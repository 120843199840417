.download-app {
    max-width: 140px;
    border: 1px solid $white;
    border-radius: 8px;

    &--small {
        max-width: 140px;
    }

    &--black {
        border: none;
    }
}
