.profile-payment-method {
    &__item {
        padding: 24px 22px;
        border-bottom: 1px solid $whitelabel-line-separator;

        &:hover {
            background-color: lighten($whitelabel-lightest, 3);

            .profile-payment-method__item__delete-btn {
                visibility: visible;
            }
        }

        &:last-of-type {
            border-bottom: none;
        }

        &__card-info {
            text-align: left;
        }

        &__card-type {
            min-width: 72px;
            margin-left: 16px;
        }

        &__delete-btn {
            flex: none;
            background-color: transparent;
            border: none;
            color: $whitelabel-red;
            font-size: 13px;
            font-family: $brand-primary-font;
            visibility: hidden;
        }
    }
}
