.bubble {
    position: absolute;
    margin-top: 1px;
    overflow: hidden;
    border-bottom-right-radius: 10px;
    &__box {
        position: relative;
        display: flex;
        flex-direction: column;
        background-color: white;
        max-height: 60vh;
        width: 555px;
        overflow: scroll;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border: solid 1px #f2f2f2;
    }
    &__box::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
    &__box::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px !important;
    }
}
