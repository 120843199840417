.careers__why {
    margin-bottom: 50px;
    width: 1170px;
    margin-left: auto;
    margin-right: auto;

    &__heading {
        margin-right: 50px;
        width: 343px;
    }

    &__text-container {
        display: flex;
        margin-left: 109px;
    }

    &__main-text {
        width: 595px;
        line-height: 1.5;
        margin-bottom: 20px;
    }

    &__read-more {
        color: $whitelabel-secondary;
    }
}
