.menu-header {
    padding: 17px 0;
    height: initial;
    align-content: space-between;

    &__container {
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr;
    }

    &__logo {
        margin-right: auto;
        margin-left: auto;
    }

    &__desktop-link {
        display: none;
    }

    &__right-container {
        flex: initial;
    }

    &__hamburger-container {
        display: block;
        margin-left: 25px;
        margin-top: auto;
        margin-bottom: auto;
        padding-bottom: 3px;
    }


    &__whatsapp-order-button {
        margin-right: 25px;
        display: flex;
        flex-wrap: wrap;
        padding: 8px 8px;
        align-items: center;
        // gap: 12px;
        // flex-shrink: 0;
        border-radius: 5px;
        background: #25D366;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
        cursor: pointer;
        p {
            color: var(--White-Primary, #FFF);
            text-align: right;
            font-feature-settings: 'clig' off, 'liga' off;
            /* Button 1 */
            font-family: Barlow;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal; 
            flex-basis: 80%;
        }
        p:last-child {
            margin-top: 5px;
            flex-basis: 100%;
            font-size: 9px;
        }
    }

  
}

.menu-header.polpa-header {
    padding: 5px 0 !important;
}

.menu-header.polpa-header-onboarding {
    padding: 4px 0 !important;
}
