.hamburger-icon {
    &--terminal {
        .hamburger-icon__line {
            border-bottom: 2px solid $white;
            width: 30px;
            padding-top: 6px;
        }
    }

    &__line {
        width: 20px;
        padding-top: 4px;
    }

    &__line.dark {
        border-bottom: 2px solid $black;
    }

    &__line.white {
        border-bottom: 2px solid $white;
    }

    &__backdrop {
        position: fixed;
        z-index: 9;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background-color: $black;
        opacity: 0.3;
    }
}
