.address-type {
    width: 80px;
    height: 80px;
    background-size: 100%;

    &__wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .selected {
            background-color: rgb(244, 244, 244);
            border-bottom: 2px solid $whitelabel-primary !important;
            margin-bottom: -2px;
        }
    }

    &__cell-container {
        border-radius: 5px;
        overflow: hidden;
        border: 1px solid rgba(74, 74, 74, 0.4);
        box-shadow: 0 2px 1px rgba(0, 0, 0, 0.13);
        box-sizing: border-box !important;
        cursor: pointer;
    }

    &__cell {
        display: flex;
        justify-content: space-between;
        width: 250px;
        height: 34px;
    }

    &__cell-container:hover {
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.38);
    }

    &__building-icon-office {
        display: inline-block;
        background-size: 100%;
        background-repeat: no-repeat;
        height: 18px;
        width: 13px;
        margin-top: 7px;
        margin-bottom: 7px;
        margin-left: 20px;
        background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/office-no-background.png');
    }

    &__building-icon-home {
        display: inline-block;
        background-size: 100%;
        background-repeat: no-repeat;
        height: 20px;
        width: 19px;
        margin-top: 9px;
        margin-bottom: 7px;
        margin-left: 20px;
        background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/home.png');
    }

    &__title {
        margin-top: 11px;
        margin-right: 40px;
        text-align: center;
    }
}
