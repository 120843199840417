.menu-one-day {
    text-align: left;
    width: 100%;
    padding-bottom: 75px;
    margin-top: 10px;

    &__header.landing {
        padding-top: 25px !important;
    }

    &__navigation {
        margin-bottom: 4px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
    &__navigation:before {
        content: initial !important;
        display: initial !important;
    }

    &__line-container {
        padding-bottom: 50px;
    }

    &__line-name {
        font-family: $brand-bolder-font;
        opacity: 0.5;
        margin-bottom: 25px;
    }

    &__date-container {
        height: 100%;
    }

    &__date {
        font-size: 24px;
        line-height: 1.21;
    }

    &__date.skeleton {
        width: 156px;
        height: 22px;
        border-radius: 1px;
        background-color: $skeleton-grey;
    }

    &__date.extra-padding {
        padding: 10px 0;
    }

    &__meal-cutoff {
        margin-top: 6px;
        font-size: 14px !important;
    }

    &__item-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 35px 15px;
        margin-top: 10px;
    }

    &__white-overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
        background-color: white !important;
    }

    &__meal-toggle {
        color: $whitelabel-red !important;
    }

    &__meal-cutoff {
        margin-right: 8px;
        font-size: 14px;
    }

    &__meal-toggle-container {
        display: flex;
        flex: 1;
        margin-left: auto;
        margin-right: auto;
    }

    &__toggle {
        margin: 0 !important;
        padding: 10px 20px;
        background-color: $white;
        font-family: $brand-bolder-font;
        color: $black;
        box-sizing: border-box !important;
        font-size: 14px;
        box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.08);
        margin-top: auto;
        margin-bottom: auto;
        border: solid 1px rgba(0, 0, 0, 0.08);
        font-family: $brand-primary-font;
        padding-bottom: 17px !important;

        &:hover {
            background-color: $whitelabel-red;
            color: $white;
        }

        &--active {
            padding-top: 13px !important;
            border-bottom: 5px solid $whitelabel-red;
            background-color: #f9f9f9;
            font-family: $brand-boldest-font;
            padding-bottom: 10px !important;
        }
    }
    &__toggle.center {
        border-radius: 5px !important;
    }
    &__toggle.left {
        border-radius: 5px 0 0 5px !important;
    }
    &__toggle.right {
        border-radius: 0 5px 5px 0 !important;
    }
    &__see-tomorrow {
        height: 87px;
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        font-size: 14px;
        background-color: $whitelabel-pastel-yellow;
    }
    &__see-tomorrow-header {
        display: flex;
        align-items: center;
        margin-bottom: 6px;
        line-height: 18px;
    }

    &__see-tomorrow-header-icon {
        margin-left: 6px;
    }

    &__arrow-icon {
        stroke-width: 2px;
        top: 2px;
        left: 5px;
        width: 10px;
        position: relative;
    }
}

@media (max-width: 1199px) {
    .menu-one-day {
        &__item-container {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}
