@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loading {
    &--circle {
        border: 3px solid $whitelabel-lightest;
        border-radius: 50%;
        border-top: 3px solid $whitelabel-primary;
        width: 30px;
        height: 30px;
        display: inline-block;
        animation: spin 1s linear infinite;
    }
    &--circle.red {
        border-top: 3px solid $whitelabel-red;
    }
}
