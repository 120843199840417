.login-popup {
    margin-top: 80px;
    &__container {
        width: 100%;
        padding: 25px;
        height: 100% !important;
    }

    &__button {
        font-weight: initial !important;
    }

    &__button-container {
        margin-top: initial !important;
        margin-bottom: auto !important;
    }

    &__social-buttons-container {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 15px;
    }

    &__title-separator {
        margin-bottom: 20px;
    }

    &__close {
        fill: $whitelabel-light;
    }

    &__back-link {
        top: 0px;
        left: 0px;
    }

    &__select-login-cta {
        height: 44px !important;
    }

    .one-item-grid {
        grid-template-columns: 1fr !important;
    }
}
