.profile-address {
    &__item {
        padding: 24px 22px;
        border-bottom: 1px solid $whitelabel-line-separator;

        &:hover {
            background-color: lighten($whitelabel-lightest, 3);

            .profile-address__item__delete-btn {
                visibility: visible;
            }
        }

        &-disabled:hover {
            background-color: inherit;
        }

        &:last-of-type {
            border-bottom: none;
        }

        &__delete-btn {
            flex: none;
            background-color: transparent;
            border: none;
            color: $whitelabel-red;
            font-size: 13px;
            font-family: $brand-primary-font;
            visibility: hidden;
        }

        &__delete-btn-wrapper {
            margin-left: auto;
        }

        &__address-wrapper {
            width: 100%;
            max-width: 80%;
        }
    }
}
