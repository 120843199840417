.chef {
    &__cta {
        width: 291px;
        height: 45px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 109px;
    }
    .menu-header {
        position: static;
        margin-top: 0 !important;
    }
}
