@media (min-width: 651px) and (max-width: 768px) {
    .delivery-area {
        &__map-section {
            text-align: center;
            padding-top: 0px;
            padding-bottom: 16px;

            &__cta {
                margin-bottom: 32px;
            }
        }
    }
}
