@keyframes circlingBlue {
    0%,
    100% {
        box-shadow: 0px -5px 0px $whitelabel-blue;
    }
    25% {
        box-shadow: 5px 0px 0px $whitelabel-blue;
    }
    50% {
        box-shadow: 0px 5px 0px $whitelabel-blue;
    }
    75% {
        box-shadow: -5px 0px 0px $whitelabel-blue;
    }
}

@keyframes circlingGreen {
    0%,
    100% {
        box-shadow: 0px -5px 0px $whitelabel-primary;
    }
    25% {
        box-shadow: 5px 0px 0px $whitelabel-primary;
    }
    50% {
        box-shadow: 0px 5px 0px $whitelabel-primary;
    }
    75% {
        box-shadow: -5px 0px 0px $whitelabel-primary;
    }
}

.payment-in-progress {
    text-align: center;

    &__title {
        font-size: 16px;
        position: relative;
        top: 40px;
    }

    &__loader {
        margin: 0 auto;
        margin-top: 40vh;
        background-color: $white;
        transform: translateY(-50%);
        border-radius: 50%;
        height: 300px;
        width: 300px;
        display: flex;

        &--blue {
            animation: circlingBlue 1s linear infinite;
        }

        &--green {
            animation: circlingGreen 1s linear infinite;
        }
    }

    &__loader-content {
        width: 60%;
        height: 60%;
        margin: auto;
        vertical-align: middle;
        display: flex;
        align-items: center;
    }
}
