.new-counter {
    display: flex;
    width: 36px;
    height: 30px;
    object-fit: contain;
    border-radius: 5px;
    background-color: #effcf4;
    margin-left: 4px;
    &__count-container {
        margin: auto;
    }
    &__count {
        font-size: 14px;
    }
}

.new-counter.wallet {
    background-color: #edf9fb;
}
