.about__section-faq {
    margin-bottom: 61px;
    &__header-container {
        width: 80%;
        margin-bottom: 40px;
    }
    &__header {
        font-size: 28px;
        margin-bottom: 20px;
    }
    &__faq-question {
        margin-left: 22px;
        line-height: 1.5;
    }
    &__faq-answer.expanded {
        padding: auto;
        padding-left: 22px;
    }
    &__arrow-icon {
        width: 30px;
        margin-right: 20px;
    }
}
