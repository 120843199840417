.careers__life-at-pop {
    margin-bottom: initial;
    margin-left: initial;
    margin-right: initial;
    width: initial;

    &__content {
        width: 87%;
        margin-left: auto;
        margin-right: auto;
        flex-direction: column;
    }

    &__header {
        font-size: 24px;
        margin-bottom: 20px;
    }

    &__sub-header {
        width: initial;
    }

    &__social-sharing {
        margin-top: initial;
        margin-bottom: 33px;
    }
}
