.about__section-hero {
    margin-bottom: 45px;
    &__bg-image {
        width: 100%;
        height: 289px;
        background-color: $whitelabel-primary;
    }
    &__content-container {
        background-color: initial;
        height: initial;
        width: initial;
        color: white;
        margin-top: 37px;
        top: 0;
    }

    &__content {
        padding: initial;
        margin-top: 37px;
        margin-left: 25px;
    }

    &__header {
        font-size: 28px;
        margin-bottom: 15px;
    }

    &__body {
        margin-bottom: 0;
    }
}
