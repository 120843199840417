.delivery-note-explain {
    &__icon {
        width: 14px;
        height: 14px;
        background-size: 100%;
        background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/most-convenient.png');
        background-repeat: no-repeat;
    }

    &__content {
        padding: 40px;
        padding-top: 0;
    }

    &__explanation-title {
        margin-top: 30px;
        margin-bottom: 25px;
    }

    &__options-title {
        margin-bottom: 25px;
    }

    &__option-description {
        margin-bottom: 16px;
    }

    &__CTA {
        margin: 25px 0;
        padding: 10px 25px;
    }

    //&__most-convenient {
    //    width: 12px;
    //    height: 12px;
    //    background-size: cover;
    //    background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/most-convenient.png');
    //}

    &__embed {
        display: inline-block;
        background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/most-convenient.png');
        background-size: 130%;
        background-position: 50% 0%;
        width: 18px;
        height: 18px;
    }
}
