.addon-panel {
    margin-top: 70px;

    &__title-container {
        font-size: 24px;
        line-height: 1.21;
    }

    &__title {
        margin-bottom: 4px;
    }

    &__meal-time-container {
        font-size: 14px;
        line-height: 1.29;
    }

    &__big-list {
        margin-bottom: 30px;

        .column-container {
            display: flex;
            flex-direction: column;
            width: 100%;
            .column:first-of-type {
                margin-top: 35px;
            }
            .column {
                margin-top: 45px;
            }
        }

        .item-container {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            grid-gap: 25px;
        }
    }

    &__small-list {
        margin: 25px 0;
        margin-bottom: 30px;
        .column-container {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            grid-gap: 25px;
        }
    }

    &__category-title {
        font-size: 18px;
        margin-bottom: 25px;
    }

    &__cta {
        font-size: 16px;
        margin-top: auto;
        margin-bottom: auto;
    }

    &__cta-arrow {
        margin-top: auto !important;
        margin-bottom: auto !important;
        margin-left: 6px;
        font-size: 9px;
        stroke-width: 3px;
    }
}

@media (max-width: 1199px) {
    .addon-panel {
        &__small-list {
            .column-container {
                grid-template-columns: repeat(4, 1fr);
            }
        }

        &__big-list {
            .item-container {
                grid-template-columns: repeat(4, 1fr);
            }
        }
    }
}
