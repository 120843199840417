.careers__life-at-pop {
    width: 1080px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 90px;

    &__content {
        margin-left: 60px;
        display: flex;
        margin-bottom: 50px;
    }

    &__header {
        font-size: 40px;
        margin-top: 10px;
        margin-right: 50px;
        width: 325px;
    }

    &__sub-header {
        line-height: 1.5;
        width: 462px;
    }

    &__img {
        border-radius: 10px;
    }

    &__photos-group-1 {
        margin-bottom: 30px;
    }

    &__photos-group-2 {
        margin-left: 83px;
    }

    &__social-sharing {
        margin-top: 30px;
        &--header {
            margin-bottom: 15px;
            p {
                opacity: 0.6;
                color: #000000;
            }
        }
    }

    &__social-icon {
        width: 56px;
        height: 48px;
        margin-right: 3px;
    }

    &__facebook-icon {
        background: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/facebook-new-icon.png');
        background-size: cover;
    }

    &__instagram-icon {
        background: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/instagram-new-icon.png');
        background-size: cover;
    }

    &__linkedin-icon {
        background: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/linkedin-new-icon.png');
        background-size: cover;
    }

    .team-pic-1 {
        background: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/team-pic-1.jpg');
        background-size: cover;
        width: 512px;
        height: 314px;
        margin-right: 30px;
    }

    .team-pic-2 {
        background: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/team-pic-2.jpg');
        background-size: cover;
        width: 445px;
        height: 273px;
        margin-top: auto;
    }

    .team-pic-3 {
        background: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/team-pic-3.jpg');
        background-size: cover;
        width: 590px;
        height: 362px;
        margin-right: 30px;
    }

    .team-pic-4 {
        background: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/team-pic-4.jpg');
        background-size: cover;
        width: 376px;
        height: 231px;
        margin-bottom: auto;
    }
}
