.stripe-credit-card {
    &__payment-expiry-container,
    &__payment-cvv-container {
        width: 48%;
    }

    .StripeElement {
        display: block;
        margin: 0 0 20px 0;
        max-width: 500px;
        padding: 8px 10px;
        font-size: 1em;
        font-family: $brand-primary-font !important;
        border-radius: 5px;
        outline: 0;
        background: white;

        &--focus {
            box-shadow: none;
            border: 1px solid $whitelabel-lighter;
            padding: 8px 10px;
        }

        .InputContainer,
        .InputElement {
            font-family: $brand-primary-font !important;
        }
    }
}
