.order-status-banner {
    margin-bottom: 5px;
    width: 100% !important;
    left: 0 !important;
    margin: 0 !important;

    &__content {
        margin-top: 5px;
        margin-bottom: 30px;
        border-radius: initial;

        &--delivered {
            padding: 20px;
            margin: 0 10px;
        }
    }

    &__left {
        left: 0px;
    }

    &__right {
        right: 0px;
    }

    &__text {
        margin-left: 50px;
        margin-right: 50px;
        font-size: 18px;
    }

    &__image {
        &:before {
            display: none;
        }
    }

    &__close {
        right: 20px;
    }
}
