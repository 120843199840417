.offline-page {
    margin-top: 80px;
    &__button-container {
        margin-left: auto;
        margin-right: auto;
        width: 40%;
        margin-top: 30px;
    }
    &__text {
        margin-top: 30px;
    }
}

.offline-signup-page {
    &__modal {
        padding: 30px;
    }
    &__download-container {
        margin-top: 20px;
    }
    &__qr-code {
        display: block;
        background: url('https://s3-ap-southeast-1.amazonaws.com/dahmakan-website-assets/images/static/qr-code.png');
        background-size: cover;
        width: 200px;
        height: 200px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
    }
}
