.delivery-area {
    .menu-header {
        position: static;
        margin-top: 0 !important;
    }

    &__hero-section {
        color: $white;
        background: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/delivery-area.jpg');
        background-size: cover;
        background-position-x: 50%;
        height: 560px;
        padding-top: 80px;
        position: relative;

        &__container {
            z-index: 1;
            position: relative;
        }

        &__overlay {
            background-color: $black;
            opacity: 0.2;
            position: absolute;
            top: 0px;
            left: 0px;
            bottom: 0px;
            right: 0px;
            z-index: 0;
        }

        &__heading {
            font-size: 42px;
        }

        &__subheading {
            font-size: 24px;
        }

        &__intro-text {
            width: 400px;
        }

        &__benefit {
            position: relative;
            top: 2px;
        }

        &__cta {
            width: 400px;
            height: 42px;
        }
    }

    &__map-section {
        padding-top: 50px;
        padding-bottom: 50px;

        &__heading {
            font-size: 26px;
        }

        &__text-area {
            padding-top: 60px;
        }

        &__map {
            height: 360px;
        }

        &__cta {
            width: 300px;
            height: 42px;
        }
    }

    &__vote-section {
        padding-top: 80px;
        padding-bottom: 50px;
        background-color: $whitelabel-lightest;

        &__heading {
            font-size: 26px;
        }

        &__subheading {
            width: 500px;
            margin: 0 auto;
        }

        &__subheading-2 {
            width: 600px;
            margin: 0 auto;
        }

        &__form-container {
            width: 600px;
            margin: 0 auto;
            margin-top: 32px;
        }

        &__input {
            width: 300px;
        }

        &__cta {
            width: 300px;
            margin-bottom: 64px;
        }

        &__business-link {
            font-size: 18px;
            font-weight: normal;
            font-family: $brand-bolder-font;
            margin-top: 12px;
            display: inline-block;
        }
    }

    &__popup-success {
        padding: 48px 72px 48px 72px;

        &__heading {
            font-size: 26px;
        }
    }

    &__popup-email {
        padding: 48px 84px 48px 84px;
        width: 500px;

        &__heading {
            font-size: 26px;
        }
    }
}
