.login-popup {
    text-align: center;

    &__container {
        width: 450px;
        padding: 80px 60px 65px;
    }

    &__title {
        font-size: 24px;
        font-family: $brand-boldest-font;
        text-align: left;
        margin-bottom: 20px;
        line-height: 1.1em;
    }

    &__title-separator {
        width: 30px;
        border: 1px solid;
    }

    &__close-margin {
        top: 50px !important;
        right: 25px;
    }

    &__back-margin {
        top: 50px !important;
    }

    &__button {
        box-sizing: border-box !important;
        padding: 13px 0 !important;
        font-size: 16px;
        border-radius: 5px;
        line-height: initial;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        &-icon-container {
            margin-right: 10px;
            height: 20px;
            width: 20px;
        }
        span {
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    &__social-media-icon {
        height: 100%;
        width: 100%;
    }

    &__phone-number-input-container,
    &__input-container {
        height: 44px !important;
    }

    &__phone-number-input-container {
        &__error {
            margin-top: 10px;
        }
    }

    &__input-container {
        margin-bottom: 10px;
    }

    &__subheader-container {
        text-align: left;
    }

    &__input-label {
        line-height: initial;
    }

    &__subheader {
        font-family: $brand-primary-font;
        font-weight: 300;
        font-size: 16px;
        line-height: 1.19;
    }

    &__or-separator {
        width: 100%;
        height: 19px;

        &-text {
            font-family: $brand-primary-font;
            font-weight: 300;
            position: relative;
            background-color: $white;
            z-index: 1;
            display: inline;
            padding-left: 10px;
            padding-right: 10px;
        }

        &-line-container {
            display: inline-block;
            margin-bottom: 3px;
        }

        &-line {
            width: 25px;
            height: 1px;
            background-color: #000000;
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    &__social-buttons-container {
        margin-bottom: 25px;
    }

    &__back-link {
        position: relative;
        top: -20px;
        left: -40px;
    }

    &__cannot-login-container {
        margin-top: 20px;
    }

    &__select-login-cta {
        width: 63px;
        height: 44px;
        margin-left: 8px;
    }

    &__arrow-icon {
        margin-right: -10px;
        margin-top: 2px;
        width: 19px;
        height: 13px;
    }
}

.login-non-modal {
    &__content {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;
        z-index: 1;
    }
}
