.chef__chefs {
    &__header-container {
        margin-right: initial;
        margin-left: initial;
        padding-left: initial;
        padding-right: initial;
        margin-bottom: 50px;
    }
    &__header {
        font-size: 24px;
        padding-right: 15px;
        width: initial;
        margin-left: initial;
    }
    &__content {
        flex-direction: column;
        margin-bottom: initial;
        width: initial;
        padding-right: 30px;
    }
    &__description-container {
        margin: initial;
    }
    &__description-body-container {
        position: relative;
        width: 100%;
        margin-bottom: 25px;
    }
    &__green-box {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 229px;
        height: 36px !important;
        background-color: #effcf4;
        z-index: -1;
    }
    &__description-header-container {
        margin-left: 15px;
        margin-top: auto;
        margin-bottom: auto;
    }
    &__description-header {
        font-size: 16px;
        margin-left: initial;
    }
    &__description-name {
        font-size: 28px;
    }
    &__description-fun-facts {
        margin-bottom: initial;
    }
    &__dash {
        margin-top: 5px;
        margin-bottom: 30px;
    }
    &__display-pic-container {
        display: flex;
        width: initial;
        height: initial;
        margin-bottom: 25px;
    }
    &__display-pic {
        width: 63px;
        height: 62px;
    }
    .chef-doul {
        background: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/doul-small.png');
        background-size: cover;
    }
    .chef-shahril {
        background: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/shahril-small.png');
        background-size: cover;
    }
    .chef-riza {
        background: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/riza-small.png');
        background-size: cover;
    }
    .chef-kamarul {
        background: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/kamarul-small.png');
        background-size: cover;
    }
    &__grey-box {
        height: 8px;
        bottom: 3px;
    }
    &__grey-box.doul {
        width: 67px;
    }
    &__grey-box.shahril {
        width: 101px;
    }
    &__grey-box.riza {
        width: 64px;
    }
    &__grey-box.kamarul {
        width: 117px;
    }
    &__dash {
        height: 2px;
        background-color: #ededed;
    }
}
