.order-status-popup {
    width: 100%;

    &__body {
        //padding-bottom: 80px;
        max-height: 100%;
    }

    &__close {
        width: 2em;
        height: 2em;
        top: 4px;
        right: 0px;
    }
}
