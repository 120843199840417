.promotion {
    .menu-header {
        position: static;
        margin-top: 0 !important;
    }
    &__container {
        width: 893px;
        margin-left: auto;
        margin-right: auto;
        padding: 75px 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 45px;
    }
    &__promotion-tiles {
        width: 424px;
        margin-left: auto;
        margin-right: auto;
        .info-content {
            padding: 50px 40px;
        }
    }

    &__cta {
        margin-left: auto;
        margin-right: auto;
        width: 291px;
        height: 45px;
        margin-bottom: 75px;
    }
}
