.section-stats {
    height: 116px;
    background-color: white;
    margin-top: -50px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 30px;
    box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.07);
    position: relative !important;

    &__container {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-around;
    }

    &__stat-container {
        margin-top: auto;
        margin-bottom: auto;
        text-align: left;
        display: flex;
    }

    &__stat-header {
        font-size: 20px;
        line-height: 1.21;
        margin-bottom: 3px;
    }

    &__stat-subheader {
        font-size: 14px;
        line-height: 1.21;
    }

    &__icon {
        height: 25px;
        min-width: 25px;
        background-size: contain !important;
        margin-right: 12px;
        margin-top: auto;
        margin-bottom: auto;
        background-repeat: no-repeat !important;
    }
}

.section-stats.referral {
    position: relative !important;
}

.served-meals-icon {
    background: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/cooking-pot.png');
}

.happy-customers-icon {
    background: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/smiley.png');
}

.google-rating-icon {
    background: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/star.png');
}

.instagram-followers-icon {
    background: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/instagram-landing.png')
        no-repeat;
}

.money-back-icon {
    background: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/money-back.png')
        no-repeat;
}

.white-star-icon {
    background: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/white-star-icon.png')
        no-repeat;
}

.white-instagram-icon {
    background: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/white-instagram-icon.png')
        no-repeat;
}

.halal-icon {
    background: url('https://image.popcontent.link/halal_logo.svg')
        no-repeat;
}
