.general-view {
    &__halal-logo {
        margin-top: 15px;
        display: block;
        width: 98px;
        height: 45px;
        object-fit: cover;
        align-self: center;
    }
}
