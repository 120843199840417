html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
a,
address,
img,
small,
strike,
strong,
b,
u,
i,
center,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside footer,
header,
menu,
nav,
output,
section,
summary,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    -webkit-tap-highlight-color: transparent;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
footer,
header,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
}
ul {
    list-style: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
