.halal-popup {
    &__content {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: -0.5px;
        color: $whitelabel-black;
    }
    @media (min-width: 340px) {
        &__content {
            font-size: 28px;
        }
    }
}
