.terminal-mobile-order-confirmation-screen {
    background: linear-gradient(
        180deg,
        $whitelabel-primary 30.05%,
        rgba(255, 209, 43, 0.78) 100%
    );
    padding: 52px;
    min-height: calc(100vh - 76px);

    button {
        border: none;
    }

    &__content_container {
        display: flex;
        flex-direction: column;
    }

    &__pay_text {
        margin: 32px 0px;
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 32px;
        text-align: center;
        color: #303234;
        text-transform: uppercase;
    }

    &__cash_price_container {
        margin-top: 34px;
        background-color: #303234;
        border-bottom: 1px dashed white;
        color: white;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 42px 28px;

        .circle {
            position: absolute;
            bottom: -8px;
            width: 15px;
            height: 15px;
            background-color: $whitelabel-primary;
            border-radius: 50%;
        }

        .circle1 {
            right: -6px;
        }
        .circle2 {
            left: -6px;
        }
    }

    &__price_container {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-top: 48px;

        .rm_text {
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 26px;
            text-align: center;
            color: $whitelabel-primary;
            margin-bottom: 2px;
        }
        .price_text {
            font-style: normal;
            font-weight: 600;
            font-size: 58px;
            line-height: 58px;
            text-align: center;
            color: $whitelabel-primary;
            margin-left: 10px;
        }
    }

    &__cash_machine {
        position: absolute;
        top: -34px;
        right: 40%;
        background-color: $whitelabel-primary;
        border: 5px solid #303234;
        border-radius: 50%;
        padding: 6px;
        width: 68px;
        height: 68px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__cash_machine_img {
        background-color: $whitelabel-primary;
        width: 38px;
        height: 38px;
        object-fit: contain;
    }
    &__name_app_container {
        padding: 28px 42px 0px;
        background-color: #303234;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .name_text {
            font-family: $brand-primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            text-align: center;
        }

        .name {
            font-family: $brand-primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            margin: 5px 0px 0px;
            text-transform: uppercase;
        }
    }

    &__app_btns_container {
        margin-top: 28px;
    }

    &__pop_app_btns {
        display: flex;
    }

    &__app_download_btns {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 8px;
    }

    &__app-button {
        border-radius: 5px;
        width: 100px;
        height: 34px;
        object-fit: contain;
    }

    &__referral_cta_btn {
        margin-top: 10px;
        margin-bottom: 45px;
        padding: 8px;
        background-color: $whitelabel-primary;
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 11px;
        text-align: center;
        letter-spacing: -0.2px;
        color: $whitelabel-black;
        text-transform: uppercase;
    }

    &__rating_modal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 24px 16px;
        width: 100%;
    }

    &__rating_modal_title {
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 32px;
        letter-spacing: -0.5px;
        color: $whitelabel-black;
        margin-bottom: 22px;
    }

    &__rating_href {
        width: 100%;
    }

    &__modal_cta_btn {
        border: none;
        background-color: $whitelabel-primary;
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: $whitelabel-black;
        border-radius: 5px;
        padding: 12px;
        height: 40px;
        width: 100%;
    }

    &__modal_later_btn {
        border: none;
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: $whitelabel-black;
        margin-top: 22px;
        background-color: transparent;
    }
}
