.halal-view {
    display: flex;
    align-items: center;
    &__img {
        width: 32px;
        height: 32px;
    }
    .halal-text {
        margin-left: 10px;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }
}
