.terminal-about {
    .menu-header {
        position: static;
        margin-top: 0 !important;
    }

    &__separator {
        width: 35px;
    }

    &__separator--short {
        width: 20px;
    }

    &__what,
    &__how,
    &__why-content,
    &__who {
        padding-left: 110px;
        padding-right: 110px;
    }

    &__question {
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 12px;
    }

    &__what {
        color: $white;
        background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/about-hero.jpg');
        background-size: cover;
        height: 460px;
        padding-top: 120px;

        &-title {
            font-family: $brand-bolder-font;
            font-size: 36px;
            max-width: 600px;
            line-height: 150%;
        }

        &-subheading {
            max-width: 600px;
        }
    }

    &__how {
        padding-top: 80px;

        &-title {
            font-family: $brand-bolder-font;
            font-size: 28px;
            font-weight: bold;
            max-width: 600px;
            line-height: 150%;
        }

        &-subheading {
            max-width: 600px;
        }

        &-step-1,
        &-step-2,
        &-step-3,
        &-step-4 {
            background-size: cover;
            height: 230px;
        }

        &-step {
            margin-bottom: 64px;
        }

        &-step-title {
            font-size: 18px;
        }

        &-step-subheading {
            margin-top: 16px;
        }

        &-step-1 {
            background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/about-step-1.jpg');
        }

        &-step-2 {
            background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/about-step-2.jpg');
        }

        &-step-3 {
            background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/about-step-3.jpg');
        }

        &-step-4 {
            background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/about-step-4.jpg');
        }
    }

    &__why {
        &-img {
            background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/girl-holding-dahmakan-box.jpg');
            background-size: cover;
            width: 100%;
            height: 380px;
        }

        &-content {
            padding-top: 80px;
        }

        &-title {
            font-size: 28px;
            font-weight: bold;
            max-width: 600px;
            font-family: $brand-bolder-font;
            margin-bottom: 40px;
        }

        &-subheading {
            max-width: 600px;
            margin-bottom: 60px;
        }
    }

    &__who {
        padding-top: 50px;

        &-title {
            font-size: 28px;
            font-weight: bold;
            max-width: 600px;
            font-family: $brand-bolder-font;
        }

        &-subheading {
            max-width: 600px;
        }

        &-CTA {
            width: 300px;
            margin-top: 40px;
            margin-bottom: 60px;
            height: 45px;
        }
    }

    &__slider {
        &-item {
            height: 460px;
            width: 100vw;
            background-size: cover;
        }

        .slider-decorator-0,
        .slider-decorator-1 {
            display: none;
        }

        .slider-decorator-2 {
            top: 10px;

            button {
                color: $white !important;
            }
        }
    }
}
