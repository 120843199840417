.terminal-cart-summary {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 9;
    background-color: $whitelabel-primary;
    color: $black;
    min-height: 100px;
    transform: translateY(0px);
    transition: transform 1s ease;

    &--hidden {
        transform: translateY(300px);
    }

    &__content {
        height: 100%;
        padding-top: 6px;
        padding-bottom: 6px;
        justify-content: space-around;
    }

    &__item-name {
        width: 70%;
        margin-right: 15px;
        font-size: 18px;
    }

    &__quantity-box {
        width: 28px;
        height: 28px;
        display: flex;
    }

    &__quantity {
        font-size: 18px;
        margin: auto;
    }

    &__increase,
    &__decrease {
        padding: 0px;
        border: none;
        border-radius: 50%;
        width: 28px;
        height: 28px;
        background-color: $black;
    }

    &__increase .iconmoon-icon,
    &__decrease .iconmoon-icon {
        stroke-width: 4px !important;
        stroke: $white;
    }
}
