@keyframes growingWide {
    0% {
        transform: scaleX(0);
    }
    100% {
        transform: scaleY(1);
    }
}

.add-to-cart-button {
    &__button {
        font-family: $brand-boldest-font;
        padding: 5px 10px;
        padding-top: 6px;
        font-size: 14px;
        background-color: $whitelabel-primary;
        color: $black;
        border: none;
        width: 100%;
        height: 30px;

        &.small-font {
            font-size: 12px;
        }

        span {
            color: $black;
        }

        &:hover {
            color: $black;
            background-color: lighten($whitelabel-primary, 7%);
        }
    }

    &__button.wallet {
        background-color: $whitelabel-blue !important;

        &:hover {
            background-color: lighten($whitelabel-blue, 7%) !important;
        }
    }

    &__button.discount {
        padding: 5px;
    }

    &__button.skeleton {
        background-color: #a7a7a7;
        pointer-events: none !important;
    }

    &--disabled {
        background-color: $whitelabel-light;
        cursor: default;
    }
}

.hover {
    background-color: $whitelabel-primary;
    color: $white;

    span {
        color: $white;

        s {
            color: $white !important;
        }
    }
}

.hover.wallet {
    background-color: $whitelabel-blue;
}

.addon-add-to-cart-button {
    border: none;
    background-color: $whitelabel-primary;
    color: $white;
    border-radius: 20px;
    display: inline-flex;
    transition: width 0.5s ease;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transform: translateZ(0);

    &--wallet {
        background-color: $whitelabel-blue;
    }

    &--disabled {
        background-color: $whitelabel-light;
        cursor: default;
    }

    &--expanded {
        will-change: width;
        width: 100%;

        .add-to-cart-button__content {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    &__content {
        position: relative;
        margin: auto;
        white-space: nowrap;
        overflow: hidden;
        display: flex;
    }

    &__hover-text {
        transform-origin: right;
        animation: growingWide 0.4s 1;
        font-family: $brand-bolder-font;
    }

    &__icon {
        width: 1.3em;
        height: 1.3em;
        margin: auto;
    }

    &:hover {
        background-color: lighten($whitelabel-primary, 7%);
    }
}
