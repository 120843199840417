.rewards-popup {
    padding: 15px 0;
    &__container {
        width: 450px;
    }
    &__switcher {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 25px;
    }
    &__switcher.popup {
        margin-top: 55px;
    }
    &__switch {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid $whitelabel-secondary !important;
        padding-bottom: 13px;
        cursor: pointer;
        opacity: 0.4;
    }
    &__switch.active {
        border-bottom: 3px solid $whitelabel-secondary !important;
        cursor: initial !important;
        pointer-events: none;
        opacity: 1 !important;
    }
    &__reward-container {
        min-height: 106px;
        border-radius: 5px;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
        background-color: #ffffff;
        margin: 0 20px;
        padding: 15px;
        padding-bottom: 13px !important;
        display: flex;
        flex-direction: column;
        margin-bottom: 8px !important;
    }
    &__reward-container.popup {
        margin: 0 25px;
    }
    &__reward-container.collapse {
        min-height: initial !important;
    }
    &__header-container {
        display: flex;
    }
    &__arrow-icon {
        width: 15px;
        height: 15px;
        margin-left: auto;
    }
    &__cta-container {
        display: flex;
        margin-top: auto;
        align-items: center;
    }
    &__text {
        margin-right: auto;
        font-size: 14px;
    }
    &__promo-input {
        width: 61%;
        font-size: 16px;
        margin-right: auto;
        margin-top: auto;
        padding-bottom: 10px;
        border-left: none;
        border-right: none;
        border-top: none;
        border-bottom: solid 1px #00000066;
        border-radius: 0 !important;
        padding-left: 0 !important;
    }
    &__promo-input::placeholder {
        opacity: 0.4 !important;
    }
    &__promo-input:focus {
        border-bottom: solid 1px $whitelabel-primary !important;
    }
    &__promo-input:focus {
        outline-width: 0;
    }
    &__cta {
        min-height: 26px !important;
        margin-left: auto;
        padding: 3px 0 !important;
        padding-bottom: 2px !important;
        font-size: 14px;
        width: 103px;
    }
    &__applied {
        align-items: center;
        justify-content: center;
    }
    &__check-mark {
        height: 25px;
        width: 25px;
        margin-bottom: 2px;
    }
}
