.ingredient-view {
    &__item {
        width: 100px;
    }

    &__image-list {
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        margin-top: 24px;
    }

    &__item-image {
        width: 60px;
        height: 60px;
    }

    &__item-name {
        font-size: small;
        line-height: 100%;
        margin-top: -3px;
        margin-bottom: 25px;
    }

    .nutritional-view {
        margin-top: 24px;
    }

    .nutritional-view__list {
        margin-top: 24px;
    }
}
