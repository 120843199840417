.logo {
    position: relative;

    &--white {
        path {
            fill: $white;
        }
    }

    &--green {
        path {
            fill: $whitelabel-primary;
        }
    }

    &--blue {
        path {
            fill: $whitelabel-blue;
        }
    }

    &--red {
        path {
            fill: $whitelabel-red;
        }
    }

    &--orange {
        path {
            fill: $whitelabel-orange;
        }
    }
}
