.chef__section-hero {
    margin-bottom: initial;
    height: initial;
    &__bg-image {
        position: initial;
        margin-left: initial !important;
        width: 100% !important;
        height: 289px;
        background: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/chef-hero-small.jpg');
        background-size: cover !important;
    }
    &__content-container {
        margin-top: 35px;
        position: initial;
        margin-right: initial;
        margin-left: initial;
        padding-left: initial;
        padding-right: initial;
        width: initial;
        height: initial;
    }
    &__content {
        position: initial;
        padding: initial;
        width: initial;
        background-color: initial;
    }
    &__body {
        width: 265px;
    }
    &__dash {
        margin-top: 25px;
        margin-bottom: 45px;
    }
    &__header {
        font-size: 28px;
        margin-bottom: 10px;
    }
    &__dash {
        height: 2px;
        background-color: #ededed;
    }
}
