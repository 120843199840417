.date-time-selector {
    margin: 30px 40px;
    margin-bottom: 0;
    margin-right: 0;
    text-align: left;
    &__main-date-container {
        margin-bottom: 25px;
    }
    &__header-container {
        margin-bottom: 20px;
    }
    &__header {
        font-size: 24px;
    }
    &__dates-container {
        display: flex;
        flex-wrap: wrap;
    }
    &__date-container {
        border-radius: 5px;
        border: solid 1px rgba(0, 0, 0, 0.1);
        margin-right: 10px;
        margin-bottom: 10px;
    }
    &__date-container.active {
        border: solid 2px $whitelabel-primary;
        box-sizing: border-box;
        p {
            padding: 12px;
            padding-top: 14px;
            color: $whitelabel-primary;
        }
    }
    &__date-container.wallet.active {
        border: solid 2px $whitelabel-blue;
        p {
            color: $whitelabel-blue;
        }
    }
    &__date {
        font-family: $brand-bolder-font;
        font-size: 14px;
        padding: 13px;
        padding-top: 17px;
    }
    &__time-container {
        margin-right: 35px;
        border-radius: 5px;
        border: solid 1px rgba(0, 0, 0, 0.1);
        margin-bottom: 5px;
        display: flex;
        align-items: center;
    }
    &__time-container.active {
        border: solid 2px $whitelabel-primary;
        p {
            color: $whitelabel-primary;
            padding: 12px;
            padding-top: 16px;
        }
    }
    &__time-container.wallet.active {
        box-sizing: border-box;
        border: solid 2px $whitelabel-blue;
        p {
            color: $whitelabel-blue;
        }
    }
    &__time {
        font-family: $brand-bolder-font;
        font-size: 14px;
        padding: 13px;
        padding-top: 15px;
    }
    &__delivery-text {
        font-size: 14px;
        margin-left: auto;
        padding: 12px;
        text-transform: capitalize;
    }
    &__cta-container {
        background-color: white;
        border-top: 1px solid #e2e2e2;
        width: 100%;
        position: sticky;
        bottom: 0;

        .login-popup__button-container {
            max-width: 475px;
            margin: 0 auto;
        }
    }
    &__cta {
        margin-top: 23px;
        margin-bottom: 10px;
    }
    &__times-container.overflow {
        overflow: auto;
        max-height: 60vh;
    }
    &__times-container::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
    &__times-container::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px !important;
    }
}
