.terminal-addon-items-popup {
    width: 332px;
    max-height: 645px;
    padding-bottom: 16px;

    &__ipad_header {
        display: none;
    }

    &__mobile_header {
        position: sticky;
        top: 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0px 16px;

        .save-text-container {
            background-color: $whitelabel-primary;
            color: $whitelabel-secondary;
            border-radius: 0px 0px 20px 20px;
            padding: 10px 12px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-self: flex-end;
            text-align: center;
            @media (max-height: 700px) {
                padding: 4px 8px;
            }
        }

        .save-text {
            font-family: $brand-primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 11px;
            line-height: 11px;
            text-transform: uppercase;
            text-align: center;
        }
        .rm-price {
            font-family: $brand-primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 18px;
            text-align: center;
        }
    }

    &__text_close {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;

        @media (max-height: 700px) {
            //styling changes applied for mobile devices having height <700px
            margin-top: 4px;
        }

        .addon-text {
            font-family: $brand-primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 22px;
        }
    }

    &__close_btn {
        border: none;
        background: transparent;
        margin-top: 6px;
    }

    &__combo-items {
        display: flex;
        flex-wrap: wrap;
        padding: 0px 6px;
        margin-top: 8px;

        @media (max-height: 700px) {
            margin-top: 4px;
        }
        .not-select-item {
            margin: 8px 8px;

            .item-container {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 89px;
                height: 121px;
                border-radius: 8px;
                background-color: #f4f4f4;

                @media (max-height: 700px) {
                    width: 80px;
                    height: 100px;
                }
            }

            .text {
                margin-top: 10px;
                font-weight: 600;
                font-size: 11px;
                line-height: 11px;
                text-align: center;
                color: black;
            }
        }
    }
}
