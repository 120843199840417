.terminal-mobile-main-menu-item {
    width: 47.8%;
    margin: 8px 3.5px;
    position: relative;
    color: $whitelabel-black;
    @media (max-width: 365px) {
        width: 47%;
    }

    &__item_card {
        background-color: #fdc601;
        border-radius: 8px;
        padding: 8px 15px 13px 13px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__ranking_price {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &__ranking {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    &__current_ranking {
        font-family: $brand-condensed-font;
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 32px;
        letter-spacing: 1px;
    }

    &__separator {
        width: 2px;
        height: 23px;
        background-color: black;
        margin: 0px 6px;
        align-self: flex-end;
        margin-bottom: 2px;
    }

    &__ranking_change {
        display: flex;
        align-items: flex-end;
        align-self: stretch;
        margin-bottom: 1px;
    }

    &__ranking_change_num {
        font-family: $brand-semi-condensed-font;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 15px;
    }

    &__price {
        display: flex;
        flex-direction: column;
        margin-top: 3px;
        .rm {
            font-family: $brand-primary-font;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 11px;
            text-align: right;
        }
        .price {
            font-family: $brand-semi-condensed-font;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 15px;
        }
    }

    &__image {
        height: 190px;
        width: 120%;
        object-fit: cover;
        margin-top: -40px;        
    }
    &__name {
        margin-top: 5px;
        text-align: center;
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        min-height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 365px) {
            min-height: 54px;
            margin-top: 0px;
        }
    }

    &__order_again {
        display: flex;
        margin-top: 5px;
        align-items: center;

        .order_again_text {
            margin-left: 5px;
            font-family: $brand-primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            color: $whitelabel-gray;
            text-transform: uppercase;
        }
    }

    &__cart {
        border: none;
        border-radius: 50%;
        border: 3px solid white;
        background-color: #fdc601;
        width: 45px;
        height: 45px;
        padding: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: -9px;
        right: 3px;
        cursor: pointer;

        .item_quanity {
            font-family: $brand-primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
        }
    }
    &__cart_img {
        border-radius: 50%;
        background-color: #fdc601;
        padding: 7px;
        width: 36px;
        height: 36px;
    }
}
