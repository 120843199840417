// hack to bypass grid system and screen size variable inconsistency (small and col-s)
@media (max-width: 767px) {
    .modal {
        &__content {
            &--full-mobile {
                overflow-y: auto;
            }
        }
    }
}
