.header {
    height: 40px;

    &__logo {
        margin-top: 10px;
    }

    &__dahmakan {
        width: 120px;
    }
}
