.footer-button {
    z-index: 6;
    position: fixed;
    bottom: 13px;
    left: auto;
    right: 13px;
    margin: 20px;
    background-color: white !important;
    border-radius: 5px;

    svg {
        margin-left: 12px;
    }

    button {
        background-color: white !important;
        height: 35px;
        border: none;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        padding: 8px 20px;
    }

    &__collapse {
        stroke-width: 1.5px;
        margin-bottom: -2px;
    }

    &__close-icon {
        width: 10px !important;
        height: 10px !important;
        cursor: pointer;
    }
}
