.shopping-cart-timeslot {
    &__item-container {
        display: inline-block;
        width: 50%;
        max-width: 50%;
    }

    &__list-container {
        margin-top: 20px;
        margin-bottom: 20px;
        display: block;
    }
}
