.place-autocomplete {
    width: 100% !important;
    &__input {
        width: 100%;
        font-size: 16px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0;
    }

    &__prediction {
        &--hover {
            &:hover {
                background-color: initial;
                color: initial;
            }
        }

        &--selected {
            background-color: initial;
            color: $whitelabel-light;
        }
    }
    &__prediction-wrapper {
        margin-left: -15px;
    }

    &__prediction-wrapper.no-margin {
        margin-left: 0 !important;
        border: 1px solid $whitelabel-lighter;
    }

    &__prediction-icon {
        margin-right: 0;
    }
}
