.hosted-field {
    height: 35px;
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    display: inline-block;
    font-size: 14px;
    border: 1px solid $whitelabel-lighter;
    line-height: 20px;
}

.braintree-hosted-fields-invalid {
    border-color: $whitelabel-red;
}

.braintree-hosted-fields-valid {
    border-color: $whitelabel-primary;
}
