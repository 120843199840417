.wallet-payment-confirm {
    width: 100%;
    min-width: auto;
    margin-top: -20px;

    &__details {
        padding: 16px;
        padding-top: 32px;
    }

    &__order-number {
        font-size: 120%;
        margin-top: 10px;
    }

    &__item-description {
        td {
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }

    &__smart-reload {
        margin: 0px;
        margin-bottom: 20px;
        background-color: darken($whitelabel-lightest, 5%);
    }

    &__payment-method {
        margin-top: 14px;
    }

    &__start-ordering {
        padding-top: 16px;
        padding-bottom: 16px;
        height: auto;
        font-weight: bold;
    }

    &__apps {
        display: flex;
    }

    &__app-button {
        width: 100%;
    }
}
