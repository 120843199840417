.shopping-cart {
    background-color: $whitelabel-lightest;
    overflow: auto;

    &__change-btn-container {
        margin-left: auto;
    }

    &__section-title {
        margin-bottom: 10px;
    }

    &__toggle-edit-button {
        margin-left: auto;
        margin-right: 16px;
    }

    &__edit-button {
        margin-right: 16px;
    }

    &__timeframe-container {
        align-items: center;
        margin-top: 5px;
    }

    &__text-wallet {
        margin-left: 10px;
        color: $whitelabel-blue;
        font-family: $brand-bolder-font;
    }
}
