.nutritional-view {
    &__list {
        margin-top: 40px;
        margin-left: -10px;
        margin-right: -10px;
    }

    &__item {
        display: inline-block;
        padding-left: 10px;
        padding-right: 10px;
    }

    &__image {
        width: 60px;
        height: 60px;
    }

    &__name {
        font-size: 15px;
        color: $whitelabel-light;
        margin-bottom: 24px;
    }
}
