.terminal-mobile-items-menu-screen {
    padding: 40px 12.5px 30px;

    &__top_20_halal {
        margin: 0px 3.5px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .top20 {
            font-family: $brand-primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 26px;
            color: $whitelabel-black;
        }
        .halal_logo {
            width: 23px;
            height: 23px;
        }
    }
    &__menu_items {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        margin-top: 8px;
        width: 100%;
    }

    &__cart_btn {
        position: fixed;
        bottom: 16px;
        right: 16px;
        border-radius: 50%;
        padding: 13px;
        border: none;
        background-color: $whitelabel-gray;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $whitelabel-gray;

        .cart_icon_img {
            width: 46px;
            height: 44px;
        }
        .number_of_items {
            font-family: $brand-primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 20px;
            text-align: center;
            position: absolute;
            min-width: 21px;
            bottom: 28px;
            right: 23.2px;
        }
    }
    .n-items {
        background-color: $whitelabel-black;
        color: $whitelabel-black;
    }

    &__halal_pop_logo {
        width: 137px;
        height: 42px;
        margin-top: 36px;
    }
}
