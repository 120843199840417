.careers__life-at-pop {
    margin-left: auto;
    margin-right: auto;
    width: 85%;
    &__content {
        flex-direction: column;
        margin-left: initial;
    }

    &__header {
        margin-bottom: 10px;
    }

    &__sub-header {
        width: initial;
    }
}
