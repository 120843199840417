.careers__why {
    width: 85%;
    &__main-text {
        width: initial;
    }
    &__heading {
        margin-bottom: 10px;
    }
    &__text-container {
        display: flex;
        flex-direction: column;
        margin-left: initial;
    }
}
