.menu-cart-confirm {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;

    &__header {
        padding-bottom: 36px;
    }

    &__body {
        padding: 0px;
        padding-top: 30px;
    }

    &__heading {
        margin-top: 16px;
        margin-bottom: 0px;
        font-family: $brand-bolder-font;
        font-weight: normal;
    }

    &__order {
        border: none;
        border-top: 1px solid $whitelabel-lighter;
        padding-bottom: 0px;
    }

    &__payment-method {
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        padding-right: 16px;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    &__timeslot {
        display: block;
        margin-left: 0px;
    }
}
