.section-hero {
    width: 100%;
    position: relative;

    &__gradient-overlay {
        position: absolute;
        width: 100%;
        opacity: 0.8;
        background-image: linear-gradient(
            to bottom,
            #000000,
            rgba(0, 0, 0, 0.03) 20%,
            rgba(0, 0, 0, 0)
        );
        pointer-events: none;
    }

    &__image-overlay {
        position: absolute;
        width: 100%;
        pointer-events: none;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
        opacity: 0;
    }

    &__image-overlay.show {
        opacity: 1 !important;
    }

    &__white-opacity.hide {
        display: none;
    }

    &__container {
        display: flex;
    }

    &__intro {
        margin-top: auto;
        margin-bottom: auto;
        z-index: 5;
    }

    &__referral-login-container {
        margin-top: 24px;
    }

    &__referral-login-header {
        font-size: 12px;
        margin-bottom: 17px;
    }

    &__referral-intro {
        margin-top: auto;
        margin-bottom: auto;
        z-index: 5;
        padding-bottom: 40px;
    }

    &__referral-intro.variant-one {
        margin-left: auto;
        margin-right: auto;
    }

    &__referral-login {
        display: flex;
    }

    &__referral-input-container {
        width: 270px;
        height: 45px;
        margin-right: 10px;
        &__error {
            margin-top: 40px !important;
        }

        .new-input {
            background-color: $white;
        }
    }

    &__referral-login-cta {
        width: 145px;
        height: 45px;
    }

    &__referral-login-footer {
        color: $white;
        font-family: $brand-boldest-font;
        font-size: 14px;
        margin-top: 15px;
    }

    &__logo-text {
        width: 190px;
    }

    &__header {
        font-size: 48px !important;
        line-height: 1.22;
    }

    &__referral-header {
        font-size: 40px !important;
        line-height: 1.25;
    }

    &__btn {
        width: 216px;
        font-size: 16px;
        height: 40px;
    }

    &__subtitle,
    &__mobile-subtitle {
        font-size: 20px;
    }

    &__subtitle {
        line-height: 1.27;
        padding-left: 0 !important;
        padding: 20px;
    }

    &__subtitle.referral {
        line-height: 1.3 !important;
        padding-left: 0 !important;
        padding-top: 20px;
    }

    &__dish-details-container {
        width: 350px;
        margin-left: auto;
        margin-top: auto;
        margin-bottom: 100px;
        color: $white;
        font-size: 18px;
        line-height: 1.28;
        opacity: 1;
        transition: 1s;
        -webkit-transition: 1s;
        -moz-transition: 1s;
        -o-transition: 1s;
        text-align: right;
        position: relative !important;
        justify-content: flex-end;
        align-items: center;
    }

    &__dish-name {
        transition: 0.5s;
        -webkit-transition: 0.5s;
        -moz-transition: 0.5s;
        -o-transition: 0.5s;
    }

    &__dish-name.description {
        font-family: $brand-primary-font !important;
    }

    &__chef-name {
        font-family: $brand-primary-font !important;
        font-size: 14px;
        margin-top: 6px;
    }

    &__cta-button {
        font-size: 16px !important;
        background-color: $whitelabel-secondary;
        color: $white;
        margin-left: 15px;
        height: 54px !important;
        width: 175px;

        &.referral {
            width: auto;
            margin-left: 0;
        }
    }

    &__cta-button:hover {
        background-color: lighten($whitelabel-secondary, 7%);
    }

    &__cta-button-container.referral {
        margin-top: 30px;
        p {
            font-size: 12px;
            color: #000000;
            opacity: 0.5;
        }
    }

    &__cta-button.referral {
        margin-bottom: 10px;
        padding: 13px 25px;
        font-size: 16px;
    }

    &__auto-complete-container {
        position: relative;
        width: 447px;
    }
    &__auto-complete-header {
        margin-top: 40px;
        margin-bottom: 10px;
    }

    &__apps {
        display: flex;
        margin-right: 60px;

        a:not(:last-child) {
            margin-right: 16px;
        }
    }

    &__app-button {
        width: 150px;
        border-radius: 4px;
    }

    &__huawei-app-gallery {
        margin-top: 2px;
        width: 152px;
    }
}

.section-hero.referral {
    background-color: #effcf4 !important;
}

.bg-pop {
    position: absolute;
    width: 100%;
    pointer-events: none;
    background-color: $whitelabel-primary;
    background-image: url(https://d3tjqo2jh935k9.cloudfront.net/images/static/hero-pop-meal-chart.png);
    background-repeat: no-repeat;
    background-position: 82% center;
    background-size: 25%;

    .__halal-img {
        width: 108px;
        height: 48px;
        border-radius: 8px 0px 0px 8px;
        bottom: 60px;
        right: 0px;
    }
}

.bg-pop-th {
    background-image: url(https://d3tjqo2jh935k9.cloudfront.net/images/static/hero-pop-meal-chart-th.png);
}

.bg-referral {
    background: url(https://d3tjqo2jh935k9.cloudfront.net/images/static/referral-hero.jpg);
    background-position: 40% 50% !important;
    height: 554px;
}

.transition {
    transition: 1s;
    -webkit-transition: 1s;
    -moz-transition: 1s;
    -o-transition: 1s;
}

.faded {
    opacity: 0;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
}
