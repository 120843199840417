.careers__view-jobs {
    width: 100%;
    height: 150px;
    background-color: $whitelabel-pastel-yellow;
    display: flex;

    &__highlight {
        opacity: 1 !important;
    }

    &__container {
        display: flex;
        margin: auto;
    }

    &__information {
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 128px;
        span {
            font-size: 28px;
            opacity: 0.5;
        }
    }

    &__cta {
        padding: 13px 32px !important;
        width: 325px;
    }
}
