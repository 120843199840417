.wallet-header {
    padding-top: 9px;
    padding-bottom: 9px;
    position: relative;
    background-color: $white;
    z-index: 1;

    &__header {
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.border {
        border-bottom: 1px solid lighten($whitelabel-lighter, 10%);
    }

    &--transparent {
        background-color: trasparent;
    }

    &__back,
    &__next {
        position: absolute;
        color: $black;
        top: calc(50% - 11px);
    }

    &__back-icon {
        position: relative;
        margin-right: 5px;
        top: 4px;
    }

    &__next-icon {
        position: relative;
        margin-left: 5px;
        top: 4px;
    }

    &__next {
        right: 10px;
    }
}
