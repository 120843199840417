.promotion-tiles {
    &__info-content {
        display: flex;
    }

    &__info-icon-container {
        margin-right: 20px;
    }

    &__info-container {
        position: relative;
    }

    &__info-box {
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.07);
    }

    &__info-box.one {
        margin-top: 22px;
        margin-left: 120px;
        width: 362px;
        height: 270px;
        &.alt-height {
            height: 294px;
        }
        .info-content {
            padding: 50px 40px;
        }
    }

    &__info-box.two {
        margin-top: 0;
        margin-left: 40px;
        width: 393px;
        height: 318px;
        &.alt-height {
            height: 370px;
        }
        .info-content {
            padding: 50px 40px;
        }
    }

    &__info-box.three {
        margin-left: 18px;
        width: 360px;
        height: 294px;
        &.alt-height {
            height: 426px;
        }
        .info-content {
            padding: 50px 40px;
        }
    }

    &__info-box.four {
        margin-top: 26px;
        margin-left: 40px;
        width: 424px;
        height: 294px;
        &.alt-height {
            height: 318px;
        }
        .info-content {
            padding: 50px 40px;
        }
    }

    &__info-text-header {
        font-size: 28px;
        margin-bottom: 20px;
    }

    &__info-text-body {
        line-height: 1.5;
        margin-bottom: 15px;
    }

    &__cta {
        margin-left: 185px;
        width: 291px;
        height: 45px;
    }

    &__green-square {
        width: 498px;
        height: 375px;
        border-radius: 10px;
        background-color: #effcf4;
        position: absolute;
        left: 272px;
        top: 111px;
        z-index: -1;
    }

    &__icon.icon-heart {
        width: 37px !important;
        height: 31px !important;
    }

    &__icon.icon-cash-envelope {
        width: 35px !important;
        height: 34px !important;
    }

    &__icon.icon-blue-leaf,
    &__icon.icon-green-leaf {
        width: 28px;
        height: 28px;
    }
}
