.terms-and-conditions {
    text-align: left;
    margin-top: 20px;
    p,
    a {
        font-size: 12px;
    }
    &__terms-link {
        text-decoration: none !important;
    }
}
