.announcement {
    margin-top: 0 !important;
    margin-bottom: 10px;
    padding: 0px;

    &__image {
        min-height: 100px;
        height: initial;
    }

    &__close {
        top: 0px;
        width: 1.5em;
        right: 30px;
    }

    .boost {
        min-height: 150px !important;
    }
}
