.careers {
    overflow-x: hidden;
}

.careers-header {
    &__arrow {
        width: 20px !important;
        height: 17px !important;
        top: 5px !important;
    }
}
