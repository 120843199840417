.about__section-faq {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 80px;
    &__header-container {
        width: 460px;
        margin-bottom: 55px;
    }
    &__header {
        font-size: 36px;
        margin-bottom: 25px;
    }
    &__sub-header {
        line-height: 1.5;
    }
    &__faq-container {
        display: flex;
        flex-direction: column;
    }
    &__faq-row {
        display: flex;
        flex-direction: column;
    }
    &__faq-question-container {
        height: 94px;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.07);
        background-color: #ffffff;
        display: flex;
    }
    &__faq-question {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 45px;
    }
    &__faq-answer.hidden {
        transform: scaleY(0) !important;
        padding-left: 45px;
        height: 0;
        transform-origin: center top 0 !important;
        color: #f8f8f8;
        line-height: 1.5;
    }
    &__faq-answer.expanded {
        transform: scaleY(1) !important;
        background-color: #f8f8f8;
        padding-top: 25px;
        padding-bottom: 35px;
        padding-left: 45px;
        transform-origin: center top 0 !important;
        line-height: 1.5;
    }
    &__arrow-icon {
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 45px;
        stroke-width: 1px;
    }
}
