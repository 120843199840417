.terminal {
    background-color: lighten($whitelabel-lightest, 3);

    &__container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 35px 30px;
    }

    &__date-display {
        font-family: $brand-boldest-font;
        font-size: 26px;
        margin: 30px 0;
    }

    &__splash-container {
        transition: opacity 0.5s linear;
        opacity: 0;
        pointer-events: none;

        &--show {
            opacity: 1;
            pointer-events: all;
        }
    }

    .modal__content--golden-ratio {
        width: auto;
    }
}
