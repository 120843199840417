.floating-cta {
    position: fixed !important;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 20;

    &__button {
        width: 100%;
        padding: 10px 0;
        border-radius: 5px;
        box-shadow: 0 2px 44px 0 rgba(0, 0, 0, 0.12);
        font-size: 14px;
    }

    &__button-details-container {
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
    }

    &__left-text {
        margin-top: auto;
        margin-bottom: auto;
    }

    &__cta {
        justify-self: center;
        padding-top: 3px;
    }

    &__right-text {
        margin-top: auto;
        margin-bottom: auto;
    }
    &__top-text {
        font-size: 10px;
        margin-top: 12px;
        margin-bottom: 12px;
        text-align: center;
    }
}

.floating-cta.with-bg {
    transform: translate(-50%, 0%) !important;
    border-top: 1px #97979733 solid;
    padding-bottom: 25px !important;
}
