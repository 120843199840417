.careers__carousel {
    width: 100vw;
    margin-right: 150px;

    &__scroll-container {
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none; // IE 10+
        overflow: -moz-scrollbars-none; // Firefox
        scroll-behavior: smooth !important;
    }

    ::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* make scrollbar transparent */
    }

    &__content-container {
        padding-top: 20px;
        padding-bottom: 20px;
        margin-bottom: 37px;
        overflow-y: visible;
        width: 100%;
        margin-right: -150px;
    }

    &__content-container::-webkit-scrollbar {
        display: none; // Safari and Chrome
    }

    &__overflow-container {
        margin-top: 0;
        margin-bottom: 0;
    }

    &__overflow-container:first-of-type {
        margin-left: 150px !important;
    }

    &__overflow-container:last-of-type {
        .careers__carousel__content-box {
            margin-right: 150px;
        }
    }

    &__content-box {
        margin-top: auto;
        margin-bottom: auto;
        display: flex;
        border-radius: 10px;
        margin-right: 25px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
        margin-top: 60px;
    }

    &__text {
        line-height: 1.5;
    }

    &__step {
        color: $black;
        opacity: 0.6;
        margin-bottom: 24px;

        &.active {
            color: $whitelabel-secondary;
            opacity: 1;
        }
    }

    &__header-container {
        margin-bottom: 20px;
    }

    &__header {
        margin-top: auto;
        margin-bottom: auto;
        font-size: 32px;
    }

    &__header-icon {
        margin-right: 10px;
        object-fit: contain;
    }

    &__left-margin {
        margin-left: -150px;
    }

    &__arrows {
        &--center-align {
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__arrow-left {
        margin-right: 30px;
    }

    &__arrow-left.disabled,
    &__arrow-right.disabled {
        opacity: 0.3;
        pointer-events: none;
    }

    &__arrow-right {
        transform: rotate(180deg);
    }

    &__image {
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 45px;
        border-radius: 10px;
    }

    .icon-y-combinator {
        width: 32px;
        height: 32px;
    }

    .icon-rocket {
        width: 35px;
        height: 32px;
    }

    .icon-light-bulb {
        width: 29px;
        height: 36px;
    }

    .icon-salary {
        width: 35px;
        height: 34px;
    }

    .icon-suitcase {
        width: 20px;
        height: 32px;
    }

    .icon-chicken {
        width: 32px;
        height: 28px;
    }

    .icon-chef-hat {
        width: 34px;
        height: 32px;
    }

    .icon-tech {
        width: 37px;
        height: 33px;
    }

    .icon-delivery {
        width: 40px;
        height: 32px;
    }
}

@media (min-width: 901px) and (max-width: 1300px) {
    .careers__carousel {
        margin-left: initial;
        margin-right: initial;
        &__content-container {
            margin-left: initial;
            margin-right: initial;
        }
        &__overflow-container:first-of-type {
            margin-left: 90px !important;
        }

        &__overflow-container:last-of-type {
            .careers__carousel__content-box {
                margin-right: 90px;
            }
        }
        &__left-margin {
            margin-left: -90px;
        }
    }
}
