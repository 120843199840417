.section-delivered {
    height: 650px;
    background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/blue-mobile.jpg');
    display: flex;
    background-position: center bottom;
    position: relative;

    &__btn {
        width: 160px;
    }

    &__paragraph {
        max-width: 100%;
    }

    &__wrapper {
        margin-bottom: 75vw;
        transform: translateY(20px);
    }
}
