.about__section-save {
    width: 100%;
    flex-direction: column;
    margin-bottom: 65px;
    &__header-container {
        width: initial !important;
        margin-bottom: 25px;
    }
    &__header {
        font-size: 28px;
        margin-bottom: 20px;
    }
    &__cta {
        margin-left: auto;
        margin-right: auto;
        width: 193px;
        height: 45px;
    }

    .icon-blue-leaf,
    .icon-green-leaf {
        width: 28px;
        height: 28px;
    }
}
