.halal-preview {
    &__content {
        background-color: $whitelabel-black;
        border-radius: 5px;
    }
    &__text {
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 32px;
        color: white;
        text-align: center;
    }
}
