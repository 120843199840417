.counter {
    top: 0;
    position: absolute;
    display: flex;
    width: 100%;
    border-top-right-radius: 5px;
    overflow: hidden;

    &__counter {
        position: relative;
        border-top: 45px solid $whitelabel-primary;
        border-left: 45px solid transparent;
        margin-left: auto;
    }

    &__text {
        position: absolute;
        top: -38px;
        right: 8px;
        color: $white;
        font-weight: bold;
    }

    &__counter.wallet {
        border-top: 45px solid $whitelabel-blue;
    }
}
