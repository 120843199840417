.rating-popup {
    width: 611px;
    text-align: center;

    &__header {
        text-align: left;
        background-color: $whitelabel-primary;
        padding: 30px;
        font-size: 20px;
        color: $black;
    }

    &__header-alt {
        font-size: 16px;
        font-weight: 100;
        margin-left: 8px;
    }

    &__close {
        fill: $white;
        position: relative;
        width: 1.2em;
        height: 1.2em;
        top: -4px;
        right: -8px;
    }

    &__body {
        padding: 16px 32px 32px;
        max-height: 100%;

        &--nps {
            padding-bottom: 40px;
        }

        &.delivery {
            padding: 24px 32px;
        }

        &--border-top {
            border-top: 1px solid #d1d1d1;
        }
    }

    &__title {
        font-size: 16px;
        margin: 12px 0 32px;
    }

    &__rating-pane {
        &:not(:last-child) {
            margin-bottom: 50px;
        }

        &-container {
            display: grid;
            grid-template-columns: 1.15fr 3fr 3fr;
            align-items: center;

            &.no-image {
                grid-template-columns: 4.15fr 3fr;
            }

            &.thumbs-up {
                grid-template-rows: repeat(2, 44px);

                .rating-popup__rating-pane-image {
                    grid-row: 1 / 3;
                }

                .rating-popup__rating-pane-title {
                    grid-area: 1 / 2 / 2 / 3;
                    align-self: start;
                }

                .rating-popup__rating-pane-button {
                    grid-area: 1 / 3 / 2 / 4;
                    padding: 8px;
                    cursor: inherit;

                    &-up {
                        background-color: #e9fbf1;
                    }

                    &-down {
                        background-color: #f5f5f5;
                    }
                }

                .rating-popup__rating-pane-comment {
                    grid-area: 2 / 2 / 3 / 4;
                    margin-left: 14px;
                }

                &.no-image {
                    display: block;
                }
            }

            &.thumbs-down {
                grid-template-columns: 1.15fr 2fr 4fr;

                .rating-popup__rating-pane-image {
                    grid-row: 1 / 2;
                }

                .rating-popup__rating-pane-title {
                    grid-area: 1 / 2 / 2 / 3;
                    align-self: start;
                }

                .rating-popup__rating-pane-tag-panel {
                    grid-area: 1 / 3 / 2 / 4;
                    justify-self: end;
                    align-self: start;
                }

                .rating-popup__rating-pane-comment {
                    grid-area: 2 / 2 / 3 / 4;
                    margin-left: 14px;
                }

                &.no-image {
                    display: block;
                }
            }
        }

        &-image {
            width: 88px;
            height: 88px;
            background-size: cover;
            background-position: 50% 55%;
        }

        &-title {
            align-self: center;
            text-align: left;
            margin-left: 14px;
        }

        &-button {
            width: 50%;
            display: inline-block;
            padding: 17px 27px;
            background-color: #f5f5f5;
            cursor: pointer;

            &-down {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                border-right: 1px solid #e7e7e7;
            }

            &-up {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }

        &-comment {
            border: none;
            border-bottom: 1px solid $whitelabel-lighter;
            background-color: #f8f8f8;
            padding: 10px;
            font-size: 14px;
            outline: none;
        }

        &-icon {
            width: 29px;
            height: 29px;

            &-rated {
                width: 17px;
                height: 17px;
            }
        }

        center:hover svg {
            fill: $whitelabel-primary;
        }

        center .rating-popup__rating-pane-star-container:hover ~ div > svg {
            fill: $whitelabel-light;
        }

        &-star-meaning {
            position: absolute;
            top: -16px;
            font-size: 14px;
            color: $whitelabel-primary;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__nps-container {
        margin-left: 50px;
        margin-right: 50px;
    }

    &__nps-box {
        color: $whitelabel-light;
        border: 1px solid $whitelabel-lighter;
        border-right: none;
        padding: 10px;

        &:hover {
            background-color: $whitelabel-primary;
            color: $white;
            cursor: pointer;
        }

        &:last-of-type {
            border-right: 1px solid $whitelabel-lighter;
        }
    }

    &__feedback-comment {
        width: 100%;
        height: 110px;
        padding: 10px;
        resize: none;

        &::placeholder {
            font-family: $brand-primary-font;
        }
    }

    &__app-button {
        width: 130px;
    }

    &__cta {
        width: 100%;
        font-size: 16px;
        margin: 8px 0;
    }
    &__missing-food-cta {
        font-size: 14px;
        margin-bottom: 4px;
    }
}
