.dish {
    text-align: left;
    background-color: $white !important;

    &__list--loading {
        /* 71px: footer's height */
        min-height: calc(100vh - 71px);
        padding-top: 20px;
    }

    &__image-container {
        position: sticky !important;
        top: 67px;
    }

    &__image {
        height: calc(100vh - 100px);
        background-size: cover;
        background-position: 0% 70%;
        .halal-logo {
            width: 98px;
            height: 45px;
            border-radius: 0px 8px 8px 0px;
            object-fit: cover;
            position: fixed;
            bottom: 100px;
            left: 0px;
        }
    }

    &__title {
        font-size: 28px;
        font-weight: bold;
        max-width: 75%;
        line-height: 35px;
        transform: translateY(-5px);
    }

    &__sub-heading {
        margin-top: 5px;
    }

    &__price {
        font-family: $brand-boldest-font;
        margin-top: 32px;
    }

    &__free-delivery {
        font-family: $brand-primary-font;
        font-size: 12px;
    }

    &__dash {
        width: 40px;
        border: 1px solid;
        display: inline-block;
        margin: 15px 0;
    }

    &__add-to-cart {
        margin-top: 16px;
        width: 200px;
        height: 36px;
        margin-bottom: 55px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
            line-height: initial !important;
        }
    }

    &__view-content {
        margin-top: 30px;
        margin-bottom: 60px;
        // text-align: justify;
    }

    &__share-message {
        position: relative;
        top: 5px;
    }

    &__social-icon {
        width: 1.5em;
        height: 1.5em;
    }

    &__sharing {
        display: none;
    }
}
