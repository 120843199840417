.terminal-payment-option {
    display: flex;
    justify-content: center;
    margin-top: 90px;
    margin: 30px 50px;
    flex-direction: column;
    width: 700px;
    text-align: center;

    &--large {
        width: 950px;
        margin: 30px 20px;
    }

    .payment-card {
        margin: 10px 15px;
    }

    &__title {
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 34px;
        text-align: center;
        letter-spacing: -0.527692px;
        color: $whitelabel-black;
    }

    &__button {
        background-color: $whitelabel-primary;
        border-radius: 12px;
        width: 349px;
        height: 50px;
        font-family: $brand-primary-font;
        font-weight: 600;
        font-size: 24px;
        line-height: 18px;
        border-width: 0px;
        color: black !important;
    }
}
