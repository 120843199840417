$login-border-color: #d6d6d6;
$errors-background: #ffeaeb;

.auth-panel {
    padding: 20px;
    padding-top: 0px;
    font-size: 20px;
    width: 530px;
    margin: 0 auto;
    margin-top: 20px;
    background-color: $white;
    border-top: 6px solid $whitelabel-blue;

    &__tab-header {
        background: none;
        padding: 10px;
        border: none;
        font-size: 15px;
        border-bottom: 1px solid $login-border-color;
        width: 50%;
        color: $whitelabel-light;
        outline: none;

        &--active {
            border-bottom: none;
            border-left: 1px solid $login-border-color;
            border-right: 1px solid $login-border-color;
            border-top: 1px solid $login-border-color;
            color: $black;
            font-family: $brand-boldest-font;
        }
    }

    &__login,
    &__register {
        width: 80%;
        margin: 0 auto;
    }

    &__title {
        font-family: $brand-boldest-font;
    }

    &__errors {
        background-color: $errors-background;
        margin-top: 20px;
        font-size: small;
        padding: 10px;
    }

    &__error-icon {
        margin-right: 10px;
        position: relative;
        top: 1px;
    }

    &__notice-board {
        padding: 30px;
        font-size: small;
        line-height: 150%;
    }

    &__separator {
        display: block;
        font-weight: bold;

        &-text {
            position: relative;
            background-color: $white;
            display: inline;
            z-index: 1;
        }

        &::after {
            content: '';
            display: block;
            margin: auto;
            margin-top: -12px;
            position: relative;
            width: 90%;
            border-bottom: 1px solid $whitelabel-lighter;
        }
    }
}
