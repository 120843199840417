.address-bar {
    position: fixed;
    width: 100%;
    text-align: left;
    padding: 20px 0 15px;
    background-color: white;
    z-index: 95;
    &__container {
        display: flex;
        align-items: center;
    }
    &__selection-button {
        padding: 15px 20px;
        border-radius: 5px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
        border: solid 1px #f7f7f7;
        background-color: #ffffff;
        cursor: pointer;

        &:first-child {
            margin-right: 15px;
        }
    }
    &__whatsapp-order-button {
        margin-left: auto;
        display: inline-flex;
        height: 48px;
        padding: 15px 20px;
        align-items: center;
        gap: 12px;
        flex-shrink: 0;
        border-radius: 5px;
        background: #25D366;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
        cursor: pointer;
        p {
            color: var(--White-Primary, #FFF);
            text-align: right;
            font-feature-settings: 'clig' off, 'liga' off;
            /* Button 1 */
            font-family: Barlow;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal; 
        }
    }
    &__address,
    &__date-time {
        position: relative;
    }
    &__address-header-text {
        font-family: $brand-bolder-font;
    }
    &__address-text {
        font-family: $brand-bolder-font;
        color: #b7b7b7;
    }
    &__search-icon {
        width: 15px;
        height: 15px;
        margin-right: 10px;
    }
    &__arrow-icon {
        stroke-width: 2px;
        margin-left: 5px;
        width: 10px;
    }
}
