.addon-panel {
    &__small-list {
        .column-container {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    &__big-list {
        .item-container {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}

@media (min-width: 651px) and (max-width: 880px) {
    .addon-panel {
        &__small-list {
            .column-container {
                grid-template-columns: repeat(3, 1fr);
            }
        }

        &__big-list {
            .item-container {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
}
