.add-to-cart-button {
    transition: 1s;
    &__button {
        padding: 7px 6px;
        padding-top: 8px !important;
        font-size: 13px;
        width: 100%;
        &.small-font {
            font-size: 11px;
        }
    }
}

@media (min-width: 501px) {
    .add-to-cart-button {
        &__button {
            padding: 7px 3px;
        }
    }
}
