.banner {
    width: 100%;
    position: fixed;
    transform: translateY(-100px);
    overflow: hidden !important;
    z-index: 1000;
    display: flex;
    transition: transform 0.3s ease;

    &--show {
        transform: translateY(0px);
        min-height: 60px;
    }

    &__content {
        flex: 1;
        width: 100%;
        text-align: center;
        margin: auto;
        padding: 7px 30px;
        font-size: 16px;

        &:empty {
            padding: 0px;
        }
    }

    &--error {
        color: $white;
        background-color: $whitelabel-red;

        .banner__close-button {
            background-color: lighten($whitelabel-red, 10%);
        }
    }

    &--success {
        color: $black;
        background-color: #fff1bf;

        .banner__close-button {
            background-color: lighten($whitelabel-primary, 10%);
        }
    }

    &--info {
        color: $white;
        background-color: $whitelabel-blue;

        .banner__close-button {
            background-color: lighten($whitelabel-blue, 10%);
        }
    }

    &--warning {
        color: $white;
        background-color: $whitelabel-orange;

        .banner__close-button {
            background-color: lighten($whitelabel-orange, 10%);
        }
    }

    &__close-button {
        right: 0;
        border: none;
        padding: 4px;
        min-width: 60px;
        flex: none;
    }

    &__cross-icon {
        width: 2.5em;
        height: 2.5em;
        fill: $white;
    }
}
