.section-hero {
    background-position: center bottom;

    &--delicious-lunch-colleagues-v12 {
        background-size: 1550px;
    }

    &--delicious-lunch-colleagues-v13 {
        background-size: 1120px;
    }
}
