.terminal-card-status {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 8;

    &__container {
        position: relative;
        background-color: #F4594F;
        border-radius: 12px;
        width: 175px;
        height: 50px;
        display: flex;
        justify-content: center;
        text-align: center;
        padding: 10px;
        padding-right: 5px;
        padding-top: 7px;
    }

    &__text {
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        text-align: left;
        color: white;
        height: 100%;
        margin-bottom: 15px;
    }

    &__loader {
        width: 29px;
        height: 29px;
        margin-top: 3px;
    }
}