.payment-method-item {
    padding-top: 8px;
    padding-bottom: 8px;

    &__button {
        width: 20px;
        height: 20px;
    }

    &__sub-heading {
        color: $black;
    }
}
