.terminal-phone-popup {
    width: 295px;
    height: 240px;
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .number-popup {
        height: 190px;
    }

    &__title {
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 29px;
        color: $whitelabel-black;
        width: 100%;
    }

    &__container {
        margin-top: 0px;
        padding: 0px;
        width: 100%;
        margin-bottom: 22px;
    }

    &__sub-heading {
        line-height: 125%;
        width: 340px;
        font-size: 20px;
        margin-bottom: 60px;
        color: rgba(0, 0, 0, 0.69);
        font-family: $brand-bolder-font;
    }

    &__input-wrapper {
        padding: 0px;
        margin: 0px;
        margin-top: 8px;
    }

    &__input-name {
        height: 45px;
        border-right: 1px solid $whitelabel-lighter;
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 16px !important;
        line-height: 16px;
    }

    &__input_number_container {
        display: flex;
        background-color: #f2f3f3;
    }

    &__input-country-code {
        width: 48px;
        height: 45px;
        padding-left: 0px;
        padding-right: 0px;
        outline: none;
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        border: 0.5px solid #dfe0e1;
        border-radius: 4px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__input-number {
        padding-left: 10px;
        height: 45px;
        border: none;
        font-family: $brand-bolder-font;
        background-color: #f2f3f3;
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: $whitelabel-black;
        border-radius: 4px !important;

        &::placeholder {
            font-family: $brand-primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 16px !important;
            line-height: 16px;
            color: #b0b2b3 !important;
        }
    }

    &__input-number-error {
        padding-left: 10px;
        height: 45px;
        width: 100%;
        border: none;
        font-family: $brand-bolder-font;
        background-color: #f2f3f3;
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: $whitelabel-black;
        border-radius: 4px !important;
        background: linear-gradient(0deg, rgba(244, 89, 79, 0.2), rgba(244, 89, 79, 0.2));
        border: 1.5px solid #F4594F;
    }

    &__error_message {
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 14px;
        color: #f4594f;
        margin-bottom: 10px;
        text-align: center;
    }

    &__CTA-container {
        transition: 1s ease;
        margin-top: 0px;
        padding: 0px;
        width: 100%;
    }

    &__CTA {
        padding: 0px;
        margin: 0px;
        transition: 1s ease;
        bottom: 0px;
        left: 0px;
        right: 0px;
        z-index: 100;
        width: 100%;
        height: 40px !important;
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: $whitelabel-black;
    }

    .disabled {
        background-color: #f2f3f3;
        color: #b0b2b3;
    }
}

.name-popup {
    height: auto;
}
