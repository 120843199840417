.delivery-note-explain {
    &__content {
        padding: 20px;
        padding-top: 60px;
    }

    &__CTA {
        width: 100%;
    }
}
