.input {
    border: 1px solid $whitelabel-lighter;
    padding: 9px;
    padding-top: 10px;
    box-sizing: border-box;
    color: $whitelabel-dark;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 14px;

    &::-webkit-input-placeholder {
        color: $whitelabel-lighter;
    }

    &:-moz-placeholder {
        /* Firefox 18- */
        color: $whitelabel-lighter;
    }

    &::-moz-placeholder {
        /* Firefox 19+ */
        color: $whitelabel-lighter;
    }

    &:-ms-input-placeholder {
        color: $whitelabel-lighter;
    }

    &.input--center-ph {
        &::-webkit-input-placeholder {
            text-align: center;
        }

        &:-moz-placeholder {
            /* Firefox 18- */
            text-align: center;
        }

        &::-moz-placeholder {
            /* Firefox 19+ */
            text-align: center;
        }

        &:-ms-input-placeholder {
            text-align: center;
        }
    }

    &--error {
        border: 1px solid $whitelabel-red;
    }
}

.new-input {
    height: 100%;
    width: 100%;
    border-radius: 5px;
    border: 1px solid $whitelabel-lighter;
    padding: 1px;
    overflow: hidden;
    &__box {
        width: 100%;
        padding: 13px 15px;
        padding-top: 14px;
        font-size: 16px;
        border: none;
    }

    &__box:focus,
    &__country-code:focus {
        outline: none !important;
    }

    &__country-code-container {
        display: flex;
        width: 80px;
    }

    &__country-code {
        margin-top: auto;
        margin-bottom: auto;
        border: none;
        line-height: 10px;
        width: 100%;
        height: 100%;
        font-size: 16px;
    }

    &__input-separator {
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;
        width: 1px;
        background-color: $whitelabel-lighter;
        height: 75%;
    }

    &__error-message-container {
        text-align: left;
    }

    &__custom-message-container {
        margin: 15px 0;
        text-align: left;
        span {
            font-family: $brand-boldest-font;
        }
    }
}

.new-input.focus {
    border: 2px solid $whitelabel-primary;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 0px !important;
}
