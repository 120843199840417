$gray-section: #edebec;

.landing {
    font-size: 18px;

    &__title {
        font-size: 36px;
        padding-top: 45px;
    }

    &__hero {
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 0;
        filter: brightness(70%);
        width: 100%;
    }

    &__overlay {
        position: relative;
        width: 100%;
        height: 100%;
    }

    &__section-title {
        font-size: 32px;
        font-family: $brand-bolder-font;
        line-height: 40px;
    }

    &__paragraph {
        max-width: 80%;
        line-height: 120%;
        margin-left: auto;
        margin-right: auto;
    }
}
