.menu-footer-show {
    position: initial !important;
}

.menu-footer-hide {
    position: initial !important;
}

.menu-footer {
    padding-top: 30px;

    &__container {
        flex-direction: column;
    }

    &__container {
        padding: 0;
    }

    &__locale {
        order: 1;
    }

    &__bottom {
        margin-left: initial;
        margin-right: initial;
        flex-direction: column;
    }

    &__social {
        width: 125px;
        display: flex;
        justify-content: space-evenly !important;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 15px !important;
        a {
            margin-right: 0;
        }
    }

    &__links {
        padding: 20px 0;
        margin: auto;
        order: 2;
    }

    &__links a {
        font-size: 13px;
    }

    &__apps {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 15px !important;
        width: 250px;

        a {
            margin-right: 0 !important;

            &:last-child {
                flex-basis: 100%;
                flex-grow: 1;
                margin-top: 10px;
            }
        }
    }

    &__payment-methods {
        width: 250px;
        margin-left: auto;
        margin-right: auto;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        div {
            margin-right: initial;
            margin: 0 8px;
            margin-bottom: 22px;
        }
    }

    &__socials {
        //order: 3;
        margin-bottom: 10px;
    }

    &__app-store {
        margin-right: auto !important;
    }

    &__google-play {
        margin-left: auto;
    }

    &__bottom {
        margin-bottom: 24px;
    }

    &__cod-logo {
        margin-right: 0 !important;
    }

    .border-bottom {
        border-bottom: initial;
    }
}
