.order-status-banner {
    cursor: pointer;
    margin-bottom: 50px;

    &__left {
        background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/order-status-left.png');
        height: 52px;
        width: 40px;
        position: absolute;
        left: 0;
        top: 55px;
    }

    &__right {
        background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/order-status-right.png');
        height: 53px;
        width: 43px;
        position: absolute;
        right: 0;
        top: 55px;
        float: right;
    }

    &__container {
        &.bottom-card {
            .order-status-banner {
                position: fixed;
                bottom: 90px;
                z-index: 9;
                width: 420px;
                left: 10px;
                margin: 0 10px;

                &__content {
                    background-color: $white;
                    border: 1px solid #e8e8e8;
                    border-radius: 8px;
                    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.04),
                        0 4px 8px 0 rgba(0, 0, 0, 0.04),
                        0 16px 24px 0 rgba(0, 0, 0, 0.04),
                        0 -54px 32px 0 rgba(0, 0, 0, 0.04);
                }
            }
        }
    }

    &__close {
        position: absolute;
        right: 12px;
        top: 12px;
    }

    &__text {
        color: $black;
        font-size: 22px;
        line-height: 160%;

        &--large {
            font-size: 26px;
        }

        &--small {
            font-size: 18px;
        }
    }

    &__image {
        width: 64px;
        height: 64px;
        background-size: cover;
        background-position: 50% 55%;
        display: inline-block;

        &:before {
            content: '';
            height: 80px;
            width: 10px;
            border-radius: 8px;
            background-color: $whitelabel-primary;
            position: absolute;
            display: block;
            top: 50%;
            left: 14px;
            transform: translate(0, -50%);
        }
    }

    &__content {
        padding-top: 20px;
        padding-bottom: 20px;
        border-radius: 5px;
        background-color: #fff1bf;
        color: $black;

        // &--delayed {
        //     background-color: $whitelabel-orange;
        // }

        // &--pending {
        //     background-color: $whitelabel-blue;
        // }

        &--arrived {
            // background-color: $whitelabel-primary;
            padding-bottom: 18px;
        }

        &--delivered {
            // color: $black;
            text-align: left;
            padding: 20px 36px;
            display: flex;
        }

        &--delivering {
            // background-color: $whitelabel-primary;
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }
}
