.promotion-bar {
    height: initial;
    flex-direction: column;
    text-align: center;
    &__text-container {
        padding: 15px 25px;
        line-height: 21px;
        margin-right: auto;
    }
    &__text-container.variant-four {
        margin-right: auto;
    }
    &__text.one {
        font-size: 12px;
    }

    &__error {
        width: initial;
        text-align: left;
    }

    &__referral-login-container {
        width: 85%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0;
        margin-bottom: 15px;
    }
    &__referral-login {
        width: 100%;
    }
    &__referral-login-cta {
        margin-left: 6px;
    }
    &__referral-input-container {
        height: 40px !important;
        width: initial;
    }

    .new-input__country-code-container {
        width: 40%;
    }

    .new-input__country-code {
        padding-left: 7px;
        padding-right: 0;
    }
}

.promotion-bar.variant-four {
    height: initial;
}
