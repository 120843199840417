.shopping-cart-address {
    max-width: 100%;
    &__item {
        padding-bottom: 32px;

        &--show-view {
            display: block;
        }
    }

    &__confirm-location-button {
        width: 100%;
    }

    &__btn {
        &--change-address {
            margin-top: 20px;
            width: 80px;
            padding: 0px;
        }

        &--add-address {
            min-width: 180px;
            padding: 0px;
        }

        &--first-add-address {
            width: 100%;
        }
    }

    &__change-btn {
        margin-top: 15px;
    }

    &__popup-content {
        transform: translate(-50%, 135px);
        width: 90%;
        height: 80vh;
    }

    &__address-preview {
        background-color: white;
    }

    &__popup {
        width: 100% !important;
        padding: 0 25px;
        &--adjust,
        &--note {
            min-width: auto;
            padding: 20px 25px;
            padding-top: 0;
            margin-top: -10px;
            overflow-x: hidden;
            -webkit-overflow-scrolling: touch !important;
        }

        &--explaining {
            min-width: auto;
            max-height: initial;
            padding: 0 !important;
        }
    }

    &__popup-content {
        .shopping-cart-address__popup {
            padding-top: 30px;
            padding-bottom: 100px;
        }

        &--PopupTyping,
        &--PopupAdjusting,
        &--PopupNote,
        &--PopupHouseExplained,
        &--PopupCondoExplained,
        &--PopupOfficeExplained {
            width: 100%;
            height: 100%;
            max-height: 100%;
            top: 0px;
            left: 0px;
            transform: none;
            //overflow-y: auto;
            overflow-x: hidden;
        }

        &--PopupHouseExplained,
        &--PopupCondoExplained,
        &--PopupOfficeExplained {
            .modal__close {
                display: none;
            }
        }
    }

    &__popup-header {
        background-color: transparent;
        color: initial;
        padding: 35px 25px;

        &__extra-margin {
            padding-top: 25px;
        }

        &__text {
            font-size: $medium * 1.5;
            margin-top: auto;
            margin-bottom: auto;
        }

        &__back {
            top: 24px;
            left: 16px;
            position: relative;
            width: 1.25em;
            height: 1.25em;
        }

        &__got-it {
            position: relative;
            top: 24px;
            right: 16px;
        }

        &__title {
            font-size: initial;
        }

        &--left,
        &--right {
            background: none;
            width: auto;
        }

        &--center {
            font-size: 20px;
            line-height: 75px;
        }
    }

    &__title {
        padding-top: 8px;
        margin-bottom: 20px;
    }

    &__confirm {
        width: 100%;
    }

    &__address-label {
        margin-top: 20px;
        margin-bottom: 10px;
        display: block;
    }

    &__input {
        padding-top: 0;
    }

    &__input-area {
        flex-direction: column !important;
        margin-top: -10px;

        .building-name {
            width: initial;
        }
    }

    &__label {
        &--address-type {
            margin-top: 16px;
            margin-bottom: 5px;
            font-size: 15px;
            text-align: center;
        }
    }

    &__popup-footer {
        background-color: $white;
        padding: 20px 25px;
        padding-top: 0px;
        width: 100%;
    }

    &____popup-type-address {
        height: 100vh !important;
    }

    &__map {
        width: 100%;
        height: 100%;
    }

    &__map-container {
        height: calc(100vh - 275px);
        margin-bottom: 25px;
    }

    &__error-message {
        padding-bottom: 0px !important;

        &:empty {
            display: none;
        }
    }
}
