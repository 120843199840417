.hamburger-menu {
    &__content {
        position: fixed;
        top: 0px;
        left: 0px;
        right: 30%;
        bottom: 0px;
        transform: translateX(-100%);
        background-color: $white;
        z-index: 50;
        padding: 25px;
        font-size: 18px;
        transition: transform 0.4s ease;
        overflow-y: scroll;

        &--show {
            transform: translateX(0%);
        }
    }

    &__item {
        padding-top: 15px;
        padding-bottom: 15px;
        color: $black;

        &--underlined {
            border-bottom: 1px solid $whitelabel-lightest;
        }
    }
}
