.rewards {
    .menu-header {
        position: static;
        margin-top: 0 !important;
    }

    background-color: $whitelabel-lightest;

    &__header {
        height: 135px;
        background-size: cover;
        background-color: $whitelabel-primary;
        color: $whitelabel-secondary;
        text-align: center;
        padding-top: 55px;
    }

    &__title {
        font-family: $brand-bolder-font;
        font-size: 30px;
    }

    &__content {
        width: 550px;
        margin: 0 auto;
        margin-top: -16px;
    }

    &__button {
        background-color: $whitelabel-primary;
        //width: 300px;
    }

    &__box {
        background-color: $white;
        margin-bottom: 12px;
        padding: 40px 22px;
    }

    &__rewards-box {
        background-color: $white;
        margin-bottom: 12px;
        padding-top: 15px;
        padding-bottom: 27px;
    }

    &__box-header {
        font-size: 28px;
        font-weight: bold;
        letter-spacing: -0.3px;
        color: #000000;
    }

    &__box-body {
        &:empty {
            display: none;
        }
    }

    &__share-container {
        flex-wrap: wrap;
        justify-content: space-between;

        > a,
        > button {
            width: 31.5%;
        }
    }

    &__details-text {
        font-size: 16px;
        font-weight: 300;
        line-height: 1.38;
        color: #000000;
    }

    &__input {
        height: 45px;
    }

    &__countryCode {
        width: 15%;
    }

    &__phone {
        width: 85%;
    }
}
