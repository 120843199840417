.careers__testimonials {
    background-color: $whitelabel-pastel-yellow;
    margin-bottom: 65px;

    &__main-container {
        padding: 50px 0;
        position: initial;
        display: block;
        top: initial;
        left: initial;
    }

    &__content-container {
        width: 87%;
        margin-top: initial;
        margin-bottom: initial;
        margin-left: auto !important;
        margin-right: auto !important;
        overflow: visible;
    }

    &__testimonial-header {
        width: 87%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
    }

    &__img-container {
        display: flex;
        width: 87%;
        margin-bottom: 38px;
        margin-left: auto;
        margin-right: auto;
    }

    &__testimonial {
        margin-right: initial;
        margin-bottom: 25px;
        width: initial;
        height: 300px;
        p {
            font-size: 20px;
            line-height: 1.5;
        }
    }

    &__employee-info {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 13px;
        &--name {
            font-size: 20px;
            margin-bottom: 3px;
        }
        &--title {
            font-size: 16px;
        }
    }
}
