.about__section-hero {
    width: 100%;
    margin-bottom: 75px;
    &__bg-image {
        margin-left: auto;
        margin-right: auto;
        width: 89%;
        height: 596px;
        background: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/about-hero-new.jpg');
        background-size: cover;
        position: relative;
    }
    &__content-container {
        background-color: white;
        width: 438px;
        position: absolute;
        top: 154px;
    }
    &__content {
        padding: 45px 55px;
    }
    &__header {
        margin-bottom: 25px;
        font-size: 36px;
    }
    &__body {
        width: 260px;
        margin-bottom: 25px;
        line-height: 1.5;
    }
    &__cta {
        height: 45px !important;
        width: 193px !important;
    }
}
