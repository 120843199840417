.terminal-header {
    background-color: $white;
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 2px solid $whitelabel-line-separator;

    .hamburger-icon__line {
        border-bottom-color: $black;
    }

    &__cutoff-notice {
        font-size: 28px;
    }

    &__delivery-notice {
        font-size: 20px;
    }
}
