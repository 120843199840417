.careers__view-jobs {
    &__highlight {
        opacity: 1 !important;
    }

    &__container {
        display: flex;
        margin: auto 32px;
    }

    &__information {
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 128px;
        span {
            font-size: 28px;
            opacity: 0.5;
        }
    }

    &__cta {
        padding: 13px 32px !important;
    }
}
