$button-disabled: #d0d0d0;
$button-facebook: #2d4386;
$button-google: $white;
$button-whatsapp: #128c7e;
$button-messenger: #0084ff;

@keyframes busy {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 400px 400px;
    }
}

.button {
    border: none;
    border-radius: 5px;
    min-height: 35px;
    font-size: $medium;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    line-height: 150%;
    color: $black;
    background-color: $whitelabel-lighter;
    font-size: 14px;
    font-family: $brand-boldest-font;

    &--busy {
        pointer-events: none;
        background-color: $whitelabel-lighter !important;
        overflow: hidden;
        position: relative;

        &::after {
            content: '';
            display: block;
            position: absolute;
            left: -10px;
            bottom: 0px;
            width: calc(100% + 10px);
            box-sizing: border-box;
            background-size: 400px 400px;
            background-image: linear-gradient(
                -45deg,
                $whitelabel-primary 25%,
                transparent 25%,
                transparent 50%,
                $whitelabel-primary 50%,
                $whitelabel-primary 75%,
                transparent 75%,
                transparent
            );
            height: 2px;
            animation: busy 2s linear infinite;
        }
    }

    &--busy.wallet {
        &::after {
            background-image: linear-gradient(
                -45deg,
                $whitelabel-blue 25%,
                transparent 25%,
                transparent 50%,
                $whitelabel-blue 50%,
                $whitelabel-blue 75%,
                transparent 75%,
                transparent
            );
        }
    }

    &--busy.terminal {
        &::after {
            background-image: linear-gradient(
                -45deg,
                $whitelabel-red 25%,
                transparent 25%,
                transparent 50%,
                $whitelabel-red 50%,
                $whitelabel-red 75%,
                transparent 75%,
                transparent
            );
        }
    }

    &--busy.select {
        &::after {
            background-image: linear-gradient(
                -45deg,
                $whitelabel-red 25%,
                transparent 25%,
                transparent 50%,
                $whitelabel-red 50%,
                $whitelabel-red 75%,
                transparent 75%,
                transparent
            );
        }
    }

    &:hover {
        background-color: lighten($whitelabel-lighter, 7%);
    }

    &--larger {
        font-size: $medium;
        max-height: 46px;
        min-height: 46px;
    }

    &--large {
        font-size: $medium * 0.8;
        max-height: 40px;
        min-height: 40px;
    }

    &--medium {
        font-size: $medium * 0.8;
        max-height: 32px;
        min-height: 32px;
    }

    &--small {
        font-size: $medium * 0.75;
        max-height: 28px;
        min-height: 28px;
    }

    &--secondary {
        background-color: $whitelabel-secondary;

        &:hover {
            background-color: lighten($whitelabel-secondary, 7%);
        }
    }

    &--info {
        color: $white;
        background-color: $whitelabel-blue;

        &:hover {
            background-color: lighten($whitelabel-blue, 7%);
        }
    }

    &--danger {
        background-color: $whitelabel-red;

        &:hover {
            background-color: lighten($whitelabel-red, 7%);
        }
    }

    &--success {
        background-color: $whitelabel-primary;

        &:active,
        &:hover {
            background-color: lighten($whitelabel-primary, 7%);
        }
    }

    &--red {
        background-color: $whitelabel-red;

        &:hover {
            background-color: lighten($whitelabel-red, 7%);
        }
    }

    &--white {
        background-color: white;

        &:hover {
            background-color: lighten(white, 7%);
        }
    }

    &--warning {
        background-color: $whitelabel-orange;

        &:hover {
            background-color: lighten($whitelabel-orange, 7%);
        }
    }

    &--dark {
        background-color: $whitelabel-dark;

        &:hover {
            background-color: lighten($whitelabel-dark, 15%);
        }
    }

    &--terminal-dinner {
        background-color: $black;

        &:hover {
            background-color: lighten($black, 15%);
        }
    }

    &--disabled {
        background-color: #f2f3f3;
        color: #b0b2b3;
        cursor: default;

        &:hover {
            pointer-events: none;
            background-color: #f2f3f3;
            color: #b0b2b3;
        }
    }

    &--outline {
        background-color: transparent;
        color: $black;
        border: 1px solid $whitelabel-lighter;

        &:hover {
            background-color: lighten($whitelabel-lighter, 15%);
        }
    }

    &--nothing {
        background-color: transparent;
        border: none;
        color: inherit;

        &:hover {
            background-color: transparent;
        }
    }

    &--facebook {
        color: $white;
        background-color: $button-facebook;

        &:hover {
            background-color: lighten($button-facebook, 5%);
        }
    }

    &--messenger {
        color: $white;
        background-color: $button-messenger;

        &:hover {
            background-color: lighten($button-messenger, 5%);
        }
    }

    &--whatsapp {
        color: $white;
        background-color: $button-whatsapp;

        &:hover {
            background-color: lighten($button-whatsapp, 5%);
        }
    }

    &--sms {
        background-color: $white;
        color: #484848;
        border: 1px solid #c4c4c4;
    }

    &--google {
        background-color: $button-google;
        border: 1px solid black;
        margin: -1px 0;
        color: #4a4a4a;

        &:hover {
            background-color: lighten($button-google, 5%);
        }
    }

    &--select {
        background-color: $whitelabel-red;

        &:active {
            background-color: lighten($whitelabel-red, 5%);
        }

        &:hover {
            background-color: lighten($whitelabel-red, 5%);
        }
    }

    &--navbar {
        padding: 6px 10px 4px 10px;
    }
}
