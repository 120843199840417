.account-switcher {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    text-align: right;
    position: relative;

    &__switcher-container {
        background-color: white !important;
    }

    &__dropdown {
        font-size: 10px;
        margin-left: 7px;
        stroke-width: 2px;
    }

    &__list {
        text-align: left;
        padding: 0 15px;
    }

    &__list-item-name {
        margin-top: auto;
        margin-bottom: auto;
    }

    &__list-item {
        padding: 15px 0;
        border-bottom: solid 1px #f2f2f2;
    }

    &__list-item .company-details {
        margin-top: 10px;
        color: #000000;
        opacity: 0.5;
        font-size: 12px;
        line-height: 1.33;
    }

    .dropdown__container {
        right: -12px;
        top: 31px;
    }
}
