.terminal-splash-screen {
    background-color: $whitelabel-primary;
    font-family: $brand-semi-condensed-font;
    font-style: normal;
    font-weight: 600;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 37px 0px;
    user-select: none !important;

    &__makan {
        font-size: 64px;
        line-height: 67px;
        text-transform: uppercase;
        font-family: $brand-semi-condensed-font;
    }

    &__reward_lucky_wheel {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: black;
    }

    &__DI_TA {
        display: flex;
        margin-top: 32px;
    }

    &__order_option {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 9px;
        background-color: $white;
        margin: 0px 15px;
        padding: 16px;
        cursor: pointer;
    }

    .dine_in {
        color: #f4594f;
    }

    .takeaway {
        color: $whitelabel-secondary;
    }

    &__order_option_img {
        width: 333px;
        height: 356px;
        border-radius: 9px;
    }

    &__order_option_text {
        font-size: 59px;
        line-height: 39px;
        margin: 40px 0px 22px;
        text-transform: uppercase;
        font-family: $brand-semi-condensed-font;
    }

    &__language_selector {
        display: flex;
        margin-top: 32px;
    }

    &__language {
        background-color: $white;
        margin: 0px 15px;
        border: none;
        padding: 5px 20px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 191px;
        height: 48px;
        color: $whitelabel-gray;

        .name {
            font-size: 20px;
            line-height: 23px;
            margin-right: 5px;
            font-weight: 600;
            font-family: $brand-semi-condensed-font;
        }
    }

    .selected {
        background-color: #303234;
        color: $white;
        cursor: auto;
    }
}
