.user-avatar {
    &__avatar {
        margin-top: -35px;
        height: 85px;
        width: 84px;
        margin-left: auto;
        margin-right: auto;
        background-size: cover !important;
        border-radius: 100%;
    }
}
