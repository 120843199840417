.cart {
    &__gray {
        background-color: $whitelabel-lightest;
    }

    &__image {
        max-width: 200px;
    }

    &--empty {
        background-color: $white;
        text-align: center;
        padding: 50px;
        width: 50%;
        margin: auto;
        margin-top: 50px;
        border-radius: 5px;
    }

    &__help {
        width: 0.75em;
        height: 0.75em;

        margin-right: 10px;
    }

    &__smart-reload-tooltip {
        width: 300px;
        background-color: $whitelabel-dark;
        color: $white;
        padding: 15px;
        border-radius: 4px;
        font-size: 14px;
        line-height: 120%;
    }

    .order-summary {
        padding: 0px;
    }
}
