.terminal-mobile-cart-screen {
    padding: 24px 0px 0px;

    .back_btn {
        margin: 0px 16px;
    }

    &__review_text {
        margin: 32px 16px;
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
    }

    &__cart_items {
        padding: 0px 16px;
    }

    .separator {
        width: 100%;
        height: 6px;
        margin: 24px 0px;
        background-color: #efeff0;
    }

    &__price_container {
        display: flex;
        flex-direction: column;
        padding: 0px 16px;
    }

    &__price_row {
        display: flex;
        justify-content: space-between;
        margin: 5px 0px;
    }

    .subtotal_row {
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 14px;
        color: $whitelabel-black;
    }

    .total_row {
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 13px;
        color: $whitelabel-black;
    }

    &__cta_container {
        border-top: 2px solid #e6e6e6;
        padding: 14px 16px;
    }

    .position-absolute {
        position: absolute;
        bottom: 0px;
    }

    .position-below {
        position: static;
        margin-top: 10px;
    }

    &__cta_btn {
        border: none;
        background-color: $whitelabel-primary;
        padding: 12px 0px;
        width: calc(100vw - 32px);
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: $whitelabel-black;
        height: 40px;
    }

    &__menu_modal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 24px 16px;
    }

    &__modal_title {
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 25px;
        color: $whitelabel-black;
    }

    &__modal_cta_btn {
        margin-top: 24px;
        border: none;
        background-color: $whitelabel-primary;
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: $whitelabel-black;
        border-radius: 5px;
        width: 100%;
        padding: 12px;
        height: 40px;
    }

    &__heading {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: -0.4px;
        color: #131415;
        margin-left: 16px;
    }

    &__sub-heading {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: -0.4px;
        color: #131415;
        mix-blend-mode: normal;
        opacity: 0.5;
        margin-left: 16px;
        margin-top: 8px;
    }

    &__thank-you-text {
        margin-top: 16px;
        margin-left: 16px;
        margin-right: 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: #00AA1B;
    }

    &__switch-button {
        margin-right: 16px;
    }
}
