.google-reviews {
    margin-bottom: 104px;
    &__header-container {
        margin-bottom: 40px;
    }
    &__header {
        font-size: 24px;
        line-height: 1.21;
    }
    &__rating-container {
        display: flex;
        margin-bottom: 35px;
    }
    &__rating {
        margin-right: 25px;
    }
    &__rating-score {
        font-size: 76px;
        line-height: 0.72;
        margin-bottom: 9px;
    }
    &__rating-count {
        font-size: 12px;
    }
    &__star-rating {
        display: flex;
        flex-direction: column;
        margin-top: auto;
        margin-bottom: auto;
    }
    &__reviews-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 25px;
    }
    &__review-container {
        height: 100%;
        border-radius: 10px;
        border: solid 1px #e7e7e7;
    }
    &__review {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 25px;
    }
    &__review-user-container {
        display: flex;
        margin-bottom: 15px;
    }
    &__review-avatar {
        margin-right: 15px;
        width: 54px;
        height: 54px;
        border-radius: 50%;
        background-size: cover !important;
    }
    &__review-name-container {
        margin-top: auto;
        margin-bottom: auto;
    }
    &__review-name {
        color: #185fc5;
    }
    &__review-content {
        line-height: 1.38;
    }
    &__review-body {
        margin-bottom: 28px;
    }
    &__stars {
        margin-top: auto;
    }
    &__google-logo {
        width: 75px;
        height: 25px;
        margin-bottom: 7px;
    }
}
