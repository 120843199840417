.careers__testimonials {
    position: relative;
    margin-bottom: 90px;
    &__green-bg {
        width: 100%;
        height: 502px;
        background-color: $whitelabel-pastel-yellow;
    }

    &__green-bg-2 {
        margin-left: auto;
        background-size: cover;
        width: 70%;
        height: 170px;
        background-color: $whitelabel-pastel-yellow;
    }

    &__main-container {
        position: absolute;
        left: 236px;
        top: 60px;
        display: flex;
    }

    &__content-container {
        margin-top: 55px;
        margin-left: 75px;
    }

    &__testimonial-header {
        margin-bottom: 62px;
    }

    &__testimonial {
        width: 80%;
        margin-bottom: 25px;
        position: relative;
        p {
            font-size: 28px;
            line-height: 1.43;
            font-weight: 300;
        }
    }

    &__icon {
        position: absolute;
        width: 47px;
        height: 35px;
        top: -10px;
        left: -25px;
    }

    &__employee-info {
        margin-bottom: 36px;
        &--name {
            font-size: 24px;
            font-weight: 300;
            margin-bottom: 10px;
        }
    }

    &__img {
        background-size: cover !important;
    }
}

@media (min-width: 1001px) and (max-width: 1300px) {
    .careers__testimonials {
        &__testimonial {
            p {
                font-size: 20px;
            }
        }
    }
}

@media (min-width: 901px) and (max-width: 1000px) {
    .careers__testimonials {
        &__main-container {
            left: 50px;
        }

        &__testimonial {
            p {
                font-size: 18px;
            }
        }
    }
}
