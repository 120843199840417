.login-cta {
    &__login-dropdown {
        width: 0.75em;
        height: 0.75em;
        margin-left: 5px;
        position: relative;
        top: 2px;
        stroke-width: 2px;
    }

    &__notification-dot {
        fill: $whitelabel-primary !important;
        height: 6px !important;
        top: 50% !important;
        transform: translate3d(4px, -8px, 0) !important;
        transform-origin-x: 7px !important;
        transform-origin-y: -5px !important;
        width: 6px !important;
        animation-name: keyframe_p1skye !important;
        animation-duration: 0.5s !important;
        animation-timing-function: ease !important;
        animation-fill-mode: both !important;
        opacity: 1 !important;

        &-hamburger {
            top: inherit !important;
        }
    }
}
