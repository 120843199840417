.about__section-how-it-works {
    width: 100%;
    margin-bottom: 65px;
    background-color: #effcf4;
    &__header-container {
        margin-left: auto;
        margin-right: auto;
    }
    &__header {
        font-size: 28px;
        margin-bottom: 15px;
        padding-top: 45px;
    }
    &__carousel-titles {
        margin-left: initial;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
    }
    &__title-container {
        margin-right: initial;
        margin-bottom: 50px;
    }
    &__carousel-number,
    &__carousel-title {
        font-size: 24px;
    }
    &__carousel-number {
        margin-right: 17px;
    }
    &__carousel-title {
        width: 70%;
        margin-bottom: 12px;
    }
    &__carousel-content {
        width: 70%;
        line-height: 1.5;
    }
}
