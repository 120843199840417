.login-dropdown {
    position: absolute;
    border: 1px solid $whitelabel-lighter;
    border-radius: 5px;
    overflow: hidden;
    background-color: $white;
    color: $black;
    margin-top: 27px;
    z-index: 200;
    width: 100%;
    right: -10px;
    min-width: 150px;

    &__backdrop {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }

    &__triangle {
        position: absolute;
        right: 0px;
        z-index: 201;
        top: 21px;

        &:before {
            content: '';
            position: absolute;
            width: 0px;
            height: 0px;
            right: 0px;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 7px solid $whitelabel-lighter;
        }

        &:after {
            content: '';
            position: absolute;
            width: 0px;
            height: 0px;
            right: 2px;
            top: 2px;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid $white;
            z-index: 3;
        }
    }
}
