.terminal-mobile-addons-screen {
    padding: 0px 16px 16px;

    .close_btn {
        margin-top: 20px;
    }

    &__addon_types {
        display: flex;

        .addon_type {
            margin: 24px 0px 16px;
            cursor: pointer;
            display: flex;
            flex-direction: column;

            .name {
                font-family: $brand-primary-font;
                font-style: normal;
                font-weight: 600;
                font-size: 25px;
                line-height: 29px;
                color: $whitelabel-black;
            }

            .horizontal_line {
                margin-top: 4px;
                width: 100%;
                height: 2px;
                background-color: $whitelabel-primary;
            }
            .drinks_line {
                margin-left: 16px;
                width: 82%;
            }
        }

        .drinks {
            margin-left: 16px;
        }

        .not-selected {
            .name {
                color: #b0b2b3;
            }
            .horizontal_line {
                display: none;
            }
        }
        .selected {
            color: $whitelabel-black;
        }
    }
}
