.calendar {
    &__loading {
        padding: 10px 0;
    }

    &__day-strip {
        display: flex;
        margin-top: 1px;
    }

    &__cell {
        margin-right: 15px;
        line-height: 18px;

        &__day-of-week {
            padding: 10px 0;
            font-size: 14px;
            border-top: 2px solid transparent;
            margin-left: auto;
            margin-right: auto;
        }

        &__day-of-week.active {
            border-top: 2px solid $black;
            font-family: $brand-boldest-font;
        }
    }
}
