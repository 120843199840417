.terminal-mobile-help-screen {
    padding: 0px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__close_btn {
        align-self: flex-end;
        margin-top: 18px;
    }

    &__heading {
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
    }

    &__steps {
        margin-top: 12px;
    }

    &__step {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 20px 0px;
    }

    &__serial_num {
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 33px;
        line-height: 33px;
        margin-right: 16px;
        min-width: 17.7px;
    }

    &__headline_desc {
        .description {
            margin-top: 8px;
            font-family: $brand-primary-font;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 23px;
        }
    }

    .step2_desc {
        //adding little bit extra margin for step2 description to make it consistent with step1 and step3, as they've the icons in desc
        margin-top: 16px;
    }

    &__cart_icon {
        border-radius: 50%;
        background-color: #fdc601;
        padding: 7px;
        width: 30px;
        height: 30px;
    }
    &__cart_number_icon {
        margin: 0px 5px;
        position: relative;
        top: 10px;
    }

    &__halal_pop_logo {
        width: 137px;
        height: 42px;
        position: fixed;
        bottom: 40px;
        left: 16px;
    }
}
