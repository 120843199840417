.section-food {
    margin-top: 65px;
    width: 100%;
    &__header {
        margin-bottom: 25px;
        h3 {
            font-size: 24px !important;
            line-height: 1.21;
        }
    }
    &__dish-container {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 25px;
        a {
            text-decoration: none;
        }
    }
    &__image {
        position: relative;
        background-size: cover !important;
        background-repeat: no-repeat;
        width: 100%;
        border-radius: 5px;
    }
    &__dish-name {
        position: absolute;
        top: 20px;
        left: 15px;
        color: $white;
        font-size: 18px;
        text-decoration: initial !important;
    }
}
