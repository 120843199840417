.section-dishes {
    padding-top: 50px;
    padding-bottom: 30px;

    &__title {
        width: 300px;
    }

    &__food-list {
        flex-wrap: wrap;
        padding-top: 45px;
    }
}
