// General components
@import 'reset';
@import 'base';
@import 'helpers';

// ==========================================LARGE================================================
// Core components can be used across application
@import 'core/large/imports';

// Import CSS for a whole page (usually general page layout, margin, padding, color)
@import 'pages/large/imports';

// Import CSS for page-components
@import 'page-components/large/about/imports';
@import 'page-components/large/landing/imports';
@import 'page-components/large/wallet/imports';
@import 'page-components/large/login/imports';
@import 'page-components/large/checkout/imports';
@import 'page-components/large/careers/imports';
@import 'page-components/large/chef/imports';
@import 'page-components/large/menu/imports';
@import 'page-components/large/dish/imports';
@import 'page-components/large/shoppingCart/imports';
@import 'page-components/large/business/imports';
@import 'page-components/large/profile/imports';
@import 'page-components/large/terminal/imports';
@import 'page-components/large/terminalAddress/imports';
// ===============================================================================================

// ===========================================HUGE================================================
@media (min-width: 1368px) {
    // Core components can be used across application

    // Import CSS for a whole page (usually general page layout, margin, padding, color)

    // Import CSS for page-components
    @import 'page-components/huge/landing/imports';

    // For landscape
}
// ===============================================================================================

// ===========================================MEDIUM==============================================
@media (min-width: 651px) and (max-width: 900px) {
    // Core components can be used across application
    @import 'core/medium/imports';

    // Import CSS for a whole page (usually general page layout, margin, padding, color)
    @import 'pages/medium/imports';

    // Import CSS for page-components
    //@import 'page-components/medium/about/imports';
    @import 'page-components/medium/landing/imports';
    @import 'page-components/medium/checkout/imports';
    @import 'page-components/medium/wallet/imports';
    @import 'page-components/medium/about/imports';
    @import 'page-components/medium/shoppingCart/imports';
    @import 'page-components/medium/business/imports';
    @import 'page-components/medium/careers/imports';
    @import 'page-components/medium/terminal/imports';
    @import 'page-components/medium/terminalAddress/imports';
    @import 'page-components/medium/chef/imports';
    @import 'page-components/medium/dish/imports';

    // For landscape
    @import 'page-components/landscape/landing/imports';
}
// ===============================================================================================

// ===========================================MEDIUM==============================================
// According to Bootstrap
@media (min-width: 651px) and (max-width: 1199px) {
    @import 'page-components/medium/menu/imports';
}

// ===========================================SMALL===============================================
@media (min-width: 300px) and (max-width: 650px) {
    // Core components can be used across application
    @import 'core/small/imports';

    // Import CSS for a whole page (usually general page layout, margin, padding, color)
    @import 'pages/small/imports';

    // Import CSS for page-components
    //@import 'page-components/small/about/imports';
    @import 'page-components/small/landing/imports';
    @import 'page-components/small/checkout/imports';
    @import 'page-components/small/login/imports';
    @import 'page-components/small/wallet/imports';
    @import 'page-components/small/menu/imports';
    @import 'page-components/small/shoppingCart/imports';
    @import 'page-components/small/dish/imports';
    @import 'page-components/small/business/imports';
    @import 'page-components/small/profile/imports';
    @import 'page-components/small/terminal/imports';
    @import 'page-components/small/terminalAddress/imports';
    @import 'page-components/small/careers/imports';
    @import 'page-components/small/about/imports';
    @import 'page-components/small/chef/imports';

    // For landscape
    @import 'page-components/landscape/landing/imports';
}
// ===============================================================================================

@import 'browser-fixes/imports';
@import 'grid';
@import 'spacings';
@import 'brand-overrides';
