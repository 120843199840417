.wallet-payment-confirm {
    width: 50%;
    min-width: 500px;
    margin: auto;
    text-align: center;
    font-size: 14px;
    background-color: $white;
    color: $whitelabel-light;

    &__header {
        background: $whitelabel-primary;
        color: $white;
        padding: 30px;
    }

    &__item-description {
        width: 100%;

        tr {
            border-bottom: 1px solid $whitelabel-lighter;
        }

        tr {
            line-height: 190%;
        }
    }

    &__order-summary {
        width: 50%;
        float: right;
        color: $whitelabel-dark;

        tr {
            line-height: 175%;
        }

        td {
            text-align: right;
        }
    }

    &__smart-reload {
        background-color: $whitelabel-lightest;
        padding: 13px;
        margin-top: 5px;
        margin-bottom: 20px;
    }

    &__apps {
        background-color: $whitelabel-lightest;
    }

    &__app-button {
        width: 150px;
    }
}
