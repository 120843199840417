.exit-popup {
    width: 33%;
    height: 90vh;
    max-width: 480px;
    max-height: 740px !important;
    border-radius: 5px;
    background-position: 0 0 !important;
    background-size: cover !important;
    &__content-wrapper {
        height: 100%;
        width: 100%;
        cursor: pointer;
    }
    &__content {
        color: white;
        padding: 85px 101px;
        text-align: center;
    }
    &__header-container {
        margin-bottom: 20px;
    }
    &__header {
        font-size: 24px;
        line-height: 1.21;
    }
    &__subheader-container {
        margin-bottom: 30px;
    }
    &__cta {
        width: 164px;
        height: 45px;
        margin-left: auto;
        margin-right: auto;
    }
    &__close-icon {
        fill: white !important;
        width: 15px !important;
        height: 15px !important;
    }
    .button--white {
        color: black;
    }
}
