.addon-upsell {
    // display: none;
    &__column {
        &:nth-of-type(2) {
            margin-left: 0;
            margin-right: 0;
        }

        &:first-of-type {
            padding-left: 0;
            margin-right: 0;
        }

        &:last-of-type {
            margin-left: 0;
            padding-right: 0;
        }
    }
}
