.menu-item {
    &__link {
        margin: 10px;
    }
    &__link.skeleton {
        margin-bottom: 18px;
    }
    &__price-container {
        margin-left: 10px;
        margin-right: 10px;
    }
    &__button-container {
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
    }
    &__tag-container {
        top: 0 !important;
        left: 0 !important;
        margin-left: 10px;
        margin-right: 10px !important;
        margin-top: 10px;
        flex-wrap: wrap;
    }
    &__rating-container.skeleton {
        margin-top: 3px;
        margin-bottom: 10px;
        width: 100%;
    }
    &__rating.skeleton {
        margin-left: 0 !important;
        height: 10px;
        width: 100%;
    }
    &__delivery.skeleton,
    &__price.skeleton {
        height: 7px !important;
    }
}
