.careers__why {
    width: 88%;
    margin-bottom: 40px;
    //left: 50%;
    //transform: translateX(-50%);
    &__text-container {
        flex-direction: column;
        margin-left: initial;
    }

    &__main-text {
        width: initial;
    }

    &__heading {
        font-size: 24px;
        margin-bottom: 20px;
    }
}
