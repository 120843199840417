.chef-view {
    margin-top: 30px;
    &__image {
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }

    &__name {
        vertical-align: middle;
        font-weight: bold;
        font-size: 14px;
    }

    &__description {
        font-weight: 300;
        line-height: 1.44;
    }

    &__name-container {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 15px;
        font-size: 14px;
    }

    &__description {
        margin-top: 20px;
    }
}
