.rewards {
    &__header {
        background-size: 1370px;
        background-position-x: -30px;
        font-size: 20px;
        padding-top: 45px;
    }

    &__button {
        max-width: 100%;
    }

    &__content {
        width: 100%;
    }

    &__box {
        margin: 0;
        padding: 40px 22px;
        border-bottom: 1px solid $whitelabel-line-separator;
    }

    &__countryCode {
        width: 20%;
    }

    &__phone {
        width: 80%;
    }

    &__share-container {
        > a,
        > button {
            width: 100%;
        }
    }
}
