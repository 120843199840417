.category-picker {
    flex: 1 0 350px;
    text-align: left;
    &__container {
        position: sticky;
    }
    &__header {
        margin-bottom: 25px;
    }
    &__date {
        font-size: 24px;
    }
    &__category {
        text-align: left;
        white-space: nowrap;
        margin-bottom: 25px;

        .active {
            cursor: initial;
            pointer-events: none;
            font-family: $brand-boldest-font;

            .category-picker__category-text {
                padding-bottom: 3px;
                border-bottom: 2px solid;
            }
        }
    }
}
