.shopping-cart {
    &__section-title {
        margin-bottom: 20px;
    }
    &__change-btn-container {
        margin-left: initial !important;
    }
    &__timeframe-container {
        flex-direction: column;
        margin-top: 15px;
        align-items: flex-start;
    }
}
