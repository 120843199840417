.section-food {
    margin-top: 0 !important;
    margin-bottom: 60px;

    &__dish-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 25px;
    }

    &__dish-name {
        position: initial;
        color: $black;
        font-size: 14px;
    }
}
