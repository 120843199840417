.halal-dropdown {
    position: absolute;
    margin-top: 9px;
    z-index: 10;
    width: 100%;
    left: -50%;
    &__backdrop {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }

    &__triangle {
        position: absolute;
        left: 25px;
        z-index: 11;
        top: 3px;

        &:before {
            content: '';
            position: absolute;
            width: 0px;
            height: 0px;
            right: 0px;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 7px solid $whitelabel-black;
        }

        &:after {
            content: '';
            position: absolute;
            width: 0px;
            height: 0px;
            right: 2px;
            top: 2px;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid $whitelabel-black;
            z-index: 3;
        }
    }
}
