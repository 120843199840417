.terminal-confirm-popup {
    width: 700px;
    color: $black;
    font-style: normal;
    font-weight: 600;
    background: linear-gradient(180deg, #ffc800 0%, #ffc907 57.81%, #ffd334 100%);
    padding: 70px 0px;

    &__flex-content {
        display: flex;
        text-align: center;
        align-items: center;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .customer-name {
            font-size: 36px;
            line-height: 43px;
        }

        .pay-text {
            margin-top: 20px;
            font-size: 28px;
            line-height: 32px;
            font-weight: 400;
        }

        .arrow {
            // display: flex;
            // align-items: center;
        }
    }
}
