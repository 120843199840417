.product-list {
    &__date-title {
        padding-top: 8px;
        padding-bottom: 4px;
        width: 100%;
        display: inline-block;
        font-size: small;
        font-weight: normal;
        font-family: $brand-bolder-font;
    }

    &__date-title-wrapper {
        border-top: 1px solid $whitelabel-line-separator;
        border-bottom: 1px solid $whitelabel-line-separator;

        .collapsible__toggle {
            padding-top: 8px;
        }

        &--no-border {
            border-bottom: none;
        }
    }

    &__date-row {
        padding-bottom: 20px;
    }

    &__list-item {
        font-size: 14px;
    }

    &__list-item-price {
        width: 80px;
        display: inline-block;
    }

    .order-summary {
        font-size: 14px;
    }
}
