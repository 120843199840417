.terminal-reset-kiosk {
    display: flex;
    justify-content: center;
    padding: 32px;
    flex-direction: column;
    width: 688px;
    text-align: center;

    &__title {
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 34px;
        text-align: center;
        letter-spacing: -0.527692px;
        margin: 10px 0px;
        color: $whitelabel-black;
    }

    &__timer-text {
        font-size: 24px;
        font-weight: 600;
        color: #f4594f;
    }

    &__inner-text {
        font-family: $brand-primary-font;
        margin: 32px 0px;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        text-align: center;
        color: $whitelabel-black;
    }

    &__CTA-btn {
        margin: 0 0 5px;
        transition: 1s ease;
        height: 60px;
        width: 100%;
        background-color: #fec700;
        color: #000;
        border-radius: 5px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
    }
}
