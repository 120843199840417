.timeslot-button {
    padding: 4px;
    display: inline-block;

    &__content {
        font-size: 13px;
        color: $whitelabel-dark;
        font-family: $brand-bolder-font;
        line-height: 38px;

        &:hover {
            background-color: $whitelabel-primary;
            color: $white;
        }

        &--isSelected {
            background-color: $whitelabel-lightest;
            border-bottom: 5px solid $whitelabel-primary;
            color: $whitelabel-light;

            &:hover {
                background-color: $white;
                border-bottom: 5px solid $whitelabel-primary;
                color: $whitelabel-dark;
            }
        }
    }
}
