.section-dishes {
    padding-top: 100px;
    padding-bottom: 55px;

    &__paragraph {
        font-size: 120%;
        line-height: 120%;
        padding-left: 50px;
        padding-right: 50px;
    }

    &__title {
        width: 515px;
        margin: 0 auto;
    }
}
