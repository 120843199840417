.careers__how-we-hire {
    margin-bottom: 65px;
    &__content {
        width: 87%;
        margin-left: auto;
        margin-right: auto;
        flex-direction: column;
        margin-bottom: 30px;
    }

    &__header {
        font-size: 24px;
        margin-top: initial;
        margin-right: initial;
        margin-bottom: 20px;
        margin-left: initial;
    }

    &__sub-header {
        width: initial;
    }
}
