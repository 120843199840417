.collapsible {
    background-color: $white;

    &__toggle {
        border: none;
        background: none;
    }

    &__collapse {
        width: 1.5em;
        height: 1.5em;
    }

    &__content {
        overflow: hidden;

        &--collapsed {
            max-height: 0px;
        }
    }
}
