@font-face {
    font-family: 'BarlowBold';
    src: url('/fonts/barlow-bold.eot'), url('/fonts/barlow-bold.woff'),
        url('/fonts/barlow-bold.ttf');
}

@font-face {
    font-family: 'BarlowSemiBold';
    src: url('/fonts/barlow-semi-bold.eot'), url('/fonts/barlow-semi-bold.woff'),
        url('/fonts/barlow-semi-bold.ttf');
}

@font-face {
    font-family: 'Barlow', sans-serif;
    @import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&display=swap');
}

@font-face {
    font-family: 'Barlow Semi Condensed', sans-serif;
    src: url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@100;200;300;400;500;600;700;800&display=swap');
}

@font-face {
    font-family: 'Barlow Condensed', sans-serif;
    src: url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@100;300;400;500;600;700;800&display=swap');
}

@font-face {
    font-family: 'Prompt';
    src: url('https://d3tjqo2jh935k9.cloudfront.net/fonts/prompt.ttf');
}

@font-face {
    font-family: 'PromptLight';
    src: url('https://d3tjqo2jh935k9.cloudfront.net/fonts/prompt-light.ttf');
}

@font-face {
    font-family: 'PromptSemiBold';
    src: url('https://d3tjqo2jh935k9.cloudfront.net/fonts/prompt-semi-bold.ttf');
}

@font-face {
    font-family: 'FairplexBold';
    src: url('https://d3tjqo2jh935k9.cloudfront.net/fonts/FairplexNarrowBold.ttf');
}

@font-face {
    font-family: 'FairplexSemiBold';
    src: url('https://d3tjqo2jh935k9.cloudfront.net/fonts/FairplexNarrowMedium.ttf');
}

@font-face {
    font-family: 'FairplexLight';
    src: url('https://d3tjqo2jh935k9.cloudfront.net/fonts/FairplexNarrowBook.ttf');
}

@import 'variables';
@import 'main_base';
@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@100;300;400;500;600;700;800&display=swap');
