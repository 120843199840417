.order-history {
    &__header {
        background-size: 1370px;
        background-position-x: -30px;
    }

    &__content {
        min-height: calc(100vh - 365px);
        padding: 0px;
    }

    &__content-card {
        padding: 0px;

        &-row {
            padding-top: 18px;
            padding-bottom: 18px;
            margin: 0 !important;
            border-bottom: 1px solid $separator-color;
        }

        &-cta {
            width: 100%;
        }
    }

    &__empty-title {
        margin-top: 32px;
    }

    &__empty-subheading {
        width: 90vw;
    }

    &__empty-cta {
        width: 90vw;
    }

    &__table-row {
        padding-top: 18px;
        padding-bottom: 18px;
        margin: 0 !important;
    }
}
