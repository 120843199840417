.timeslot-button {
    height: 45px;
    padding: 2px;
    width: 100%;

    &__content {
        border: 1px solid $whitelabel-lighter;
        color: $black;
        font-family: $brand-primary-font;
        height: 100%;
        width: 100%;
        font-size: 12px;
        line-height: 40px;
        white-space: pre;

        &--isDisabled {
            border-color: lighten($whitelabel-lightest, 1);
            color: $whitelabel-lighter;
            pointer-events: none;
        }

        &:hover {
            background-color: $whitelabel-primary;
            color: $white;
        }

        &--isSelected {
            background-color: $whitelabel-lightest;
            border-bottom: 5px solid $whitelabel-primary;
            color: $whitelabel-light;

            &:hover {
                background-color: $whitelabel-primary;
                color: $white;
            }
        }
    }
}
