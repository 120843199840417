.wallet-benefit {
    padding: 20px;
    background-color: $white;
    color: $whitelabel-light;
    font-size: 15px;
    width: 560px;
    border-radius: 8px;
    transition-timing-function: ease-out;
    transition-duration: 0.15s;
    transform: translateY(0);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    &:hover {
        transition-timing-function: ease-out;
        transition-duration: 0.2s;
        transform: translateY(-5px);
        box-shadow: 0 14px 32px 0 rgba(0, 0, 0, 0.2);
    }

    &--prominent {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    &__info {
        flex: 60% 0 0;
    }

    &__title {
        font-size: 24px;
        font-family: $brand-boldest-font;
        display: flex;
    }

    &__price {
        font-family: $brand-boldest-font;
    }

    &__saving {
        color: $whitelabel-blue;
        font-size: 14px;
    }

    &__descriptions {
        color: black;
        margin-bottom: 14px;
    }

    &__button {
        padding: 15px;
        padding-top: 12px;
        padding-bottom: 12px;
        border-radius: 5px;
        font-size: 15px;
        width: 110px;
        transition-duration: 0.3s;
        text-transform: uppercase;
        background-color: $whitelabel-primary;
        color: $black;
        border: 1px solid $whitelabel-primary;

        &.button--busy {
            &::after {
                background-image: linear-gradient(
                    -45deg,
                    $whitelabel-primary 25%,
                    transparent 25%,
                    transparent 50%,
                    $whitelabel-primary 50%,
                    $whitelabel-primary 75%,
                    transparent 75%,
                    transparent
                );
            }
        }
    }
    &__popular-tag {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        padding: 8px;
        span {
            color: $black;
            font-size: 12px;
            font-family: $brand-bolder-font;
        }
    }
    &__popular-tag.popular {
        background-color: $whitelabel-primary;
    }
    &__popular-icon {
        width: 14px !important;
        height: 14px !important;
        margin-right: 5px;
        fill: $whitelabel-primary;
    }
}
