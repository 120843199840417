@media (max-width: 767px) {
    .business-form {
        max-width: 100%;
        width: 100%;

        &__name-container {
            padding-right: 15px;
            margin-bottom: $medium;
        }

        &__phone-container {
            padding-left: 15px;
        }
    }
}
