.terminal-addon-items {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__message {
        color: $whitelabel-primary;
        text-align: center;
        font-family: $brand-semi-condensed-font;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 32px;
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        align-self: flex-start;
    }
}

.show-message {
    justify-content: center;
}
