.payment-method-picker {
    margin-bottom: 10px;

    > li:last-child {
        border-bottom: 1px solid $whitelabel-line-separator;
    }

    &__card {
        align-items: center;
        border-top: 1px solid $whitelabel-line-separator;
    }

    &__button {
        border: none;
        background: none;
    }

    &__button-icon {
        width: 2em;
        height: 2em;
    }
}
