.business {
    .menu-header {
        position: static;
        margin-top: 0 !important;
    }

    &__title-separator {
        height: 5px;
        width: 50px;
        background-color: $whitelabel-primary;

        &--gray {
            background-color: $whitelabel-dark;
        }
    }

    &__hero-section {
        background: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/business-hero.jpg');
        background-size: cover;
        height: 650px;
        position: relative;
    }

    &__hero-section-overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(
            to right,
            rgba(0, 0, 0, 0.9) 30%,
            rgba(0, 0, 0, 0) 100%
        );
        opacity: 0.5;
    }

    &__form-container {
        text-align: right;
    }

    &__happy-customer-section {
        padding-top: 48px;
        padding-bottom: 48px;
        text-align: center;
        background-color: $whitelabel-lightest;
    }

    &__happy-customer-subheading {
        width: 700px;
        margin-left: auto;
        margin-right: auto;
    }

    &__benefit-section {
        padding-top: 48px;
        padding-bottom: 48px;
    }

    &__benefit-section-container {
        padding-left: 96px;
        padding-right: 96px;
    }
}
