.help-popup {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    text-align: center;

    &--as-modal {
        .help-popup__content {
            bottom: auto;
            position: fixed;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .help-popup__backdrop {
            opacity: 0.2;
            background-color: $black;
        }
    }

    &__content {
        position: absolute;
        bottom: 20px;
        transform: translateX(-46%);
        margin: auto;
        transition: opacity 0.25s;
        z-index: 3;

        &--hide {
            opacity: 0;
            pointer-events: none;
        }

        &--show {
            opacity: 1;
            pointer-events: all;
        }
    }

    &__trigger {
        cursor: pointer;
    }

    &__backdrop {
        position: fixed;
        z-index: 2;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}
