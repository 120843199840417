.rewards-popup {
    height: 100% !important;
    width: 100% !important;
    &__container {
        min-width: initial !important;
        width: initial !important;
        height: initial !important;
    }
    &__header {
        margin-bottom: 10px;
    }
}
