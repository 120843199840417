.medium-item {
    background-color: $whitelabel-pastel-yellow;
    border: 5px solid black;
    position: relative;
    overflow: hidden;
    font-style: normal;
    font-weight: 600;
    font-size: 7px;
    border-radius: 1.25em;
    cursor: pointer;
    color: $charcoal-dark;

    &__ranking_name_container {
        display: flex;
        padding: 1.28em 1.15em;
        align-items: flex-start;
    }
    &__ranking {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &__current_ranking {
        font-size: 4.5em;
        line-height: 102%;
        color: $whitelabel-red;
        font-family: $brand-condensed-font;
    }

    &__ranking_change {
        display: flex;
        align-items: center;
        margin-top: 3.8px;
    }

    &__ranking_change_num {
        font-size: 2.2em;
        line-height: 19px;
        font-family: $brand-semi-condensed-font;
        margin-left: 2px;
    }

    &__title_bold {
        margin-left: 0.5em;
        font-weight: 600;
        font-size: 2.72em;
        line-height: 23px;
        font-family: $brand-semi-condensed-font;
    }

    &__meal_img {
        position: relative;
        z-index: 1;
        margin-top: -19.5em;
        width: 39.68em;
        height: 50em;
        object-fit: cover;
        right: 0px;
    }

    &__price_container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: absolute;
        bottom: 5px;
        z-index: 3;
        padding-left: 1.33em;
    }
    &__price {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        font-family: $brand-semi-condensed-font;
        margin-right: auto;
        margin-left: 2px;
    }
    &__special-price {
        font-style: normal;
        font-weight: 600;
        font-size: 3.5em;
        line-height: 28px;
        font-family: $brand-semi-condensed-font;
    }

    &__currency {
        font-style: normal;
        font-weight: 600;
        font-size: 1.1em;
        margin-right: 2px;
        margin-top: -13px;
    }

    &__separator {
        margin: 0px 4px 0px 4.5px;
        width: 0.9px;
        height: 2.28em;
        background-color: $whitelabel-black;
        opacity: 0.2;
    }

    &__combo_text {
        font-size: 1.42em;
        line-height: 9px;
        font-family: $brand-condensed-font;
    }

    &__combo_price {
        font-size: 1.71em;
        line-height: 14px;
        font-family: $brand-semi-condensed-font;
    }

    &__yellow_pastel_section {
        position: absolute;
        bottom: -15%;
        width: 120%;
        height: 45%;
        background-color: rgba(255, 234, 160, 0.82);

        -webkit-transform: rotate(-16deg); /* Safari and Chrome */
        -moz-transform: rotate(-16deg); /* Firefox */
        -ms-transform: rotate(-16deg); /* IE 9 */
        -o-transform: rotate(-16deg); /* Opera */
        transform: rotate(-16deg);
    }
}
