$gray-section: #edebec;

.section-app {
    background-color: $gray-section;
    display: flex;

    &__content {
        flex: 1;
        padding-left: 30px;
        max-width: 50%;
    }

    &__background-wrapper {
        flex: 1;
    }

    &__background {
        max-width: 620px;
        min-width: 300px;
        width: 100%;
        float: right;
    }

    &__padder {
        max-height: 155px;
        height: 10vw;
    }

    &__small-padder {
        max-height: 64px;
        height: 5vw;
    }
}
