.terminal-lucky-wheel {
    &__lucky_wheel_container {
        text-align: center;
        background: $charcoal-dark;
        height: 100vh;
        padding-top: 42px;
    }

    &__title {
        font-family: Barlow;
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 43px;
        color: $whitelabel-primary;
    }
    &__play_btn {
        background: none;
        font-family: Barlow;
        font-style: normal;
        font-weight: 600;
        font-size: 64px;
        line-height: 77px;
        color: $black;
        padding: 16px 64px;
        background-color: $whitelabel-primary;
        border-radius: 8px;
        width: 468px;
        text-transform: uppercase;
    }

    &__image {
        background: yellowgreen;
        width: 120px;
        height: 120px;
    }

    #spin,
    #reset {
        position: absolute;
        bottom: 20vh;
        right: 45vw;
    }

    &__wheel_container {
        position: relative;
        margin: 16px 0px;
    }
    &__pointer_triangle {
        position: absolute;
        right: 47.8%;
        top: 20px;
        width: 0;
        height: 0;
        border-left: 25px solid transparent;
        border-right: 25px solid transparent;
        border-top: 40px solid #f4594f;
        z-index: 10;
    }

    &__custom_overlapping_wheel {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 505px;
        height: 505px;
    }
    &__center_circle {
        position: absolute;
        top: 242px;
        right: 48.7%;
        width: 20px;
        height: 20px;
        background-color: #f4594f;
        border-radius: 50%;
    }

    &__custom_pie {
        position: absolute;
    }

    &__img {
        width: 140px;
        height: 100%;
        object-fit: cover;
    }
}
