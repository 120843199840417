.wallet {
    &__section {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    &__section-title {
        font-family: $brand-bolder-font;
        font-weight: bold;
        margin-bottom: 30px;
        text-align: center;
    }

    &__section-hero {
        background-size: cover;
        padding-top: 45px;
        padding: 0;
        text-align: center;
        padding: 60px 0;

        &-mobile-gradient {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-content {
            width: 100%;
            height: 100%;
            background-position: right 100px bottom -40px;
            background-repeat: no-repeat;
            background-size: 45vh;
        }

        &-cta {
            margin: 10px auto;
            height: 45px;
            line-height: 45px;
            padding: 0 14px;
            box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
                0 1px 3px rgba(0, 0, 0, 0.08);
            background: $whitelabel-primary;
            color: $black;
            border-radius: 4px;
            font-size: 12pt;
            font-weight: 600;
            letter-spacing: 0.025em;
            transition: all 0.15s ease;
            cursor: pointer;
            width: 250px;

            &:hover {
                // color: $white;
                transform: translateY(-1px);
                box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1),
                    0 3px 6px rgba(0, 0, 0, 0.08);
            }
        }

        &-title {
            font-size: 60px;
            margin-bottom: 12px;
        }

        &-subtitle {
            font-size: 30px;
            margin-bottom: 24px;
        }
    }

    &__section-savings {
        padding-bottom: 0px;

        &-wrapper {
            margin-top: 32px;
            margin-bottom: 64px;
        }
    }

    &__section-savings-icon {
        color: $whitelabel-primary;
        display: inline-block;
        font-size: 100px;
    }

    &__section-packages {
        background-color: #f8f8f8;
    }

    &__section-faq {
        background-color: $whitelabel-lightest;
    }

    &__savings-item {
        padding-left: 50px;
        padding-right: 50px;

        &-title {
            font-size: 32px;
            font-weight: bolder;
            margin-bottom: 16px;
        }
    }

    &__section-video {
        background-size: cover;
        background: linear-gradient(
            to bottom,
            #56abe6 0,
            #5cb8f6 25%,
            #63bdfb 50%,
            #66c0fc 75%,
            #64befa 100%
        );
        // height: 60vh;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding: 100px;
    }

    &__section-testimonials-logos-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        > div {
            width: 250px;
            height: 200px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        :nth-child(1) {
            background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/techcrunch.jpg');
        }
        :nth-child(2) {
            background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/techinasia.jpg');
        }
        :nth-child(3) {
            background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/inccom.jpg');
        }
        :nth-child(4) {
            background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/womenshealth.jpg');
        }
        :nth-child(5) {
            background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/bazaar.jpg');
        }
        :nth-child(6) {
            background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/buro24.jpg');
        }
    }

    &__video {
        max-height: 100%;
        width: 70%;
    }

    &__benefit-wrapper {
        padding: 20px;
        margin: 0 auto;
    }

    &__benefit-wrapper-item {
        &--prominent {
            flex: 1;
        }

        &--non-prominent {
            flex: 1;
            margin-top: 30px;
        }
    }

    &__faq-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
    }

    &__faq-item-wrapper {
        box-sizing: border-box;
        padding: 10px;
        padding-bottom: 0px;
        vertical-align: top;
        background-color: $white;
    }
}
