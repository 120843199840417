* {
    box-sizing: border-box;
}

a {
    color: $black;
}

body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    user-select: initial !important;
}

body,
h1,
h2,
h3,
p,
input {
    font-family: $brand-primary-font;
}

button {
    outline: none;
    cursor: pointer;
    font-family: $brand-bolder-font;
    border-radius: 5px;
    -webkit-tap-highlight-color: transparent;
}

h1 {
    font-size: $large;
}

h2 {
    font-size: $medium;
}

.IIV::-webkit-media-controls-play-button,
video::-webkit-media-controls-start-playback-button {
    opacity: 0;
    pointer-events: none;
    width: 5px;
}

::-webkit-scrollbar {
    width: 0 !important; /* Remove scrollbar space */
    background: transparent !important; /* Optional: just make scrollbar invisible */
}

label {
    line-height: 150%;
    font-size: 14px;
}

strong {
    font-family: $brand-boldest-font;
}

hr {
    border: none;
    border-bottom: 1px solid $whitelabel-line-separator;
}

input {
    border-radius: 5px;
}

input[type='number']:not(.input--show-spinner) {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

#mixpanel-notification-tagline {
    display: none !important;
}

#fc_frame {
    right: initial !important;
    left: 20px !important;
}

@media (max-width: 651px) {
    #fc_frame {
        left: initial !important;
    }
    //body {
    //    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    //    height: calc(var(--vh, 1vh) * 100);
    //}
    // overflow-x: hidden !important;
    #launcher,
    .percept-launcher-button {
        display: none !important;
    }
}
