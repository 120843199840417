.delivery-preference {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    border: 1px solid $whitelabel-lighter;
    display: inline-flex;
    position: relative;
    font-size: 13px;
    line-height: 125%;
    vertical-align: middle;
    margin-left: 8px;
    margin-right: 8px;
    cursor: pointer;

    &__container {
        width: 100%;
    }

    &__label {
        display: flex;
    }

    &__item {
        display: inline-block;
    }

    &--selected {
        background-color: $whitelabel-primary;
        color: $white;
        border-color: $whitelabel-primary;
    }

    &__thumbs {
        background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/most-convenient-no-bg.png');
        background-repeat: no-repeat;
        background-size: 100%;
        width: 14px;
        height: 14px;
        margin-left: 12px;
    }

    &__most-convenient-icon-container {
        margin-bottom: 40px;
    }

    &__most-convenient-icon {
        position: relative;
        top: -2px;
        margin-left: -2px;
        background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/most-convenient.png');
        background-size: 100%;
        width: 20px;
        height: 20px;
    }

    &__learn-more {
        margin-left: 10px;
    }

    &__select-container {
        border-color: rgb(242, 242, 242) !important;
        margin-bottom: 15px;
    }

    &__select__control,
    &__select__option {
        padding: 12px;
        font-size: 16px;
    }

    &__select__option--is-selected {
        background-color: grey !important;
    }

    &__select__option--is-focused {
        background-color: rgb(244, 244, 244);
    }

    &__select__option--is-selected:hover {
        background-color: grey !important;
    }

    &__select__option:hover {
        background-color: rgb(244, 244, 244);
    }

    &__select__indicator-separator {
        display: none;
    }

    &__select__control {
        background-color: rgb(242, 242, 242);
    }

    &__select__single-value {
        line-height: 1.2;
    }

    &__container {
        margin: 20px 0;
    }
}

.css-2o5izwL:hover {
    border-color: rgb(242, 242, 242) !important;
}

.css-2o5izw {
    box-shadow: 0 0 0 1px $black;
}

.css-2o5izwL {
    border-color: rgb(242, 242, 242) !important;
}

.css-bl6clz {
    border-color: rgb(242, 242, 242) !important;
}

.css-19bqh2r {
    fill: $black;
}
