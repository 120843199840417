.terminal-header {
  background-color: #151515;
  color: $white;
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 0px 1.75rem 8px 16px;
  user-select: none !important;
  .hamburger-icon__line {
    border-bottom-color: $black;
  }

  &__add-btn {
    background-color: #fec700;
    border-radius: 4px;
    height: 50px;
    padding: 16px 32px;
    font-size: 18px;
    color: black;
    font-weight: 600;
  }

  &__cutoff-notice {
    font-size: 28px;
  }
  &__delivery-notice {
    font-size: 20px;
  }
  &__hamburger-icon-wrapper {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 24px;
    width: 120px;
    width: 30px;
  }
  &__check-in-button-container {
    margin-right: 24px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
  }
  &__check-in-button {
    background-color: #ff7e81;
    width: 120px;
    &:hover {
      background-color: lighten(#ff7e81, 5%);
    }
  }
  &__logo_start {
    display: flex;
  }
  &__logo {
    background-color: $whitelabel-primary;
    width: 62px;
    height: 58px;
    background-color: $whitelabel-primary;
    border-radius: 0px 0px 14px 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  &__logo_img {
    width: 140%;
    height: 140%;
    object-fit: cover;
  }
  &__start {
    align-self: flex-start;
    margin: 24px 0px 7px 24px;
    background-color: transparent;
    color: $white;
    border: 1px solid white;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 11px;
    padding: 8px;
    font-family: $brand-primary-font;
  }

  &__item_types {
    display: flex;
    justify-content: space-between;
    user-select: none !important;
    width: 94%;
    font-family: 'BarlowSemiBold';
    font-size: 18px;

    .item_type {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      cursor: pointer;
      flex-grow: 1;
      white-space: nowrap;
      height: 100%;
    }

    .item_type_category {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      flex-grow: 20;
      overflow: hidden;
      white-space: nowrap;
      height: 100%;

      // Hide scrollbar
      scrollbar-width: none;
      -ms-overflow-style: none; 

      &::-webkit-scrollbar { 
        display: none;
      }
    }

    .sections {
      display: flex;
      overflow-x: scroll;
      align-items: center;
      white-space: nowrap;
      height: 100%;
    }

    .item_type_category::after {
      content: '';
      position: absolute;
      top: 22.5%;
      height: 50%;
      width: 4px;
      background: #B0B2B3;
      right: 0;
    }
    
    .not-selected {
      color: #b0b2b3;
    }

    .selected {
      color: $white;
      border-bottom: 4px solid #fec700;
    }

    .text-item {
      display: flex;
      align-items: center;
      height: 100%;
      margin-left: 1.5rem;
    }
  }

  &__outlet_name {
    font-family: $brand-primary-font;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 11px;
    margin-top: 32px;
    margin-bottom: auto;
  }
}
