.button {
    min-height: 32px;
    font-size: 13px;

    &--bigger-on-mobile {
        padding-top: 12px;
        padding-bottom: 12px;
        height: auto;
        max-height: inherit;
        font-weight: bold;
        font-size: 16px;
    }
}
