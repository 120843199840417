.date-time-selector {
    margin: 0 25px !important;
    margin-right: 0 !important;
    &__header-container {
        margin-bottom: 15px !important;
    }
    &__header {
        font-size: 16px !important;
    }
    &__cta-container {
        width: initial !important;
        margin-top: 30px !important;

        .login-popup__button-container {
            margin: 0 25px;
            padding-bottom: 23px;
        }
    }
    &__cta {
        margin-bottom: 0;
    }
    &__dates-container {
        overflow: scroll;
        flex-wrap: initial !important;
    }
    &__date-container {
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }
    &__date {
        white-space: nowrap;
    }
    &__times-container {
        max-height: initial;
        overflow: initial;
    }
    &__times-container.overflow {
        max-height: 85vh !important;
    }
    &__time-container {
        margin-right: 25px !important;

        &:last-child {
            margin-bottom: 50px;
        }
    }
}
