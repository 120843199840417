.complete-signup {
    border-radius: 5px;
    &__country-code {
        width: 25%;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    &__phone {
        width: 75%;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    &__save-phone {
        min-height: 35px;
    }
}
