.section-delivery-areas {
    margin-top: 40px;
    font-size: 14px;
    &__header {
        margin-bottom: 25px;
        h3 {
            font-size: 24px !important;
            line-height: 1.21;
        }
    }

    &__area-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 25px;
        margin-bottom: 40px;
    }

    &__cta {
        color: $whitelabel-primary;
    }

    &__city-container.add-margin {
        margin-bottom: 15px;
    }

    &__cta-arrow {
        color: $whitelabel-primary;
        margin-top: auto !important;
        margin-bottom: auto !important;
        margin-left: 6px;
        font-size: 9px;
        stroke-width: 3px;
    }

    &__area-container {
        max-height: 0 !important;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        font-size: 10px;
        p {
            margin-bottom: 2px;
            margin-right: 10px;
        }
    }

    &__area-container.show {
        max-height: 300px !important;
    }
}
