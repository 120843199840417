.rating-widget {
    background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/rating_header_2.png');
    background-size: 100%;
    background-position-y: -16px;
    color: $white;
    padding-top: 14px;
    padding-bottom: 14px;
    line-height: 170%;
    font-size: 18px;
    font-weight: bold;

    &__star {
        width: 24px;
        height: 24px;
        margin-left: 5px;
        margin-right: 5px;
    }

    &__star-container {
        height: 28px;
    }
}
