.section-hero {
    @media (orientation: landscape) {
        height: auto;

        &__logo-text {
            width: 120px;
        }

        &__logo {
            padding-top: 20px;
        }

        &__intro {
            margin-top: 40px;
        }

        &__intro-text {
            font-size: 40px;
        }

        &__subtitle,
        &__mobile-subtitle {
            font-size: 18px;
        }

        &__download {
            margin-top: 30px;
            margin-bottom: 30px;
            position: static;
        }
    }
}
