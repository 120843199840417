$separator-color: lighten($whitelabel-lighter, 10);

.order-history {
    background-color: $whitelabel-lightest;

    .menu-header {
        position: static;
        margin-top: 0 !important;
    }

    &__header {
        height: 135px;
        background-color: $whitelabel-primary;
        color: $whitelabel-secondary;
        text-align: center;
        padding-top: 55px;
        font-size: 30px;
        font-family: $brand-bolder-font;
    }

    &__content {
        min-height: calc(100vh - 270px);
        padding-left: 10vw;
        padding-right: 10vw;
        padding-top: 60px;
        padding-bottom: 60px;
    }

    &__content-card {
        padding-left: 10vw;
        padding-right: 10vw;
        padding-top: 60px;

        &-row {
            padding: 24px;
            padding-left: 32px;
            padding-right: 32px;
            background-color: $white;
        }

        &-cta {
            width: 200px;
        }
    }

    &__loading {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    &__table-container {
        background-color: $white;
    }

    &__table-header {
        padding: 18px 32px 14px 32px;
        font-weight: bold;
        border-top: 1px solid $separator-color;
        border-bottom: 1px solid $separator-color;
        border-radius: 5px;
    }

    &__table-row {
        padding: 24px;
        padding-left: 32px;
        padding-right: 32px;
        border-bottom: 1px solid $separator-color;

        &:hover {
            background-color: lighten($whitelabel-lightest, 3);
        }
    }

    &__empty-title {
        font-size: 28px;
        font-family: $brand-bolder-font;
        margin-bottom: 30px;
    }

    &__empty-subheading {
        width: 500px;
        line-height: 125%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
    }

    &__empty-cta {
        width: 450px;
    }
}
