.login {
    background-color: $whitelabel-lightest;

    .wallet-headerr {
        background-color: transparent;
    }

    .button--busy {
        &::after {
            background-image: linear-gradient(
                -45deg,
                $whitelabel-blue 25%,
                transparent 25%,
                transparent 50%,
                $whitelabel-blue 50%,
                $whitelabel-blue 75%,
                transparent 75%,
                transparent
            );
        }
    }
}
