.terminal-mobile-cart-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 7.5px 0px;

    &__img_title_block {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    &__item_img_container {
        width: 65px;
        height: 65px;
        display: block;
        background: rgba(0, 0, 0, 1);
        border-radius: 4px;

        .image {
            width: 65px;
            height: 65px;
            object-fit: cover;
            display: block;
            opacity: 0.6;
        }
        .item_quantity {
            position: relative;
            bottom: 59%;
            right: -42%;
            font-family: $brand-primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            color: white;
        }
    }

    &__title_edit {
        margin-left: 7px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        place-content: space-between;

        .title {
            font-family: $brand-primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 14.4928px;
            line-height: 17px;
            max-width: 190px;
        }

        .edit_button {
            margin: 0px;
            margin-top: 7px;
            border: none;
            font-family: $brand-primary-font;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 14px;
            color: $whitelabel-gray;
            background-color: transparent;
            padding: 0px;
        }
    }

    &__price {
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: normal;
        font-size: 12.6812px;
        line-height: 14px;
        color: $whitelabel-gray;
    }

    &__edit_modal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 24px 16px;
    }

    &__modal_title {
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 29px;
        color: $whitelabel-black;
    }

    &__meal_inc_dec_num {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 22px 0px;

        .inc_dec_btn {
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px;
            border-radius: 50%;
            background-color: $whitelabel-primary;
            width: 27px;
            height: 27px;
        }

        .n_items {
            font-family: $brand-primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 14.5px;
            line-height: 17px;
            text-align: center;
            color: $whitelabel-black;
            margin: 0px 14px;
        }
    }

    &__remove_modal_title {
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 32px;
        letter-spacing: -0.5px;
        color: $whitelabel-black;
        margin-bottom: 22px;
    }

    &__modal_cta_btn {
        border: none;
        background-color: $whitelabel-primary;
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: $whitelabel-black;
        border-radius: 5px;
        width: 100%;
        padding: 12px;
        height: 40px;
    }

    &__modal_remove_btn {
        border: none;
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: $whitelabel-black;
        margin-top: 24px;
        background-color: transparent;
    }
}
