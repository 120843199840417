.menu-checkout {
    &__container {
        display: flex;
        flex-direction: column;
        padding-top: 16px;
        padding-left: 0px;
        padding-right: 0px;
    }

    &__user-info {
        order: 1;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-bottom: 0 !important;
    }

    &__cart-info {
        order: 0;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    &__button-wrapper {
        margin-top: 0 !important;
        background: initial;
        padding-bottom: 20px;
    }

    &__summary-section {
        background: white;
    }
}
