.cart {
    margin-bottom: 14px;
    margin-top: 14px;

    &__smart-reload-desc {
        margin-bottom: 0px;
    }

    &--empty {
        padding: 0px;
        background-color: transparent;
        width: 100%;
    }
}
