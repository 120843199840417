.address-bar {
    height: 70px;
    padding: 16px 0 15px;
    border-bottom: 1px solid #f2f2f2;
    &__address {
        width: 70%;
    }
    &__address-header {
        font-size: 14px;
        margin-right: auto;
        margin-bottom: 7px;
    }
    &__container {
        width: 100%;
        display: flex;
        align-items: flex-start;
        margin-left: initial !important;
    }
    &__selected-address-text {
        margin-right: auto;
        width: 100%;
    }
    &__account-switcher-container {
        background-color: white;
        width: 100%;
        position: fixed;
        height: 42px;
        z-index: 97;
        display: flex;
        border-bottom: solid 1px #e2e2e2;
    }
    &__change-button {
        border-radius: 5px;
        background-color: #a9a9a9;
        text-align: center;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        span {
            display: block;
            color: white;
            font-size: 14px;
            font-family: $brand-bolder-font;
            padding: 5px 13px;
            margin-bottom: -1px;
        }
    }
}
