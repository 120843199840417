.address-selector-popup {
    height: 100%;
    width: 100%;
    &__main-container {
        padding-left: 25px !important;
        padding-right: 25px !important;
        padding-bottom: 30px !important;
    }
    &__input-box {
        margin-bottom: initial !important;
    }
    &__saved-addresses-container {
        margin-top: 30px !important;
    }
    &__header {
        font-size: 16px;
        margin-bottom: 20px !important;
    }
    &__saved-addresses-header-container {
        margin-bottom: 20px;
    }
    &__saved-addresses-header {
        font-size: 12px;
        font-family: $brand-bolder-font;
    }
    &__saved-address-icon-container {
        margin-right: 12px;
    }
    &__saved-address-location-header {
        margin-bottom: 5px;
    }
    &__saved-address-location-subheader {
        font-size: 14px;
        color: #4a4a4a;
    }
    &__input {
        width: 80%;
        margin-left: initial !important;
    }
}
