$gray: #ababab;

.section__testimonials {
    padding-top: 90px;
    padding-bottom: 60px;
    text-align: center;

    &__nav-btn {
        border: none;
        background: none;
        cursor: pointer;
        outline: none;

        &--prev {
            width: 25px;
            margin-left: -60px;
        }

        &--next {
            width: 25px;
            margin-right: -60px;
        }
    }

    &__content {
        flex: 1;
        overflow: hidden;
    }

    &__title {
        font-weight: bold;
        margin-bottom: 50px;
    }

    &__page {
        background-color: $white;
        display: flex;

        .slider {
            margin: 0 auto;
        }
    }

    .slider {
        margin: 0 auto;
    }

    .slider-frame {
        min-height: 290px;
    }
}

.section__testimonials-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;

    &__leaf {
        margin-bottom: 10px;
        width: 35px;
    }

    &__text {
        padding-bottom: 10px;
        border-bottom: 1px solid $gray;
        flex: 1;
    }

    &__info {
        padding: 8px;
    }

    &__footer {
        display: flex;
        padding-top: 30px;
        margin: 0 auto;
    }

    &__name {
        font-weight: bold;
        font-family: $brand-bolder-font;
    }

    &__date {
        font-size: 80%;
        color: $gray;
    }

    &__avatar {
        &--alberto-ayala,
        &--alvin-george,
        &--ariel-silt,
        &--catherine,
        &--claire-horn,
        &--danielle {
            display: inline-block;
            background: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/testimonials.png')
                no-repeat;
            overflow: hidden;
            text-indent: -9999px;
            text-align: left;
        }

        &--alberto-ayala {
            background-position: -0px -0px;
            width: 43px;
            height: 43px;
        }
        &--alvin-george {
            background-position: -43px -0px;
            width: 43px;
            height: 43px;
        }
        &--ariel-silt {
            background-position: -0px -43px;
            width: 43px;
            height: 43px;
        }
        &--catherine {
            background-position: -43px -43px;
            width: 43px;
            height: 43px;
        }
        &--claire-horn {
            background-position: -0px -86px;
            width: 43px;
            height: 43px;
        }
        &--danielle {
            background-position: -43px -86px;
            width: 43px;
            height: 43px;
        }
    }
}
