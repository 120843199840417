.terminal {
    background-color: #151515;

    &__container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 35px 30px;
    }

    &__date-display {
        font-family: $brand-boldest-font;
        font-size: 26px;
        margin: 30px 0;
        margin-left: 15px !important;
    }

    &__splash-container {
        transition: opacity 0.5s linear;
        opacity: 0;
        pointer-events: none;

        &--show {
            opacity: 1;
            pointer-events: all;
        }
    }

    .modal__content--golden-ratio {
        width: auto;
    }

    &__meal-toggle {
        flex: initial !important;
    }

    &__meal-toggle-btn {
        white-space: nowrap;
        font-size: 21px !important;
        background-color: initial !important;
        color: black !important;
    }

    &__menu-selector-container {
        z-index: 100;
        margin-left: -15px;
        margin-right: -15px;
    }

    &__menu-selector {
        margin-left: auto;
        margin-right: auto;
    }

    &__full-width {
        width: 100vw;
        position: relative;
        margin-left: -50vw;
        height: 100px;
        left: 50%;
        background-color: lighten($whitelabel-lightest, 3);
    }
}
