.password-reset {
    text-align: center;
    min-height: calc(100vh - 71.31px);

    &__container {
        width: 450px;
        padding: 40px;
        padding-left: 60px;
        padding-right: 60px;
        margin: 0 auto;
    }

    &__title {
        font-size: 26px;
        font-weight: bold;
    }

    &__title-separator {
        width: 30px;
        border: 1px solid;
    }

    &__or-separator {
        margin-top: $large;
        margin-bottom: $large;
        width: 100%;

        &-text {
            font-weight: bold;
            position: relative;
            background-color: $white;
            z-index: 1;
            display: inline;
            padding-left: 10px;
            padding-right: 10px;
        }

        &::after {
            content: '';
            display: block;
            margin: auto;
            margin-top: -10px;
            position: relative;
            width: 100%;
            border-bottom: 1px solid $whitelabel-lighter;
        }
    }

    &__back-link {
        position: relative;
        top: -20px;
        left: -40px;
    }
}

@media (max-width: 991px) {
    .password-reset {
        min-height: calc(100vh - 112.63px);
    }
}

@media (max-width: 650px) {
    .password-reset {
        min-height: calc(100vh - 183.31px);
    }
}
