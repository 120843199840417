.section__badges {
    padding: 70px 70px;

    &__title {
        font-size: 32px;
        font-family: $brand-bolder-font;
        margin-bottom: 30px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }

    &__text {
        margin-top: 10px;
        margin-bottom: 30px;
    }
}
