.product-list {
    padding-bottom: 0px;
    margin-bottom: 16px;

    &__date {
        font-family: $brand-bolder-font;
    }

    &__date-title {
        font-size: 14px;
        padding-top: 16px;
        padding-bottom: 13px;
    }

    &__list-item {
        margin-bottom: 12px;
    }
}
