.wallet-footer {
    &__container {
        display: flex;
        flex-direction: column;
        background-color: $whitelabel-lightest;
        padding-bottom: 20px;
    }

    &__apps {
        display: inline-block;
        width: 100%;
        float: none;
        text-align: center;
        order: 2;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    &__logo-wrapper {
        position: static;
        left: 0px;
        transform: none;
        order: 1;
    }

    &__socials {
        float: none;
        display: inline-block;
        width: 100%;
        text-align: center;
        order: 3;
    }
}
