.terminal-snack-dessert-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 24px;
    position: relative;
    overflow: hidden;

    .image {
        width: 170px;
        height: 225px;
        border-radius: 4px;
        object-fit: cover;
    }

    .discount-banner {
        position: absolute;
        background: #f4594f;
        transform: translateX(-20%) translateY(0%) rotate(-38deg);
        left: -20px;
        font-size: 28px;
        color: white;
        font-weight: bold;
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        top: 10px;
    }

    .off-txt {
        font-size: 12px;
        font-weight: bold;
        margin-left: 5px;
        margin-top: 10px;
    }

    .special-price {
        font-size: 22px;
        color: #ff5a5f;
        margin-top: 8px;
    }

    .name {
        margin-top: 8px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        width: 128px;
    }
}
