.terminal-header {
    background-color: $white;
    height: 76px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px 16px;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
        0px 0px 1px rgba(0, 0, 0, 0.04);

    &__logo {
        background-color: $whitelabel-primary;
        width: 55px;
        height: 65px;
        background-color: $whitelabel-primary;
        border-radius: 0px 0px 32px 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    &__logo_img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__outlet_name_cont {
        margin-top: 36px;
    }

    &__outlet_name {
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        color: black;
        text-align: center;
        margin: 0px;
    }

    &__help_btn {
        margin-top: 36px;
        cursor: pointer;
    }

    .help-screen-open {
        visibility: hidden;
    }
}
