.careers__view-jobs {
    height: initial;
    &__container {
        flex-direction: column;
        padding-top: 35px;
        padding-bottom: 45px;
    }
    &__information {
        display: flex;
        flex-direction: column;
        margin-right: initial;
        margin-bottom: 20px;
        text-align: center;
        span {
            margin-bottom: 15px;
        }
    }
}
