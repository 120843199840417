@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loading--circle {
    border: 3px solid #efefef;
    border-radius: 50%;
    border-top: 3px solid #fec700;
    width: 30px;
    height: 30px;
    display: inline-block;
    animation: spin 1s linear infinite;
    text-align: center;
}

.loading--circle.loading--circle--neutral {
    border-top: 3px solid #8b898d;
}

.text--center {
    text-align: center;
}
