.rating-popup {
    width: 100%;
    overflow: hidden;

    &__header {
        background-color: $white;
        color: $black;
        font-size: 26px;
        display: flex;
        flex-flow: column wrap;
        padding-bottom: 10px;

        &-alt {
            order: -1;
            color: #5f5f5f;
            font-size: 12px;
            margin-left: 0;
            margin-bottom: 6px;
        }
    }

    &__body {
        max-height: initial !important;

        &--nps {
            height: auto;
        }

        &-cta {
            position: sticky;
            bottom: 0;
            width: 100%;
            background-color: $white;
        }
    }

    &__close {
        width: 2.2em;
        height: 2.2em;
        top: 14px;
        right: 0px;
    }

    &__cta {
        width: 100%;
    }

    &__rating-pane-container {
        grid-template-columns: 88px auto;
        grid-template-rows: repeat(2, 44px);

        &.thumbs-up {
            display: block;

            .rating-popup__rating-pane-image {
                display: none;
            }

            .rating-popup__rating-pane-title {
                margin-left: 0;
                margin-bottom: 20px;
            }

            .rating-popup__rating-pane-comment {
                margin-left: 0;
            }
        }

        &.thumbs-down {
            display: block;

            .rating-popup__rating-pane-image {
                display: none;
            }

            .rating-popup__rating-pane-title {
                margin-left: 0;
                margin-bottom: 20px;
            }

            .rating-popup__rating-pane-comment {
                margin-left: 0;
            }

            .rating-popup__rating-pane-tag-panel {
                margin-bottom: 20px;
            }
        }

        &.no-image {
            .rating-popup__rating-pane-title {
                grid-area: 1 / 1 / 2 / 2;
                align-self: center;
            }

            .rating-popup__rating-pane-btn-wrapper {
                grid-area: 1 / 2 / 2 / 3;
            }
        }
    }

    &__rating-pane-image {
        grid-area: 1 / 1 / 3 / 2;
    }

    &__rating-pane-title {
        grid-area: 1 / 2 / 2 / 3;
        align-self: start;
    }

    &__rating-pane-btn-wrapper {
        grid-area: 2 / 2 / 3 / 3;
        margin-left: 14px;
        justify-self: start;
    }

    &__rating-pane-button {
        padding: 7px 20px;
    }

    &__rating-pane-icon {
        width: 29px;
        height: 29px;
    }

    &__rating-pane-comment {
        font-size: 16px;
        width: 100%;
    }

    &__rating-pane-tag-container {
        width: 100%;
    }

    &__rating-pane-tag-panel {
        width: 100%;
    }

    &__rating-pane-star {
        margin-left: 10px;
        margin-right: 10px;
    }

    &__feedback-comment {
        width: 100%;
        font-size: 15px;
    }

    &__nps-container {
        margin: 0 auto;
    }
}
