.business-form {
    max-width: 100%;
    width: 100%;

    &__name-container {
        padding-right: 15px;
        margin-bottom: $medium;
    }

    &__phone-container {
        padding-left: 15px;
    }

    &__date-picker-container {
        max-width: 320px;
    }

    &__thank-you {
        padding: 24px 24px;

        &__title {
            font-size: 28px;
            line-height: 125%;
            width: 70%;
            margin: 0 auto;
            margin-top: 35px;
        }

        &__cta {
            width: 100%;
            margin-bottom: 32px;
        }
    }

    .modal__content {
        width: 95%;
        top: 0px;
        transform: translate(-50%, 100px);
    }
}
