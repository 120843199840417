@keyframes wiggle {
    0%,
    100% {
        transform: scale(1);
    }
    50% {
        transform: scale(2);
    }
}

.cart-icon {
    display: inline;
    position: relative;

    &__content {
        position: absolute;
        right: 0px;
        top: -10px;
    }

    &__icon {
        width: 30px;
        height: 25px;
        position: relative;
        top: 5px;
    }

    &__number-wrapper {
        background-color: $whitelabel-primary;
        color: black;
        font-family: $brand-bolder-font;
        height: 20px;
        width: 20px;
        position: relative;
        top: -40px;
        left: 20px;
        border-radius: 50%;
        display: flex;
        animation: wiggle 0.25s 2 ease;

        &.bg-purple {
            background-color: $whitelabel-secondary;
        }
    }

    &__number {
        color: black;
        font-size: small;
        margin: auto;
    }
}

.fill-white {
    fill: white !important;
}
