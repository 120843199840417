.add-payment-method {
    &__list {
        margin-top: 20px;
    }

    &__button {
        width: 100%;
    }

    &__button-text {
        width: 100%;
        color: $whitelabel-light;
        font-size: 115%;
        text-align: left;
        padding: 4px 0;
    }
}
