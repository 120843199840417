.shopping-cart-address {
    padding: 40px;

    &__address-preview {
        padding: 20px 0 !important;
        width: 100% !important;
        margin-left: auto;
        margin-right: auto;
        line-height: normal;
    }

    &__input-area {
        display: flex;
        flex: 1;

        .building-name {
            width: 50%;
        }
    }

    &__input-container {
        margin-right: 40px;
    }

    &__input {
        border-left: none;
        border-right: none;
        border-top: none;
        border-radius: 0 !important;
        padding-left: 0 !important;
    }

    &__input:focus {
        outline-width: 0;
    }

    &__button,
    &__confirm-location-button {
        margin: 40px 30px;
        padding: 10px 26px;
    }

    &__item {
        padding-bottom: 25px;
    }

    &__item-address {
        height: 38px;
    }

    &__radio {
        width: 25px;
        height: 25px;
    }

    &__btn {
        padding-left: 40px;
        padding-right: 40px;
        height: 40px;

        &--cancel-add-address {
            max-height: 30px;
            min-height: auto;
        }
    }

    &__change-btn {
        background-color: transparent;
        color: $whitelabel-secondary;
        padding: 5px 15px;
        &:hover {
            background-color: #effcf4 !important;
        }
    }

    &__change-btn.wallet {
        background-color: #edf9fb;
        color: $whitelabel-blue;
        padding: 5px 15px;
        &:hover {
            background-color: #edf9fb !important;
        }
    }

    &__popup {
        padding: 40px;
        padding-top: 0;
        margin-top: -20px;
        width: 600px !important;

        &--adjust,
        &--note {
            padding: 0px 40px 0px 40px;
            max-width: 600px;
            min-width: 600px;
        }

        &--explaining {
            //padding: 0px 40px 0px 40px;
            max-width: 600px;
            min-width: 600px;
            max-height: 90vh;
            text-align: left !important;
        }
    }

    &__popup-header {
        color: $black;
        display: flex;
        padding: 35px 40px;
        text-align: left;
        width: 85%;

        &__extra-margin {
            padding-top: 35px;
        }

        &__text {
            margin-top: auto;
            margin-bottom: auto;
            font-size: $large;
        }

        &--left {
            background-size: 100%;
            height: 100%;
        }

        &--center {
            line-height: 90px;
        }

        &--right {
            background-size: 100%;
            width: 180px;
            height: 100%;
        }
    }

    &__back-icon {
        width: 0.8em;
        height: 0.8em;
    }

    &__separator {
        border-bottom: 1px solid $whitelabel-line-separator;
        margin: 0px;
        width: calc(100% + 80px);
        margin-left: -40px;
    }

    &__title-container {
        margin-top: 40px !important;
    }

    &__title {
        margin: 20px 0 !important;
    }

    &__popup-content {
        top: 0%;
        transform: translate(-50%, 135px);

        &--PopupHouseExplained,
        &--PopupCondoExplained,
        &--PopupOfficeExplained {
            .modal__close-icon {
                fill: $whitelabel-dark;
            }
        }
    }

    &__popup-footer {
        padding: 40px;
        padding-top: 30px;
    }

    &__map-container {
        position: relative;
    }

    &__map-canvas {
        height: 150px;
        width: calc(100% + 80px);
        transform: translateX(-40px);
        margin-bottom: $medium;
    }

    &__confirm {
        padding: 10px 25px;
    }

    &__pin-icon {
        width: 1.5em;
        height: 1.5em;
        margin-right: 12px;
        margin-left: -4px;
    }

    &__delivery-note {
        resize: none;
        width: 100%;
        border: none;
        border-bottom: 1px solid $whitelabel-lighter;
        font-size: 16px;
    }

    &__delivery-note {
        outline-width: 0;
    }

    &__map-pin {
        background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/map-pin.png');
        background-size: cover;
        height: 40px;
        width: 30px;
        position: absolute;
        z-index: 100;
        left: calc(50% - 15px);
        top: calc(50% - 40px);
    }

    &__map {
        position: relative;
        height: 300px;
        width: 600px;
    }

    &__map--hidden {
        height: 0px;
    }

    &__error-message {
        padding-bottom: 0 !important;
    }
}
