.discount-code-form {
    border-radius: 5px;
    padding: 14px 20px;
    padding-bottom: 13px !important;
    background-color: white;
    &__error {
        margin-top: 5px;
        font-size: 12px;
        font-family: $brand-bolder-font;
    }
    &__cancel-button {
        width: 150px;
        margin-top: 4px;
    }
}
