.section-hero {
    background-color: $whitelabel-primary;

    &__container {
        flex-flow: column nowrap;
        bottom: 0;
        position: absolute;
    }

    &__intro {
        margin-bottom: 10%;

        // &.extra-margin {
        //     margin-bottom: 26px;
        // }
    }

    &__cta-button:hover {
        background-color: lighten($whitelabel-secondary, 7%);
    }

    &__header {
        font-size: 32px !important;
        width: 100%;
    }

    &__cta-button {
        background-color: $whitelabel-secondary;
        color: $white;
        border-radius: 5px;
        padding: 11px 22px;
        font-size: 18px;
        margin-bottom: initial;
        margin-left: initial;
        margin-top: initial;
        height: initial !important;
        width: 100%;
    }

    &__dish-name {
        text-align: left;
    }

    &__dish-details-container {
        margin: 20px 0;
        font-size: 14px;
        width: initial;
        text-align: left;
    }

    &__referral-login-container {
        margin-top: 20px;
    }

    &__referral-input-container {
        &__error {
            margin-top: 15px;
        }
    }

    &__referral-login {
        flex-direction: column;
    }

    &__referral-login-cta {
        margin-top: 15px;
        width: 214px;
    }

    &__referral-login-footer {
        margin-top: 10px;
    }

    &__auto-complete-container {
        height: 44px;
        width: 100%;
        margin-bottom: 8px;
    }

    &__auto-complete-header {
        margin-top: 16px;
    }

    .bg-mobile-pop {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-bottom: 30px;
        margin-bottom: 10px;
        &__hero-img {
            height: 330px;
            width: 275px;
        }
        &__halal-img {
            position: absolute;
            width: 87px;
            height: 38px;
            bottom: 5px;
            left: 0px;
            border-radius: 0px 8px 8px 0px;
        }
    }

    .bg-referral {
        height: 100%;
        background: url(https://d3tjqo2jh935k9.cloudfront.net/images/static/referral-hero-mobile.jpg);
        top: 0;
        background-position: initial;
        background-size: cover !important;
    }

    &__full-overlay {
        position: absolute;
        width: 100%;
        opacity: 0.5;
        background-color: #000000;
        pointer-events: none;
    }

    &__referral-header {
        font-size: 28px !important;
    }

    &__subtitle.referral {
        font-size: 16px;
        line-height: 1.63 !important;
    }

    &__cta-button-container.referral {
        text-align: center;
        margin-top: 30px;
        p {
            font-size: 12px;
            color: #000000;
            opacity: 0.5;
        }
    }

    &__cta-button.referral {
        margin-bottom: 10px;
    }
}

//.section-hero.referral {
//    padding: 120px 0;
//}
