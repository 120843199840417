.wallet-dropdown {
    position: absolute;
    border: 1px solid $whitelabel-lighter;
    border-radius: 5px;
    overflow: hidden;
    background: $white;
    margin-top: 13px;
    z-index: 10;
    width: 100%;
    right: -125px;
    min-width: 150px;
    height: 350px;
    padding: 10px;
    text-align: center;

    &__content {
        background: $whitelabel-secondary; /* fallback for old browsers */
        background: linear-gradient(
            to left top,
            $whitelabel-secondary,
            $whitelabel-primary
        );
        height: 100%;
        color: $white;
        padding: 50px 30px;
        font-family: $brand-bolder-font;
    }

    &__header-alt {
        font-size: 40px;
    }

    &__wrapper {
        position: absolute;
        right: 10px;
        top: 30px;
        min-width: 400px;
    }

    &__cta {
        margin: 0 auto;
        margin-top: 30px;
        height: 45px;
        line-height: 45px;
        padding: 0 14px;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
            0 1px 3px rgba(0, 0, 0, 0.08);
        background: $whitelabel-primary;
        color: $black;
        border-radius: 4px;
        font-size: 12pt;
        font-weight: 600;
        letter-spacing: 0.025em;
        transition: all 0.15s ease;
        cursor: pointer;
        width: 150px;

        &:hover {
            // color: $white;
            transform: translateY(-1px);
            box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1),
                0 3px 6px rgba(0, 0, 0, 0.08);
        }
    }

    &__backdrop {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }

    &__triangle {
        position: absolute;
        right: 0px;
        z-index: 11;
        top: 7px;

        &:before {
            content: '';
            position: absolute;
            width: 0px;
            height: 0px;
            right: 0px;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 7px solid $whitelabel-lighter;
        }

        &:after {
            content: '';
            position: absolute;
            width: 0px;
            height: 0px;
            right: 2px;
            top: 2px;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid $white;
            z-index: 3;
        }
    }
}

@media (max-width: 991px) {
    .wallet-dropdown__wrapper {
        display: none !important;
    }
}
