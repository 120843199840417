.separator {
    display: inline-block;
    background-color: $whitelabel-dark;
    height: 2px;
    width: 30px;

    &--green {
        background-color: $whitelabel-primary;
    }

    &--white {
        background-color: $white;
    }

    &--red {
        background-color: $whitelabel-red;
    }
}
