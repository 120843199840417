.wallet-header {
    background-color: $white;
    border-bottom: 1px solid lighten($whitelabel-lighter, 10%);
    box-shadow: 0px 0px 3px $whitelabel-light;
    padding-bottom: 10px;

    &__back-icon,
    &__next-icon {
        top: -2px;
        width: 1.5em;
        height: 1.5em;
    }

    &__back-text,
    &__next-text {
        display: none;
    }

    &__next {
        right: 15px;
    }
}
