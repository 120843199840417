.reviews-view {
    &__dash {
        margin-top: 19px;
        margin-bottom: 20px;
    }

    &__header {
        margin-bottom: 5px;
    }

    &__date {
        font-size: 12px;
    }

    &__comment {
        margin-top: 10px;
        line-height: 1.44;
        letter-spacing: -0.2px;
    }
}
