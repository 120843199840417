.terminal-cart-summary {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 9;
    background-color: $whitelabel-primary;
    color: $white;
    min-height: 100px;
    transform: translateY(0px);
    transition: transform 1s ease;

    &--hidden {
        transform: translateY(300px);
    }

    &__content {
        min-height: 100px;
        height: 100%;
        padding-top: 6px;
        padding-bottom: 6px;
    }


    &__CTA {
        background: none;
        border: none;
        vertical-align: middle;
        float: right;
    }

   

    &__CTA-text {
        text-align: left;
        line-height: 120%;
        position: relative;
        left: 10px;
    }

    &__item-name {
        width: 160px;
        margin-right: 15px;
        font-size: 18px;
    }

    &__quantity-box {
        width: 28px;
        height: 28px;
        display: flex;
    }

    &__quantity {
        font-size: 18px;
        margin: auto;
    }

    &__increase,
    &__decrease {
        padding: 0px;
        border: none;
        border-radius: 50%;
        width: 28px;
        height: 28px;
        background-color: $white;
    }

    &__increase .iconmoon-icon,
    &__decrease .iconmoon-icon {
        stroke-width: 4px !important;
        stroke: $whitelabel-primary;
    }
}
