.wallet-status-banner {
    padding: 28px 16px 32px 16px;
    align-items: center;

    &__col-balance {
        border: none;
    }

    &__col-wallet-type,
    &__col-balance,
    &__col-smart-reload {
        display: flex;
        flex-direction: column;
        height: 75px;
    }

    &__col-label {
        flex: 1;
    }
}
