.terminal-phone-popup {
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: $brand-primary-font;
    font-style: normal;
    font-weight: 600;
    padding: 32px 24px;

    .number-popup {
        height: 600px;
    }

    .pin-input {
        width: 64px;
        height: 64px;
        margin-right: 10px;
        font-size: 30px !important;
        text-align: center;
        background: #ffffff;
        /* Grey / 40 */

        border: 1.5px solid #dfe0e1;
        border-radius: 4px;
    }

    .pin-input-selected {
        background: #fff4cc;
        /* Yellow / Yellow Primary */

        border: 1.5px solid #fec700;
    }

    .pin-input-error {
        background: rgba(244, 89, 79, 0.2);
        border: 1.5px solid #f4594f;
    }

    &__ordering {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 16px 0px;
    }

    &__container {
        margin-top: 8px;
        padding: 0px 13px;
        width: 90%;
    }

    &__title {
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 36px;
        text-align: center;
        letter-spacing: -0.527692px;
        color: $whitelabel-black;
    }

    &__sub-heading {
        line-height: 125%;
        width: 340px;
        font-size: 20px;
        margin-bottom: 60px;
        color: rgba(0, 0, 0, 0.69);
        font-family: $brand-bolder-font;
    }

    &__coupon-error-sub {
        line-height: 125%;
        text-align: center;
        font-size: 20px;
        margin-top: 60px;
        margin-bottom: 60px;
        color: rgba(0, 0, 0, 0.69);
        font-family: $brand-bolder-font;
    }

    &__input-wrapper {
        width: 100%;
    }

    &__input-name {
        margin: 5px 0px;
        width: 100%;
        height: 64px;
        font-size: 20px !important;
        line-height: 19px;
        padding: 15px;
        border: 1.5px solid #dfe0e1;
    }

    &__input-country-code {
    }

    &__input-number {
        width: 100%;
        border: none;
        border-radius: 0 !important;
        border-left: 1px solid $whitelabel-lighter;
        font-family: $brand-bolder-font;
        border-radius: 0 !important;
    }

    &__num_inp_box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .input-number-error-box {
            width: 80%;
            width: 320px;
            height: 64px;
            border: 1.5px solid #dfe0e1;
            border-radius: 4px;
            display: flex;
            align-items: center;
            margin-top: 5px;
            border-left: 1px solid $whitelabel-lighter;
            font-size: 30px;
            line-height: 19px;
            background: linear-gradient(0deg, rgba(244, 89, 79, 0.2), rgba(244, 89, 79, 0.2));
            border: 1.5px solid #f4594f;
        }

        .input-number-box {
            width: 80%;
            width: 320px;
            height: 64px;
            border: 1.5px solid #dfe0e1;
            border-radius: 5px;
            display: flex;
            align-items: center;
            margin-top: 5px;
            border-left: 1px solid $whitelabel-lighter;
            font-size: 30px;
            line-height: 19px;
        }
    }

    &__number {
        width: 80%;
        padding: 10px 10px 10px 16px;
        font-family: $brand-primary-font;
        border-radius: 4px;
        color: $whitelabel-black;
    }

    &__placeholder {
        color: #c0c1c3 !important;
        font-size: 30px;
        line-height: 19px;
    }

    &__backspace_icon_container {
        height: 100%;
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__backspace_icon {
        height: 45%;
        .hide {
            display: none;
        }
    }

    &__numpad {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin-top: 30px;
        justify-content: center;
        align-items: center;
    }

    &__numpad_key {
        height: 76px;
        width: 76px;
        font-style: normal;
        font-weight: 600;
        font-size: 53px;
        text-align: center;
        color: $whitelabel-gray;
        border-radius: 50%;
        border: 2px solid #c0c1c3;
        margin: 7px 8px;
        cursor: pointer;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;

        &.numpad_clicked_key {
            @keyframes tapstate {
                from {
                    background: #c0c1c3;
                }
                to {
                    background: white;
                }
            }
            animation-name: tapstate;
            animation-duration: 0.5s;
        }
    }

    &__error_message {
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        color: #f4594f;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    &__CTA-container {
        width: 92%;
        padding: 0;
        margin-top: 30px;
    }

    &__CTA {
        margin: 0px 0px 5px;
        transition: 1s ease;
        height: 60px;
        width: 100%;
        background-color: $whitelabel-primary;
        color: $black;
        border-radius: 5px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
    }

    &__skip-button {
        font-size: 20px;
        margin-top: 30px;
        margin-bottom: 5px;
        color: #7056b5;
    }

    .disabled {
        background: rgba(254, 199, 0, 0.25);
        color: rgba(19, 20, 21, 0.25);
    }
}

.name-popup {
    height: auto;
}

.pin-popup {
    height: 765px;
}
