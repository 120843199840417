.shopping-cart-item {
    padding-top: 7.5px;
    padding-bottom: 7.5px;

    &__image {
        min-width: 80px;
        min-height: 80px;
        background-size: 100%;
        background-position: center center;
        border-radius: 5px;
    }

    &__name {
        text-overflow: ellipsis;
        overflow: hidden !important;
        white-space: pre;
    }

    &__up-down-btn {
        background-color: $whitelabel-lighter;
        padding-top: 5px;
        border: none;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        color: $white;
        font-size: large;

        &:hover {
            background-color: $whitelabel-light;
        }
    }

    &__quantity {
        width: 32px;
        display: inline-block;
        padding-top: 3px;
        line-height: 27px;
        text-align: center;
        position: relative;
        border: 1px solid $whitelabel-lighter;
        border-radius: 5px;
        top: -3px;
    }
}
