.menu-cart-confirm {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 50px;
    margin-bottom: 100px;
    line-height: 125%;

    &__header {
        background-color: $whitelabel-primary;
        color: $white;
        padding-top: 40px;
        padding-bottom: 20px;
        text-align: center;
    }

    &__body {
        padding: 16px;
        padding-top: 24px;
        font-size: 15px;
    }

    &__thank-you {
        font-family: $brand-bolder-font;
        font-size: 32px;
    }

    &__order {
        border: 1px solid $whitelabel-lighter;
        padding: 16px;
    }

    &__invoices {
        text-align: right;
    }

    &__invoices-col {
        display: inline-block;
        width: 100px;
    }

    &__heading {
        font-weight: bold;
        margin-top: 12px;
        margin-bottom: 8px;
    }

    &__quantity-col {
        width: 50px;
    }

    &__payment-method {
        text-align: right;
        border-top: 1px solid $whitelabel-lighter;
        border-bottom: 1px solid $whitelabel-lighter;
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 16px 16px 10px 16px;
    }

    &__apps {
        text-align: center;
    }

    &__app-button {
        width: 150px;
    }
}

@media (min-width: 992px) {
    .menu-cart-confirm {
        padding-left: 200px;
        padding-right: 200px;
    }
}

@media (min-width: 1200px) {
    .menu-cart-confirm {
        padding-left: 300px;
        padding-right: 300px;
    }
}
