.terminal-address-form {
    padding: 24px 60px;

    label {
        color: $whitelabel-light;
        font-size: 13px;
        margin-bottom: 4px;
        display: inline-block;
    }

    &__form-title {
        font-size: 18px;
        font-family: $brand-bolder-font;
    }

    &__form-container {
        padding: 30px;
    }

    &__delivery-instruction {
        resize: none;
        font-size: 13px;
        height: 100px;
    }

    &__preview {
        padding: 12px 60px;
        background-color: lighten($whitelabel-lightest, 3);
        border-bottom: 1px solid $whitelabel-line-separator;
    }
}
