.section-corporate {
    height: 500px;
    padding-top: 115px;
    background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/lunchbox.jpg');
    display: flex;
    background-size: cover;
    background-position: center center;

    &__title {
        width: 70%;
    }

    &__background {
        width: 100%;
        position: absolute;
        z-index: 0;
        margin: auto;
    }

    &__btn {
        width: 180px;
        font-family: $brand-bolder-font;
    }
}
