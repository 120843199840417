.terminal-addon-item-combo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px 5px;

    .image {
        width: 139px;
        height: 216px;
        border-radius: 8px;
        object-fit: cover;
    }

    .name {
        margin-top: 10px;
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        max-width: 138px;
    }
}
