@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.menu-item {
    height: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    overflow: hidden;

    &__main-container {
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        grid-gap: 10px 0;
        height: 100%;
        width: 100%;
        z-index: 2;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        overflow: hidden !important;
        transition: 0.5s;
        -webkit-transition: 0.5s;
        -moz-transition: 0.5s;
        -o-transition: 0.5s;
    }

    &__image {
        background-size: 100%;
        padding-top: 100%;
        position: relative;
        background-position: 50% 55%;
        background-repeat: no-repeat;
        width: 100%;
    }

    &__image.skeleton {
        background-color: $skeleton-grey;
    }

    &__image.add-on {
        padding-top: 62.5%;
    }

    &__container {
        width: 100%;
        height: 100%;
        display: flex !important;
        flex-direction: column;
    }

    &__tint {
        display: flex;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 16%;
        background-color: rgba(0, 0, 0, 0.6);
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        .sold-out-text {
            margin: auto;
            color: $white;
            font-size: 12px;
        }
    }

    &__dish-description-container {
        line-height: 1.28;
        font-size: 16px;
    }

    &__name-container.skeleton {
        height: 10px;
        background-color: $skeleton-grey;
        margin-right: 30px;
        border-radius: 1px;
    }

    &__name {
        font-size: 16px;
        line-height: 1.25;
    }

    &__subheading {
        font-size: 12px;
        line-height: 1.25;
    }

    &__link {
        margin: 0 15px;
        margin-bottom: 16px;
        display: block;
    }

    &__link.add-on {
        margin-top: 16px !important;
        margin-bottom: 10px !important;
    }

    &__tag-container {
        position: absolute;
        top: 15px;
        left: 15px;
        margin-right: 15px;
    }

    &__tag {
        border-radius: 4px;
        color: white;
        font-size: 10px;
        padding: 4px 8.5px 3px 8.5px;
        box-sizing: border-box;
        display: inline-block;
    }

    &__button-container {
        display: flex;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 15px;
    }

    &__rating-container {
        display: flex;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    &__rating-container.skeleton {
        display: flex;
        align-items: center;
    }

    &__rating {
        font-family: $brand-primary-font;
        font-size: 12px;

        &:not(:first-child) {
            margin-left: 5px;
        }
    }

    &__rating.skeleton {
        width: 72px;
        display: inline-block;
        height: 5px;
        border-radius: 1px;
        background-color: $skeleton-grey;
    }

    &__new-rating {
        font-size: 10px;
    }

    &__price-container {
        font-family: $brand-bolder-font;
        margin-top: auto;
        margin-bottom: 11px;
        margin-left: 15px;
    }

    &__price,
    &__delivery {
        font-size: 12px;
    }

    &__price {
        white-space: nowrap;
    }

    &__price.skeleton,
    &__delivery.skeleton {
        display: inline-block;
        width: 40%;
        height: 9px;
        border-radius: 1px;
        background-color: $skeleton-grey;
    }

    &__delivery.skeleton {
        margin-right: 5px;
    }

    &__discounted-price {
        color: $whitelabel-red;
    }
}
