.dish {
    &__image-container {
        position: initial !important;
    }
    &__image {
        max-height: 500px;
        margin-bottom: 50px;
        background-position: 0% 80% !important;
        .halal-logo {
            display: none;
        }
    }

    &__date-title {
        position: absolute;
        top: -35px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 15px;
        white-space: nowrap;
    }

    &__title {
        max-width: 100%;
        margin-top: 32px;
    }

    &__content {
        text-align: center;
    }

    &__price {
        margin-top: 24px;
        font-size: 18px;
    }

    &__add-to-cart {
        width: 100%;
        margin-bottom: 32px;
        height: 48px;
    }
}

.dish {
    &--terminal {
        .view-switcher {
            margin: 0 auto;
            display: inline-block;
        }

        .cart-icon {
            display: none;
        }
    }
}
