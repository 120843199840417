.chef {
    height: 100%;
    margin-bottom: initial;
    &__cta {
        margin-top: 20px;
        margin-bottom: 50px;
        width: 193px;
        height: 45px;
    }
}
