.google-reviews {
    &__reviews-container {
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none; // IE 10+
        overflow: -moz-scrollbars-none; // Firefox
        scroll-behavior: smooth !important;
        margin-left: -25px;
        margin-right: -25px;
        margin-bottom: 25px;
    }
    &__overflow-container:last-of-type {
        .google-reviews__review-container {
            margin-right: 25px !important;
        }
    }
    &__overflow-container:first-of-type {
        .google-reviews__review-container {
            margin-left: 25px !important;
        }
    }
    &__review-container {
        width: 319px;
        margin-right: 15px;
    }
}
