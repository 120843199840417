.auth-panel {
    margin: 0px;
    padding: 0px;
    width: 100%;
    margin-top: 0px;
    border: none;

    &__notice-board {
        background-color: $whitelabel-lightest;
        font-size: 0.75em;
        color: $whitelabel-light;
    }

    &__tab-strip {
        padding-top: 24px;
    }

    &__tab-header {
        height: 50px;
    }

    &__errors {
        padding-top: 20px;
        font-size: 0.75em;
        padding-bottom: 20px;
    }

    &__login,
    &__register {
        padding: 16px;
        margin: 0px;
        width: 100%;
        padding-bottom: 16px;

        .input {
            height: 40px;
            margin-bottom: 8px;
        }
    }
}
