.menu-one-day {
    overflow: hidden !important;
    &__item-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (min-width: 651px) and (max-width: 880px) {
    .menu-one-day {
        &__item-container {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
