.terminal-login {
    background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/terminal-login-bg.jpg');
    background-size: cover;
    overflow: hidden;

    &__content {
        padding-top: 100px;
    }

    &__form {
        width: 450px;
        margin-top: 80px;
    }

    &__input {
        border: none;
        opacity: 0.8;
    }

    &__title {
        font-size: 42px;
        font-weight: bold;
        font-family: $brand-bolder-font;
    }

    &__subtitle {
        font-size: 24px;
        margin-top: 16px;
        margin-bottom: 32px;
    }

    &__sub-heading {
        width: 400px;
    }
}
