.promotion-bar {
    width: 100%;
    background-color: $whitelabel-pastel-yellow;
    display: flex;
    height: 42px;
    &__text-container {
        margin: auto;
    }
    &__text-container.variant-four {
        margin-right: 15px;
    }

    &__referral-login-container {
        display: flex;
        height: 40px;
        margin-top: auto;
        margin-bottom: auto;
        margin-right: auto;
    }

    &__referral-login-cta {
        height: 40px !important;
        min-height: initial !important;
        width: 94px;
        margin-left: 10px;
    }

    &__referral-input-container {
        height: 40px;
        width: 270px;
    }
}

.promotion-bar.variant-four {
    height: 80px;
}
