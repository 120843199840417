.section-app {
    display: block;

    &__title {
        margin-bottom: 8px;
    }

    &__sub-heading {
        padding-left: 20px;
        padding-right: 20px;
    }

    &__content {
        padding-top: 75px;
        padding-left: 0px;
        padding-bottom: 50px;
        max-width: 100%;
    }

    &__phone {
        height: 300px;
        margin-bottom: 10px;
    }
}
