.profile {
    &__header {
        font-size: 30px;
        padding-top: 45px;
    }

    &__button {
        max-width: 100%;
    }

    &__content {
        width: 100%;
    }

    &__name-greeting {
        width: 100%;
    }
}
