.ride {
    line-height: 1.3;

    // .circle {
    // 	width: 150%;
    // 	height: 100%;
    // 	background-color: $whitelabel-primary;
    // 	z-index: 100;
    // 	border-radius: 99999px;
    // 	position: relative;
    // }

    h1 {
        //font-weight: bolder;
    }

    .mantis-background {
        background-color: $whitelabel-primary;
    }

    .header-image-container {
        position: relative;
        z-index: -1;
    }

    .right-justified {
        justify-content: flex-end;
    }

    .left-justified {
        justify-content: flex-start;
    }

    .whitesmoke {
        background-color: #f2f2f2;
        // margin: 0 -1000px;
    }

    &__content-ride {
        padding-top: 67px;
        margin-bottom: 90px;
    }

    &__section {
        min-height: 400px;
        // padding: 20px;
    }

    &__footer-section {
        //height: 250px;
    }

    &__ride-hero {
        background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/ride-hero.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
    }

    &__ride-hero2 {
        background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/ride-another-hero.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
    }

    &__ride-fleet {
        max-height: 198px;
    }

    &__ride-fleet2 {
        max-height: 283px;
    }

    &__center-in-div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__center-horizontal {
        display: flex;
        align-items: center;
    }

    &__you-need {
        justify-content: space-around;
        max-width: 800px;
        margin: 0 auto;
    }

    &__you-need-container {
        max-width: 200px;
        margin-bottom: 20px;
    }

    &__you-need-icons {
        max-width: 100px;
    }

    .button-white {
        background-color: white;
        color: $whitelabel-primary;
    }

    .flex-wrap {
        flex-wrap: wrap;
    }

    &__rectangle {
        max-width: 100%;
        width: 320px;
        //height: 70px;
        padding: 15px;
    }

    &__rectangle1 {
        background-color: #8b189b;
    }

    &__rectangle2 {
        background-color: #ffa300;
    }

    &__rectangle3 {
        background-color: $whitelabel-red;
    }

    &__rectangle-icon {
        max-width: 12%;
    }

    &__benefit-text {
        max-width: 88%;
    }

    &__p {
        max-width: 90vw;
    }

    &__textbox {
        max-width: 100vw;
    }

    &__apply {
        min-width: 100px;
        &.large {
            min-height: 40px;
            min-width: 200px;
            font-size: 16px;
        }
    }
}

.rideNavbar {
    padding: 20px;
    padding-bottom: 15px;
    position: fixed;
    left: 0px;
    right: 0px;
    background-color: $white;
    z-index: 6;

    // &__padder {
    //     padding-top: 64px;
    // }

    // &__back-icon {
    //     position: relative;
    //     top: 3px;
    //     transform: rotate(-90deg);
    // }

    // &__hamburger-container {
    //     display: none;
    // }

    &__language {
        position: relative;
        //top: -3px;
    }

    &__apply {
        min-width: 100px;
    }

    &__whatsapp {
        font-size: 15px;
    }
}

@media (min-width: 901px) and (max-width: 991px) {
    .rideNavbar {
        &__desktop-link {
            display: none;
        }

        &__hamburger-container {
            display: block;
        }
    }
}

@media (max-width: 991px) {
    .ride {
        .right-justified {
            justify-content: center;
            padding: 0;
        }
        .left-justified {
            justify-content: center;
            padding: 0;
        }
        &__ride-fleet2 {
            //max-height: 200px;
        }
    }
}

@media (max-width: 720px) {
    .ride {
        .fleet-background {
            display: none !important;
        }
    }
}

@media (max-width: 650px) and (min-width: 300px) {
    .rideNavbar {
        &__right-nav {
            display: none;
        }
    }

    .ride {
        &__textbox-container {
            width: 100%;
        }
    }
}

//     &__whatsapp {
//     	color: $whitelabel-primary;
//     	font-size: 15px;
//     }
// }
