.section-delivery-areas {
    margin-top: 20px;
    margin-bottom: 45px;
    &__area-list {
        grid-template-columns: 1fr 1fr;
    }

    &__area-container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        font-size: 10px;
        p {
            margin-bottom: 2px;
        }
    }

    &__area-container.show {
        max-height: initial !important;
    }
}
