.wallet-status-banner {
    background-color: $whitelabel-primary;
    color: $white;
    padding: 16px 150px;

    &__col-wallet-type {
        text-align: center;
    }

    &__col-balance {
        border-right: 1px solid $white;
        text-align: center;
    }

    &__col-smart-reload {
        text-align: center;
    }
}

.wallet-status-dropdown {
    background-color: $whitelabel-primary;
    width: 100%;
    height: 100%;
    color: $black;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    &__row {
        height: 50px;
        align-items: center;
    }

    &__cta {
        margin: 0 auto;
        height: 30px;
        width: 80%;
        line-height: 30px;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
            0 1px 3px rgba(0, 0, 0, 0.08);
        background: $white;
        color: $black;
        border-radius: 4px;
        font-size: 10pt;
        font-weight: 600;
        letter-spacing: 0.025em;
        transition: all 0.15s ease;
        cursor: pointer;

        &:hover {
            // color: $white;
            transform: translateY(-1px);
            box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1),
                0 3px 6px rgba(0, 0, 0, 0.08);
        }
    }
}
