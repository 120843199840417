.discount-code-form {
    margin-top: 10px;
    margin-bottom: 14px;
    padding-bottom: 14px;

    &__label {
        font-weight: bold;
        margin-bottom: 8px;
    }
}
