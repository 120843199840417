.section__badges {
    padding: 70px 100px;

    &__title {
        font-size: 32px;
        font-family: $brand-bolder-font;
        margin-bottom: 30px;
    }

    &__content {
        display: flex;
        margin: 0 auto;
    }

    &__icon {
        display: inline-block;
        background: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/badges.png')
            no-repeat;
        overflow: hidden;
        text-indent: -9999px;
        text-align: left;

        &--daily {
            background-position: -0px -0px;
            width: 150px;
            height: 150px;
        }
        &--delivered {
            background-position: -0px -150px;
            width: 150px;
            height: 150px;
        }
        &--delivery {
            background-position: -0px -300px;
            width: 150px;
            height: 150px;
        }
    }

    &__text {
        margin-top: 16px;
    }
}
