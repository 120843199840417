.terminal-mobile-order-type-screen {
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    border-radius: 5px;

    @media (max-height: 700px) {
        //styling changes applied for mobile devices having height <700px
        padding-top: 50px;
    }

    &__order_type {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media (max-height: 700px) {
            margin-top: 24px;
        }
    }

    &__order_type_img {
        width: 200px;
        height: 200px;
        @media (max-height: 700px) {
            width: 130px;
            height: 130px;
        }
    }

    &__order_type_name {
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 43px;
        text-align: center;
        margin-top: 16px;
        color: black;
        @media (max-height: 700px) {
            font-size: 22px;
            line-height: 26px;
        }
    }
}
