.terminal-about {
    &__question {
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 26px;
    }

    &__what {
        padding-left: 32px;
        background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/about-hero-mobile.jpg');
        padding-right: 32px;
        padding-top: 45px;
        text-align: center;
        background-position: center;

        &-title {
            width: 100%;
            font-size: 26px;
            margin-top: 32px;
        }

        &-subheading {
            width: 100%;
        }
    }

    &__how {
        padding-left: 24px;
        padding-right: 24px;
        text-align: center;
        padding-top: 45px;

        &-title {
            width: 100%;
            font-size: 20px;
        }

        &-subheading {
            width: 100%;
            margin-bottom: 40px;
        }

        &-step-1,
        &-step-2,
        &-step-3,
        &-step-4 {
            height: 135px;
        }

        &-step {
            margin-bottom: 28px;
        }

        &-step-title {
            text-align: left;
            padding-top: 16px;
        }

        &-step-subheading {
            text-align: left;
            line-height: 150%;
            margin-top: 10px;
        }
    }

    &__why {
        .about__question {
            margin-bottom: 20px;
        }

        &-img {
            background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/girl-eating.jpg');
            height: 365px;
            background-size: cover;
            background-position: center;
        }

        &-content {
            padding-left: 20px;
            padding-right: 20px;
            padding-top: 50px;
            text-align: center;
        }

        &-title {
            margin: 0 auto;
            width: 90%;
            font-size: 20px;
            line-height: 150%;
            font-weight: normal;
        }

        &-subheading {
            width: 100%;
        }
    }

    &__who {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 45px;
        text-align: center;

        &-title {
            width: 100%;
            font-size: 20px;
            font-weight: normal;
            line-height: 150%;
        }

        &-subheading {
            width: 100%;
            padding-left: 16px;
            padding-right: 16px;
            line-height: 160%;
        }

        &-CTA {
            margin-top: 28px;
            width: 100%;
        }
    }

    &__slider {
        &-item {
            background-size: cover;
            background-position: center;
            height: 365px;
        }

        .slider-list {
            height: 365px !important;
        }
    }
}
