.careers__carousel {
    margin-left: initial;
    margin-right: initial;

    &__content-container {
        margin-left: initial;
        margin-right: initial;
    }

    &__overflow-container:first-of-type {
        margin-left: 50px !important;
    }

    &__overflow-container:last-of-type {
        .careers__carousel__content-box {
            margin-right: 50px !important;
        }
    }

    &__content {
        padding: 35px 40px;
        margin-top: 0;
        width: initial !important;
        margin-bottom: auto;
        margin-left: initial;
        margin-right: initial;
    }

    &__overflow-container:last-of-type {
        .careers__carousel__content-box {
            margin-right: 50px;
        }
    }
}
