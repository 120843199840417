.delivery-preference {
    width: calc(100% + 16px);
    height: auto;
    padding: 16px;
    border: none;
    display: block;
    border-radius: 0;
    font-size: 14px;
    left: -8px;

    &__container {
        text-align: left;
        padding-left: 0px;
        padding-right: 0px;
        left: -20px;
        position: relative;
        width: calc(100% + 40px);
    }

    &__item {
        display: block;
        border-bottom: 1px solid $whitelabel-line-separator;

        &:last-of-type {
            border-bottom: none;
        }
    }
}
