.terminal-phone-popup {
    width: 420px;
    max-height: 76vh;

    &__container {
        margin-top: 10px;
    }

    &__title {
        font-size: 24px;
        width: 250px;
        line-height: 125%;
    }

    &__title.ordering {
        margin-top: 16px;
    }

    &__sub-heading {
        line-height: 125%;
        width: 340px;
        font-size: 20px;
        margin-bottom: 60px;
        color: rgba(0, 0, 0, 0.69);
        font-family: $brand-bolder-font;
    }

    &__input-country-code {
        width: 30px;
        border: none;
        padding-left: 0px;
        padding-right: 0px;
        outline: none;
        font-family: $brand-bolder-font;
    }

    &__input-number {
        width: 100%;
        border: none;
        border-left: 1px solid $whitelabel-lighter;
        font-family: $brand-bolder-font;
    }

    &__CTA-container {
        transition: 1s ease;
    }

    &__CTA {
        transition: 0.5s ease;
        // position: fixed;
        bottom: 0;
        left: 0px;
        right: 0px;
        z-index: 100;
        width: 100%;
        height: 45px;
        font-size: 20px;
    }

    &__CTA.ipad {
        bottom: 310px;
    }
}
