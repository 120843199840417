.shopping-cart-meal {
    background-color: $white;
    padding: $large $large $small $large;
    margin-bottom: 12px;
    border-radius: 5px;

    &__container {
        padding: $large 65px;
    }

    &__header-date {
        font-family: $brand-bolder-font;
    }

    &__separator {
        border-color: $whitelabel-line-separator;
        margin: 20px 0;
    }

    &__quantity-separator {
        border-color: $whitelabel-line-separator;
        margin-top: 7.5px;
        margin-bottom: 7.5px;
    }

    &__subtotal-row {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    &__next {
        position: relative;
        height: 45px;
        margin-bottom: 40px;
    }

    &__money-back-button-container {
        margin-left: auto;
        display: flex;
        p {
            font-size: 12px;
            margin-top: auto;
            margin-bottom: auto;
            margin-right: 4px;
        }
    }

    &__money-back-bubble {
        position: absolute;
        border: 1px solid $whitelabel-lighter;
        border-radius: 5px;
        overflow: hidden;
        background-color: $white;
        margin-top: 14px;
        z-index: 10;
        width: 100%;
        right: -10px;
        min-width: 150px;

        &__text {
            padding: 15px;
            font-size: 12px;
        }

        &__triangle {
            position: absolute;
            right: 0px;
            z-index: 11;
            top: 8px;

            &:before {
                content: '';
                position: absolute;
                width: 0px;
                height: 0px;
                right: 0px;
                border-left: 7px solid transparent;
                border-right: 7px solid transparent;
                border-bottom: 7px solid $whitelabel-lighter;
            }

            &:after {
                content: '';
                position: absolute;
                width: 0px;
                height: 0px;
                right: 2px;
                top: 2px;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-bottom: 5px solid $white;
                z-index: 3;
            }
        }
    }
    &__select-upsell {
        border-radius: 5px;
        background-color: #fff3f4;
        padding: 10px;
        align-items: center;
        margin-top: 15px;
    }
    &__select-text {
        font-size: 14px;
    }
    &__select-button-container {
        margin-left: auto;
    }
    &__select-button {
        width: 98px;
        height: 22px;
    }

    &__cutlery-switcher {
        padding: 7.5px 0;

        .cutlery-text {
            color: $whitelabel-secondary;
        }

        .iconmoon-icon {
            position: relative;
            top: 3px;
            left: 4px;
            width: 18px;
            height: 18px;
        }
    }

    &__cutlery-switcher-subheader {
        display: flex;
        align-items: center;
    }

    &__cutlery-switcher-icon {
        margin-right: 5px;
    }

    &__cutlery-bubble {
        position: absolute;
        border: 1px solid $whitelabel-lighter;
        border-radius: 5px;
        background-color: $white;
        margin-top: 10px;
        z-index: 10;
        width: 265px;
        left: 0;
        min-width: 150px;

        &__text {
            padding: 15px;
            font-size: 12px;
        }

        &__triangle {
            position: absolute;
            left: 87px;
            top: -7px;

            &:before {
                content: '';
                position: absolute;
                width: 0px;
                height: 0px;
                right: 0px;
                border-left: 7px solid transparent;
                border-right: 7px solid transparent;
                border-bottom: 7px solid $whitelabel-lighter;
            }

            &:after {
                content: '';
                position: absolute;
                width: 0px;
                height: 0px;
                right: 2px;
                top: 2px;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-bottom: 5px solid $white;
                z-index: 3;
            }
        }
    }
}

@media (min-width: 900px) and (max-width: 991px) {
    .shopping-cart-meal {
        &__products {
            margin-top: 24px;
        }
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .shopping-cart-meal {
        &__container {
            padding-left: 32px;
            padding-right: 32px;
        }
    }
}
