.date-picker {
    &__day-row {
        margin: $small;
        margin-bottom: $small;
    }

    &__year-row,
    &__month-row {
        margin: $small;
        margin-left: $medium;
        margin-right: $medium;
    }

    &__week-row {
        border-top: 1px solid $whitelabel-line-separator;
        border-bottom: 1px solid $whitelabel-line-separator;
        margin-bottom: $medium;
        padding: $small;
    }

    &__year-row {
        font-family: $brand-bolder-font;
        margin-top: $medium;
    }

    &__picker {
        position: absolute;
        background-color: $white;
        width: 100%;
        z-index: 10;
        border: 1px solid $whitelabel-line-separator;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
    }

    &__backdrop {
        z-index: 9;
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
    }

    &__date-cell {
        width: calc(100% / 7);
        display: table;

        &--lastmonth,
        &--nextmonth {
            color: $whitelabel-lighter;
        }

        &--today {
            .date-picker__date-cell-inner {
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    background-color: $whitelabel-lightest;
                    width: 32px;
                    height: 32px;
                    border-radius: 50px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: -1;
                }
            }
        }

        &--selected {
            .date-picker__date-cell-inner {
                position: relative;
                color: $white;

                &:hover {
                    background-color: transparent;

                    &::before {
                        background-color: lighten($whitelabel-primary, 10);
                    }
                }

                &::before {
                    content: '';
                    position: absolute;
                    background-color: $whitelabel-primary;
                    width: 32px;
                    height: 32px;
                    border-radius: 50px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: -1;
                }
            }
        }
    }

    &__date-cell-inner {
        height: 24px;
        display: table-cell;
        vertical-align: middle;

        &:hover {
            color: $whitelabel-primary;
        }
    }
}
