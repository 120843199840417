.terminal-mobile-addon-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 16px 0px 0px;

    .image-title-price {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    &__image {
        width: 72px;
        height: 72px;
        object-fit: cover;
        border-radius: 4px;
    }

    &__name_price {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 8px;

        .name {
            font-family: $brand-primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: $whitelabel-black;
        }

        .price {
            margin-top: 10px;
            font-family: $brand-primary-font;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: $whitelabel-gray;
        }
    }

    &__cart {
        border: none;
        border-radius: 50%;
        background-color: #fdc601;
        width: 38px;
        height: 38px;
        padding: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .item_quanity {
            font-family: $brand-primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
        }
    }
    &__cart_img {
        border-radius: 50%;
        background-color: #fdc601;
        padding: 7px;
        width: 36px;
        height: 36px;
    }
}
