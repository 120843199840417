.select-popup {
    width: 100% !important;
    height: 100% !important;
    max-height: 100% !important;
    top: 0;
    left: 0;
    transform: none;
    overflow-x: hidden;
    overflow-y: auto !important;
    &__container {
        overflow: initial !important;
        max-height: initial !important;
    }
    &__container::-webkit-scrollbar-thumb {
        border-radius: initial !important;
        background-color: initial !important;
        -webkit-box-shadow: initial !important;
    }
    &__container::-webkit-scrollbar {
        -webkit-appearance: initial !important;
        width: initial !important;
    }
    &__body,
    &__payment-container {
        text-align: left !important;
        margin-left: 25px !important;
        margin-right: 25px !important;
    }
    &__select-logo {
        margin-bottom: 30px;
    }
    &__plans-container {
        flex-direction: column;
        margin-top: 20px !important;
        margin-bottom: 15px !important;
    }
    &__plan-container,
    &__plan-container.popular {
        width: 100% !important;
        margin-bottom: 20px;
    }
    &__plan-container.single-plan {
        width: 180px !important;
    }
    &__terms-container {
        margin-left: initial;
        margin-right: initial;
        width: 100%;
    }
    &__terms {
        font-size: 12px;
    }
    &__payment-card-container {
        width: 100% !important;
    }
    &__thank-you-body {
        width: 82% !important;
        margin-bottom: 25px !important;
    }
}

.select-thank-you-popup {
}
