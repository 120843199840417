.input {
    &--bigger-on-mobile {
        height: 40px;
        font-size: 16px;
    }

    &__label {
        font-size: 100%;
    }
}
