.dish-header {
    padding-left: 0px;
    padding-right: 0px;

    &__header-container {
        align-items: center;
    }

    &__back-icon {
        top: 0px;
    }

    &__date-container {
        margin-left: auto;
        margin-right: auto;
    }
}
