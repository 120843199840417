.large-item {
    position: relative;
    overflow: hidden;
    font-size: 5px;
    cursor: pointer;
    color: $charcoal-dark;

    &__top_container {
        padding: 0.31em 1.25em;
        display: flex;
        justify-content: space-between;
    }

    &__best_seller_sticker {
        position: relative;
        z-index: 1;
        width: 17em;
        height: 15.5em;
        top: -0.31em;
    }

    &__ranking {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__current_ranking {
        color: $black;
        font-style: normal;
        font-weight: 600;
        font-size: 8em;
        line-height: 102%;
        font-family: $brand-condensed-font;
    }
    &__ranking_change {
        display: flex;
        align-items: center;
    }

    &__ranking_change_num {
        font-style: normal;
        font-weight: 600;
        font-size: 4em;
        line-height: 24px;
        font-family: $brand-semi-condensed-font;
    }

    &__title_bold {
        position: relative;
        width: 40.62em;
        left: -4.65em;
        top: 0.6em;
        padding: 10px 2px;
        background-color: $whitelabel-red;
        padding-left: 5em;
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 102%;
        color: $white;
        -webkit-transform: rotate(-9deg); /* Safari and Chrome */
        -moz-transform: rotate(-9deg); /* Firefox */
        -ms-transform: rotate(-9deg); /* IE 9 */
        -o-transform: rotate(-9deg); /* Opera */
        transform: rotate(-9deg);
        font-family: $brand-condensed-font;
        max-width: 400px;
    }

    &__meal_img {
        position: relative;
        z-index: 1;
        margin: 5.62em 0px;
        width: 150%;
        height: 105%;
        margin-top: -80%;
        right: 60px;
        object-fit: cover;
        @media (min-width: 2150px) and (min-height: 3840px) {
            margin: -27.62em 0px;
        }
    }

    &__price_container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: absolute;
        bottom: 8px;
        z-index: 3;
        padding-left: 1.33em;
    }
    &__price {
        font-style: normal;
        font-weight: 600;
        font-size: 5em;
        line-height: 22px;
        font-family: $brand-semi-condensed-font;
        margin-right: auto;
        margin-left: 2px;
    }
    &__special-price {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
        color: $whitelabel-red;
        font-family: $brand-semi-condensed-font;
    }

    &__currency {
        font-style: normal;
        font-weight: 600;
        font-size: 1.1em;
        margin-right: 2px;
        margin-top: -13px;
    }

    &__separator {
        margin: 0px 1em;
        width: 1.18px;
        height: 4em;
        background-color: $whitelabel-black;
        opacity: 0.2;
    }

    &__combo_container {
        font-style: normal;
        font-weight: 600;
    }

    &__combo_text {
        font-size: 2.6em;
        line-height: 12px;
        font-weight: 600;
        font-family: $brand-condensed-font;
    }

    &__combo_price {
        font-size: 3.2em;
        line-height: 19px;
        font-family: $brand-semi-condensed-font;
    }

    &__yellow_pastel_section {
        background-color: rgba(255, 234, 160, 0.82);
        width: 134%;
        height: 42%;

        -webkit-transform: rotate(-16deg); /* Safari and Chrome */
        -moz-transform: rotate(-16deg); /* Firefox */
        -ms-transform: rotate(-16deg); /* IE 9 */
        -o-transform: rotate(-16deg); /* Opera */
        transform: rotate(-16deg);
        position: absolute;
        bottom: -4.5em;
        left: -5em;
    }

    &__light_bg {
        background-color: $whitelabel-pastel-yellow;
    }
    &__dark_bg {
        background-color: $whitelabel-primary;
    }
}
