.terminal-lucky-wheel-screen {
    &__content {
        padding: 64px 0px;
        color: $black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $whitelabel-primary;
        height: 100vh;
    }

    &__customer-name {
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 64px;
        line-height: 77px;
        text-transform: uppercase;
        text-align: center;
        color: $charcoal-dark;
    }

    &__addon_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__addon-image {
        // margin-top: 32px;
        position: absolute;
        top: -90px;
        width: 120%;
        height: 150%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    &__addon-name {
        font-family: $brand-primary-font;
        margin-top: 8px;
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 43px;
        color: $charcoal-dark;
    }
    &__download-app {
        margin-top: 32px;
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 32px;
        color: $charcoal-dark;
    }

    &__pay-text {
        font-family: $brand-primary-font;
        margin-top: 8px;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 32px;
        color: $charcoal-dark;
    }

    &__arrow {
        display: flex;
        align-items: center;
        margin-right: 20px;
        height: 250px;
    }
    &__start-new-order {
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 32px;
        color: $charcoal-dark;
        letter-spacing: -0.5px;
        border: 2px solid $charcoal-dark;
        position: absolute;
        bottom: 60px;
        border-radius: 4px;
        padding: 16px;
        background-color: transparent;
        width: 400px;
        margin-top: 32px;
    }
}
