.terminal-menu-meals-selection{
    &__item-1 {
        width: 262px;
        border-right: 1px solid rgba(97, 100, 104, 1);
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow:auto;
        user-select: none !important;
    }

    h1{
        font-size: 40px !important;
        margin-right: 20px;
        margin-top: 10px;
        user-select: none !important;
    }

    .error-tag {
        font-size: 18px;
        color: #F4594F;
        font-weight: 600;
        background-color: #FFE1DF;
        padding: 10px;
        position: relative;
        margin-top: 10px;
        gap: 10px;
        display: flex;
        align-items: center;
        user-select: none !important;
    }

    .white-btn{
        background-color: white;
        color: black;
        font-weight: bold;
        border-radius: 50%;
        width: 43px;
        height: 43px;
    }

    .box {
        animation: wobble 1s ease forwards;
    }

    @keyframes wobble {
        0%, 100% {
            -webkit-transform: translateX(0%);
            transform: translateX(0%);
            -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
        }
    
        15% {
            -webkit-transform: translateX(-32px) rotate(0deg);
            transform: translateX(-32px) rotate(-0deg);
        }
    
        30% {
            -webkit-transform: translateX(calc(32px / 2)) rotate(0deg);
            transform: translateX(calc(32px / 2)) rotate(0deg);
        }
    
        45% {
            -webkit-transform: translateX(calc(-32px / 2)) rotate(calc(-0deg / 1.8));
            transform: translateX(calc(-32px / 2)) rotate(calc(-0deg / 1.8));
        }
    
        60% {
            -webkit-transform: translateX(calc(32px / 3.3)) rotate(calc(0deg / 3));
            transform: translateX(calc(32px / 3.3)) rotate(calc(0deg / 3));
        }
    
        75% {
            -webkit-transform: translateX(calc(-32px / 5.5)) rotate(calc(-0deg / 5));
            transform: translateX(calc(-32px / 5.5)) rotate(calc(-0deg / 5));
        }
    }


    .error-tag:before {
      content: " ";
      border: 3px solid #F4594F;
      position: absolute;
      height: calc(100% - 6px);
      left: 0;
      top: 0;

    }


    &__card-container{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 0.3em;
        padding: 0px 20px;
        margin-bottom: 40px;
        user-select: none !important;
    }


    &__sticky-header{
        position: sticky;
        top:0;
        z-index: 4;
        background-color:#151515;
        flex-wrap: wrap;
        padding: 24px 25px;

    }

    &__card-price{
    font-weight: 600;
    font-size: 18px;
    color: #616468;
    font-family: Barlow Semi Condensed;
    }

    &__card-img{
        width: 155px;
        height: 155px;
        border-radius: 4px 4px 0px 0px;
        object-fit: cover;
       

    }


    &__card-details-content{
        padding: 1.33em 2em 0px;
        display: flex;
        flex-direction: column;
        min-height: 76px;
        height: 100%;

       
    }

    &__card-details-name{
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
    max-width: 100%;
    font-family: Barlow Semi Condensed;
    }

    &__yellow-container{
        width: 100%;
        height: 100%;
        left:0;
        position: absolute;
        border: 5px solid #fec700;
        border-radius: 3px 3px 0px 0px;
        z-index: 2;
        top:0
    }




   &__add-cart-container{
    position: absolute;
    bottom:0;
    height: 56px;
    background-color:#fec700;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 3;
    width: 100%;
  }

  &__minus-btn, &__plus-btn{
    width: 40px;
    height: 40px;
  
  }

  &__btn-txt{
    width: 20px;
    height: 2.4px;
    color: white;
    font-weight: bold;
  }

  &__qty-number{
    //styleName: H2;
font-family: Barlow;
font-size: 24px;
font-weight: 600;
line-height: 29px;
letter-spacing: 0em;
text-align: left;

  }

  .hide-btn{
    display: none;
  }

  .yellow-display .hide-btn{
    display: block;
  }


    &__card-special-price{
    font-weight: 600;
    font-size: 20px;
    font-family: Barlow Semi Condensed;
    color: #ff5a5f;
    }

    &__card-details-price-container{
        margin-top: auto;
        margin-bottom: 5px;
        height: 24px;
        display: flex;
        align-items: center;
    }



    &__card-details{
        width: 155px;
        display: flex;
        flex-direction: column;
        margin: 5px;
        color: #131415;
        font-family: Barlow Semi Condensed;
        position: relative;
        font-style: normal;
        border-radius: 4px;
        font-size: 6px;
        cursor: pointer;
        background: #f4f4f4;


    }

    .cross-img{
        background-image: url(/dismiss-x.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50%;
        background-color: rgb(48, 50, 52);
    }

    &__menu-details{
        width: 100%;
        align-items: center;
        flex: 0 0 85%;
        border-bottom: 1px solid #616468;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    &__cta-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding:20px 20px 30px 20px;
    }


    &__cta-cart{
        width: 236px;
        height: 50px;
        background-color: #fec700;
        color: #000;
        border-radius: 5px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        margin-top: 24px;
    }

    &__cta-cart-back{
        color: rgba(244, 89, 79, 1);
        cursor: pointer;
        width: 236px;
        text-align: center;
        font-weight: 600;
        font-size: 18px;
    }

    &__item-2{
        width: 70%;
        height: 100vh;
        overflow:auto;
        padding-bottom: 120px;
        scroll-behavior: smooth;
    }

    &__title-container{
        position: relative;
        margin: 0px 0px 0px 22px;
        width: 240px;
        flex: 0 0 39%;
        overflow-y: scroll;
    }

    .progress-line{
        content: ' ';
        position: absolute;
        left: 15px;
        height: calc(100% + 10px);
        top: 25px;
        // border-right: 5px solid var(--scrollbar-background);;
        z-index: -1;
    }

    &__title-sub-container{
        padding-bottom: 20px;
        --scrollbar-background: rgba(97, 100, 104, 1);
        position: relative;
    }

    &__icon-title{
        font-size: 18px;
        font-weight: 600;
        margin-left: 10px;
        color: rgba(160, 162, 164, 1);

    }

    &__circle-icon{
        width: 35px;
        height: 35px;
        border: 5px solid rgba(160, 162, 164, 1);
        border-radius: 50%;
        background: #2b2b2b;
        position: relative;
    }

    &__meal-card{
        width: 236px;
        height: 138px;
        border-radius: 4px;
    }
}