.business-form {
    display: inline-block;
    text-align: center;
    background-color: $white;
    max-width: 480px;
    padding: 48px 64px;

    &__title {
        font-size: 24px;
        font-family: $brand-bolder-font;
    }

    &__subheading {
        width: 75%;
        margin-left: auto;
        margin-right: auto;
    }

    &__thank-you {
        padding: 42px 72px;

        &__title {
            margin-top: 24px;
            font-size: 22px;
            font-family: $brand-bolder-font;
        }

        &__separator {
            height: 2px;
            width: 30px;
            background-color: $whitelabel-primary;
            display: inline-block;
            margin: 0 auto;
        }

        &__cta {
            width: 280px;
        }
    }

    &__date-picker-container {
        min-width: 300px;
    }

    .modal__content {
        top: 0px;
        transform: translate(-50%, 120px);
    }
}

@media (max-width: 991px) {
    .business-form {
        padding-left: 32px;
        padding-right: 32px;
    }
}
