.view-switcher {
    &__container {
        display: flex;
        align-items: baseline;
    }

    &__dash-non-selected {
        margin-top: 2px !important;
        margin-right: -32px !important;
        margin-bottom: 0;
    }

    &__dash-non-selected.last {
        margin-right: 0 !important;
    }

    &__dash {
        margin-top: -1px;
        margin-right: -32px !important;
        margin-bottom: 0;
    }

    &__dash.last {
        margin-right: 0 !important;
    }

    &-header {
        font-family: $brand-bolder-font;
        font-weight: normal;
        padding-bottom: 6px;
        border-bottom: 3px transparent;
        cursor: pointer;
        display: inline-block;
        text-align: center;
        line-height: 1.44;
        letter-spacing: -0.2px;

        &--active {
            color: $whitelabel-secondary;
            border-bottom: 3px solid !important;
        }
        &--active-wallet {
            color: $whitelabel-blue;
            border-bottom: 3px solid !important;
        }
        &--active-terminal {
            color: $whitelabel-red;
            border-bottom: 3px solid !important;
        }
    }
}
