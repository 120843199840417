.business {
    &__hero-section {
        height: auto;
        padding-bottom: 24px;
    }

    &__form-container {
        margin-top: 24px;
        text-align: center;
    }
}
