.landing {
    overflow-x: hidden;

    &__title {
        font-size: 20px;
        padding-top: 42px;
        font-family: $brand-primary-font;
    }

    &__text-primary {
        font-size: 28px;
        line-height: 34px;
        width: 220px;
        margin: 0 auto;
    }

    &__section-title {
        font-size: 21px;
        line-height: 26px;
    }

    &__paragraph {
        font-size: 15px;
    }
}
