.addon-upsell {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &__expand-icon {
        transform: translateX(-10px);

        &--flip {
            transform: translateX(-10px) rotate(-180deg);
        }
    }

    &__column {
        flex: 0 0 28%;
        min-width: 250px;
        margin-right: auto;

        &-on-dish-page {
            flex: 0 0 45%;
        }

        &-1 {
            // for now also only take up half the space, stretching doesn't look good
            flex: 0 0 45%;
        }

        &-2 {
            flex: 0 0 45%;
        }
    }

    &__item {
        padding-top: 8px;
        padding-bottom: 8px;
        justify-content: space-between;

        &-image {
            width: 120px;
            height: 120px;
            background-color: $black;
            background-size: 100%;
            border-radius: 5px;
        }

        &__name-and-icon-wrapper {
            text-align: right;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: space-between;
        }

        &-name {
            overflow: hidden !important;
            margin-bottom: 2px;
            word-break: normal;

            &--full {
                max-height: 100%;
            }
        }

        &-btn {
            border-radius: 10px;
            padding: 1px 8px 1px 8px;
            width: 100%;
            min-height: 10px;
            max-height: 18px;
            font-size: 12px;
        }

        &-btn-content {
            &--full {
                display: block;
            }

            &--short {
                display: none;
            }
        }
    }
}

@media (max-width: 1199px) {
    .addon-upsell__item {
        &-btn-content {
            &--full {
                display: none;
            }

            &--short {
                display: block;
            }
        }
    }
}

@media (max-width: 900px) {
    .addon-upsell__column {
        flex: 0 0 100%;
    }
}
