.addon-panel {
    margin-top: 40px;

    &__title-container {
        font-size: 24px;
    }

    &__small-list {
        margin-bottom: 25px;
        .column-container {
            display: flex;
            overflow-x: scroll;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: none !important; // IE 10+
            overflow: -moz-scrollbars-none !important; // Firefox
            margin: 0 -25px;
        }
        .column-container::-webkit-scrollbar {
            display: none !important;
        }
    }

    &__big-list {
        margin-bottom: 25px;
        .item-container {
            display: flex !important;
            overflow-x: scroll !important;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: none !important; // IE 10+
            overflow: -moz-scrollbars-none !important; // Firefox
            justify-content: flex-start !important;
            margin: 0 -25px;
        }
        .item-container::-webkit-scrollbar {
            display: none !important;
        }

        .column-container {
            .column {
                margin-top: 30px;
            }
        }
    }

    &__date {
        font-size: 24px;
    }

    &__cta {
        font-size: 14px;
    }
}
