.order-status-popup {
    width: 552px;
    text-align: left;
    line-height: 125%;

    &__close {
        fill: $white;
        position: relative;
        width: 1.2em;
        height: 1.2em;
        top: -4px;
        right: -8px;

        &--black {
            fill: $black;
        }
    }

    &__header {
        background-color: $whitelabel-primary;
        color: $whitelabel-secondary;
        padding: 42px;
        font-size: 24px;

        &--inverse {
            padding-top: 30px;
            background-color: $white;
            color: $black;
        }
    }

    &__body {
        padding: 18px 36px 18px 36px;
        max-height: calc(100vh - 150px);

        &--with-rating {
            max-height: calc(100vh - 230px);
        }
    }

    &__meals {
        border: 2px solid lighten($whitelabel-dark, 30);
        margin-left: -16px;
        width: calc(100% + 32px);
    }

    &__item-line-quantity {
        width: 50px;
    }

    &__final-line {
        display: inline-block;
        width: 120px;
    }

    &__sep-line {
        margin-left: -36px;
        width: calc(100% + 72px);
        border: 1px solid lighten($whitelabel-dark, 30);
        //margin-bottom: 18px;
    }

    &__cutoff-text {
        font-size: 11px;
    }

    &__cancel-popup {
        width: 276px;
        line-height: 125%;
    }
}
