.add-credit-card {
    &__form {
        &--busy {
            display: none;
        }

        .display--flex {
            justify-content: space-between;
        }
    }
}
