.text {
    &--white {
        color: $white;
    }
    &--light {
        color: $whitelabel-lighter;
    }
    &--gray {
        color: $whitelabel-light;
    }
    &--dark {
        color: $whitelabel-dark;
    }
    &--black {
        color: $black;
    }
    &--blue {
        color: $whitelabel-blue;
    }
    &--primary {
        color: $whitelabel-primary;
    }
    &--secondary {
        color: $whitelabel-secondary;
    }
    &--red {
        color: $whitelabel-red;
    }
    &--orange {
        color: $whitelabel-orange;
    }

    &--sofiapro {
        font-family: $brand-bolder-font;
    }
    &--thin {
        font-family: $brand-primary-font;
    }
    &--bold {
        font-weight: bold;
    }
    &--bolder {
        font-family: $brand-boldest-font;
    }

    &--right {
        text-align: right;
    }
    &--center {
        text-align: center;
    }
    &--left {
        text-align: left;
    }
    &--middle {
        vertical-align: middle;
    }

    &--capitalize {
        text-transform: capitalize;
    }
    &--uppercase {
        text-transform: uppercase;
    }
    &--lowercase {
        text-transform: lowercase;
    }
    &--underlined {
        text-decoration: none;
        padding-bottom: 2px;
        border-bottom: 1px solid;
    }
    &--no-underlined {
        text-decoration: none;
    }
}

.border {
    &--rounded {
        border-radius: 5px;
    }
    &--rounded-top {
        border-radius: 5px 5px 0 0;
    }
    &--rounded-bottom {
        border-radius: 0 0 5px 5px;
    }
    &--rounded-left {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }
    &--rounded-right {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
    }
    &--non-rounded {
        border-radius: 0 !important;
    }
    &--solid {
        border: solid 1px #d1d1d1;
    }
    &--none {
        border: none;
    }
}

.margin {
    &--right {
        margin-right: auto !important;
    }
    &--left {
        margin-left: auto !important;
    }
    &--top {
        margin-top: auto !important;
    }
    &--bottom {
        margin-bottom: auto !important;
    }
}

.center {
    &--vertical {
        margin-top: auto;
        margin-bottom: auto;
    }
    &--horizontal {
        margin-left: auto;
        margin-right: auto;
    }
}

.fill {
    &--white {
        fill: $white;
    }
    &--gray {
        fill: $whitelabel-lighter;
    }
}

.opacity {
    &--half {
        opacity: 0.5;
    }
}

.bg {
    &--white {
        background-color: $white;
    }
    &--black {
        background-color: $black;
    }
    &--none {
        background: none !important;
    }
}

.lh {
    &--125 {
        line-height: 125%;
    }
    &--150 {
        line-height: 150%;
    }
    &--200 {
        line-height: 200%;
    }
}

.fs {
    &--smallest {
        font-size: $small * 1.4;
    }
    &--small {
        font-size: $small * 1.6;
    }
    &--small-medium {
        font-size: $small * 1.75;
    }
    &--medium {
        font-size: $medium;
    }
    &--medium-medium-large {
        font-size: $medium * 1.25;
    }
    &--medium-large {
        font-size: $medium * 1.5;
    }
    &--large {
        font-size: $large;
    }
    &--largest {
        font-size: $largest;
    }
}

.display {
    &--inline-block {
        display: inline-block;
    }
    &--flex {
        display: flex;

        &.container:before,
        &.container:after,
        &.row:before,
        &.row:after {
            content: normal;
        }
    }
    &--inline-flex {
        display: inline-flex;
    }
    &--table {
        display: table;
    }
    &--table-cell {
        display: table-cell;
    }
    &--none-if-empty {
        &:empty {
            display: none;
        }
    }
}

.justify {
    &--start {
        justify-content: flex-start;
    }
    &--end {
        justify-content: flex-end;
    }
    &--center {
        justify-content: center;
    }
    &--space-around {
        justify-content: space-around;
    }
    &--space-between {
        justify-content: space-between;
    }
    &--space-evenly {
        justify-content: space-evenly;
    }
}

.position {
    &--relative {
        position: relative;
    }
    &--absolute {
        position: absolute;
    }
    &--initial {
        position: initial !important;
    }
}

.flex {
    &-1 {
        flex: 1 0 auto;
    }
    &-1-only {
        flex: 1;
    }
    &-shrink-1 {
        flex-shrink: 1;
    }
    &-none {
        flex: none;
    }
    &-align-items-center {
        align-items: center;
    }
    &-wrap {
        flex-wrap: wrap;
    }
    &-column {
        flex-direction: column;
    }
    &-align {
        align-content: space-between;
    }
}

.float {
    &-right {
        float: right;
    }
    &-left {
        float: left;
    }
}

.hide {
    display: none;
}
.show {
    display: block;
}

.max-width-100 {
    max-width: 100%;
}
.width {
    &--150 {
        width: 150%;
    }
    &--100 {
        width: 100%;
    }
    &--75 {
        width: 75%;
    }
    &--70 {
        width: 70%;
    }
    &--60 {
        width: 60%;
    }
    &--50 {
        width: 50%;
    }
    &--40 {
        width: 40%;
    }
    &--33 {
        width: 33%;
    }
    &--30 {
        width: 30%;
    }
    &--25 {
        width: 25%;
    }

    &--100px {
        width: 100px;
    }
    &--200px {
        width: 200px;
    }
}

.height {
    &--100 {
        height: 100%;
    }
    &--0 {
        height: 0 !important;
    }
}

.white-space {
    &--pre {
        white-space: pre;
    }
    &--nowrap {
        white-space: nowrap;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.link {
    &--no-underline {
        text-decoration: none;
    }
}

.iconmoon-icon {
    width: 1em;
    height: 1em;
    stroke: currentColor;
    fill: currentColor;
    display: inline-block;
    stroke-width: 0;

    &--middle {
        position: relative;
        top: 2px;
    }

    &--12 {
        width: 1.2em;
        height: 1.2em;
    }

    &--white {
        fill: $white;
    }
}

.clearfix {
    &::after {
        clear: both;
        display: table;
        content: '';
    }

    &::before {
        display: table;
        content: '';
    }
}

.dmk-link {
    text-decoration: none;
    color: $whitelabel-blue;

    &--primary {
        text-decoration: none;
        color: $whitelabel-primary;
    }

    &--primary-hover {
        text-decoration: none;
        font-weight: normal;
        color: inherit;

        &:hover {
            color: $whitelabel-primary !important;
        }
    }

    &--secondary {
        text-decoration: none;
        color: $whitelabel-secondary;
    }

    &--secondary-hover {
        text-decoration: none;
        font-weight: normal;
        color: inherit;

        &:hover {
            color: $whitelabel-secondary !important;
        }
    }

    &--wallet-hover {
        text-decoration: none;
        font-weight: normal;
        color: inherit;

        &:hover {
            color: $whitelabel-blue;
        }
    }
}

.z-index-higher-than-backdrop {
    z-index: 10;
    position: relative;
}

.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
}

.no-overflow {
    overflow: hidden;
}

.no-overflow-mobile {
    overflow: hidden !important;
    position: fixed;
    width: 100vw;
}

.no-overflow-y {
    overflow-y: hidden;
}

.no-overflow-x {
    overflow-x: hidden;
}

.blinking {
    animation: blinker 1.5s linear infinite;
}

.list {
    &--none {
        list-style-type: none;
    }
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

@media (min-width: 300px) and (max-width: 650px) {
    .hide--if-small {
        display: none !important;
    }
    .show--if-small {
        display: block !important;
    }
    .m0--if-small {
        margin: 0px 0px 0px 0px !important;
    }
    .p0--if-small {
        padding: 0px 0px 0px 0px !important;
    }
    .text-center--if-small {
        text-align: center;
    }
    .width--100-if-small {
        width: 100%;
    }
    .dmk-link {
        &--primary-hover {
            &:hover {
                color: initial !important;
            }
        }
    }
}

@media (min-width: 651px) {
    .hide--if-large {
        display: none !important;
    }
    .show--if-large {
        display: block !important;
    }
}
