.select-popup {
    width: 635px;
    max-height: initial !important;
    overflow: initial !important;
    &__container {
        max-height: 87vh !important;
        overflow: auto !important;
    }
    &__container::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
    &__container::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px !important;
    }
    &__header-container {
        height: 165px;
        background: url(https://d3tjqo2jh935k9.cloudfront.net/images/static/pop-select-header-bg.png);
        background-position: 50% 50%;
        display: flex;
        justify-content: center;
    }
    &__select-logo {
        width: 160px;
        height: 70px;
        margin-top: auto;
        margin-bottom: 25px;
    }
    &__body {
        margin-left: 50px;
        margin-right: 50px;
        text-align: center;
    }
    &__body-header-container {
        margin: 25px 0;
    }
    &__body-header {
        font-size: 20px;
        margin-bottom: 5px;
    }
    &__body-subheader {
        line-height: 1.44;
    }
    &__points-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__point {
        width: 280px;
        display: flex;
        margin-bottom: 15px;
        align-items: center;
    }
    &__point-text-container {
        display: flex;
        flex-direction: column;
        text-align: left;
    }
    &__point-text {
        line-height: 1.19;
    }
    &__point-subtext {
        font-size: 12px;
        margin-top: 6px;
        line-height: 1.17;
    }
    &__check-mark {
        margin-right: 10px;
        width: 30px;
        height: 30px;
    }
    &__plans-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 30px 0;
        margin-bottom: 10px !important;
    }
    &__plan-container {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        border-radius: 5px;
        border: solid 1px $whitelabel-lightest;
        width: 160px;
        height: 98px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
    }
    &__plan-container.single-plan {
        margin-left: initial !important;
    }
    &__plan-container.selected {
        border: solid 2px $whitelabel-red !important;
        background-color: rgba(255, 90, 95, 0.07);
        padding: 0 !important;
    }
    &__plan-container.popular {
        width: 185px;
    }
    &__plan-body {
        margin-top: 13px;
        padding: 1px;
    }
    &__plan-body.selected {
        padding: 0px !important;
    }
    &__plan-body.popular {
        margin-top: 1px !important;
    }
    &__plan-header {
        font-size: 24px;
        margin-bottom: 1px;
    }
    &__plan-subheader {
        font-size: 12px;
        margin-bottom: 1px;
    }
    &__plan-savings {
        font-size: 12px;
        color: $whitelabel-red;
        font-family: $brand-bolder-font;
        line-height: 1.17;
    }
    &__plan-footer {
        background-color: $whitelabel-lightest;
        margin-top: auto;
        padding: 6px 0;
    }
    &__plan-footer.selected {
        background-color: $whitelabel-red;
        padding: 5px 0 !important;
        p {
            color: white;
        }
    }
    &__price {
        font-size: 12px;
        font-family: $brand-bolder-font;
        margin-bottom: -1px;
    }
    &__popular-indicator {
        display: flex;
        margin-top: -12px;
        margin-bottom: 6px;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        align-items: center;
        height: 19px;
        border-radius: 4px;
        background-color: $whitelabel-red;
        padding-left: 5px;
        padding-right: 5px;
    }
    &__popular-indicator.selected {
        margin-top: -13px !important;
        margin-bottom: 7px;
    }
    &__popular-text {
        font-size: 11px;
        font-family: $brand-bolder-font;
        color: white;
        margin-bottom: -2px;
    }
    &__star-icon {
        width: 10px;
        height: 10px;
        margin-right: 3px;
    }
    &__total-price {
        margin-bottom: 20px;
        span {
            font-size: 10px;
        }
    }
    &__cta-container {
        margin-bottom: 20px;
    }
    &__terms-container {
        width: 70%;
        margin-bottom: 22px !important;
        margin-left: auto;
        margin-right: auto;
    }
    &__terms {
        font-size: 10px;
        line-height: 1.3;
    }
    &__payment-container {
        margin: 25px 45px;
        margin-top: 84px;
    }
    &__payment-header {
        font-size: 20px;
        margin-bottom: 5px;
    }
    &__payment-subheader {
        line-height: 1.44;
    }
    &__payment-header-container {
        margin-bottom: 20px;
    }
    &__payment-plan-container {
        margin-bottom: 30px;
    }
    &__payment-form {
        margin-bottom: 35px;
    }
    &__payment-form-title {
        font-size: 14px;
        margin-bottom: 10px;
    }
    &__payment-card-container {
        width: 67%;
        margin-bottom: 25px;
    }
    &__payment-expiry-container {
        margin-right: 10px;
    }
    &__payment-expiry-container,
    &__payment-cvv-container {
        width: 99px;
    }
    &__payment-footer {
        display: flex;
        text-align: center;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    }
    &__payment-price {
        font-size: 10px;
    }
    &__input {
        border-radius: 5px;
        border: solid 2px $whitelabel-red;
    }
    &__thank-you-header-container {
        height: 165px;
        background: url(https://d3tjqo2jh935k9.cloudfront.net/images/static/pop-select-header-bg.png);
        background-position: 50%, 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__select-logo-thank-you {
        width: 114px;
        height: 50px;
    }
    &__thank-you-body {
        margin-top: 15px;
        margin-bottom: 35px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 52%;
    }
    &__thank-you-body-header {
        font-size: 20px;
        margin-bottom: 15px;
    }
    &__thank-you-body-subheader {
        margin-bottom: 30px;
    }
    &__thank-you-cta-container {
        width: 100%;
    }
    &__error-message {
        margin-top: 10px;
    }
}

.StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 15px 18px;
    font-size: 1em;
    font-family: $brand-primary-font !important;
    border-radius: 5px;
    border: solid 1px #00000033;
    outline: 0;
    background: white;
}

.InputContainer,
.InputElement {
    font-family: $brand-primary-font !important;
}

.StripeElement--focus {
    border-radius: 5px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    border: solid 2px $whitelabel-red;
    padding: 14px 17px;
}

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
    padding: 0;
}

#placeholder {
    font-family: $brand-primary-font !important;
}
