.menu-checkout {
    @media (min-width: 651px) and (max-width: 767px) {
        &__container {
            display: flex;
            flex-direction: column;
            padding-top: 16px;
        }

        &__summary-section {
            display: block;
            margin-bottom: 24px;
        }

        &__user-info {
            order: 1;
        }

        &__cart-info {
            order: 0;
        }
    }
}
