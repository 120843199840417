.place-autocomplete {
    width: 100%;
    &__prediction-wrapper {
        background-color: $white;
        position: relative;
        text-align: left;
    }

    &__input-container {
        width: 100%;
        margin: 0;
        border-bottom: 1px solid $whitelabel-lighter;
        margin-left: auto;
        margin-right: auto;
    }

    &__input-container.border-bottom-none {
        border-bottom: initial !important;
    }

    &__border {
        background: white;
        width: 100%;
        display: flex;
        border-radius: 5px;
        border: 1px solid $whitelabel-lighter;
        padding: 1px;
    }

    &__border.focus {
        border: 2px solid $whitelabel-primary;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        padding: 0px !important;
    }
    &__border.focus.wallet {
        border: 2px solid $whitelabel-blue !important;
    }

    &__input-container.focus {
        border-bottom: initial !important;
    }

    &__search-icon {
        fill: $whitelabel-light;
        margin-bottom: -2px;
    }

    &__search-icon.large {
        margin-bottom: -2px;
    }

    &__search-icon.black {
        fill: black !important;
    }

    &__input {
        width: 100%;
        height: 40px;
        outline: none;
        border: none;
    }

    &__input.large {
        height: 50px;
        font-size: 16px;
    }

    &__input.black {
        font-family: $brand-bolder-font;
        color: black;
    }

    &__input:focus {
        outline: none !important;
    }

    &__prediction {
        line-height: 40px;
        font-size: 14px;
        color: $whitelabel-light;
        display: flex;
        align-items: center;

        &--hover {
            &:hover {
                background-color: $whitelabel-primary;
                color: $white;
            }
        }

        &--selected {
            background-color: $whitelabel-primary;
            color: $white;
        }
    }

    &__prediction-icon {
        position: relative;
        width: 1.2em;
        height: 1.2em;
        top: -2px;
        margin-right: 10px;
    }

    &__busy {
        width: 16px !important;
        height: 16px !important;
        margin-bottom: -2px;
    }

    &__busy.large {
        margin-bottom: -2px;
    }

    &__delivery-area-link {
        text-decoration: none;
    }
    &__change-button-container {
        margin-left: auto;
        display: flex;
        align-items: center;
        color: #4a4a4a;
        margin-bottom: -1px;
        span {
            font-size: 14px;
        }
    }
    &__arrow-icon {
        width: 10px;
        margin-left: 5px;
    }
    &__inline-error {
        font-size: 12px;
        color: $whitelabel-red;
        text-align: right;
        margin-right: 20px;
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;
        width: 100%;
        font-family: $brand-bolder-font;
    }
    &__check {
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 20px;
        width: 20px;
        height: 11px;
    }
}

.place-autocomplete.absolute {
    position: absolute !important;
}
