.slider-banner {
    margin-bottom: 5%;

    .slider-frame {
        border-radius: 10px;
    }

    .slider-list,
    .slider-slide {
        width: 100% !important;
    }

    .slider-control-centerleft {
        transform: translate(-50%, -50%) !important;
    }

    .slider-control-centerright {
        transform: translate(50%, -50%) !important;
    }

    &__slide-link {
        display: block;
    }

    &__slide-image {
        width: 100%;
        height: auto;
        border-radius: 10px;
    }

    &__nav-btn {
        width: 35px;
        height: 35px;
        border: 0;
        border-radius: 50%;
        background-color: white;
        box-shadow: 0 2px 4px 0 rgba(201, 201, 201, 0.5);
        padding: 0;
        display: flex;
        align-items: center;
    }

    &__nav-btn--prev,
    &__nav-btn--next {
        height: 15px;
        stroke-width: 3px;
        width: 100%;
    }
}
