.section-stats {
    &__icon {
        filter: brightness(0) invert(1);
    }

    &__icon.referral {
        filter: initial !important;
    }

    &__container {
        flex-direction: column !important;
    }

    &__container.referral {
        flex-direction: column !important;
        height: 368px;
        justify-content: space-evenly;
    }

    &__stat-container.referral {
        margin-top: initial;
        margin-bottom: initial;
        margin-left: auto;
        width: 80%;
    }

    &__hide {
        display: none;
    }

    &__stat-header {
        line-height: 1.19;
        margin-bottom: 0;
    }

    &__stat-subheader {
        line-height: 1.19;
    }

    &__stat-subheader {
        width: 80%;
    }

    &__mobile-container {
        opacity: 1;
        transition: 0.5s;
        -webkit-transition: 0.5s;
        -moz-transition: 0.5s;
        -o-transition: 0.5s;
        min-height: 55px;
        margin: 25px 0 !important;
    }
    &__referral-green-box {
        position: absolute;
        top: 0;
        height: 70px;
        left: -100px;
        background-color: #effcf4 !important;
        width: 130vw !important;
        z-index: -1;
    }
}

.section-stats.referral {
    margin-left: 25px;
    margin-right: 25px;
    height: 100%;
    margin-top: 0 !important;
    padding-bottom: 5px;
    position: relative;
}

.faded {
    opacity: 0 !important;
    transition: 0.5s !important;
    -webkit-transition: 0.5s !important;
    -moz-transition: 0.5s !important;
    -o-transition: 0.5s !important;
}
