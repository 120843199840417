.section__testimonials {
    &__content {
        height: auto;
    }

    &__page {
        display: block;
        padding-left: 20px;
        padding-right: 20px;
    }

    &__title {
        margin-bottom: 20px;
    }

    .slider-list {
        min-height: 550px !important;
    }
}

.section__testimonials-item {
    &__footer {
        padding-top: 10px;
    }
}
