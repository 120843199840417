$backdrop-color: rgba(0, 0, 0, 0.5);

.modal {
    &--open {
        height: 100vh;
    }

    &__close {
        right: 18px !important;
        top: 25px;
    }

    &__back {
        left: 25px;
        top: 38px;
        position: absolute;
    }

    &__logo {
        top: 41px;
        left: 25px;
        position: absolute;
    }

    &__backdrop {
        position: initial;
        background-color: $backdrop-color;
        width: 100%;
        height: 100%;
        z-index: 0;
    }

    &__backdrop.show {
        position: fixed;
    }

    &__extra-margin {
        right: 18px !important;
        top: 38px !important;
    }

    &__content {
        width: 90%;
        transform: translate(-50%, -50%);
        max-height: 100vh;
        border-radius: 0 !important;
        zoom: initial !important;

        &--golden-ratio {
            top: 0px;
            transform: translate(-50%, 60px);
        }

        &--extra-margin {
            top: 20px !important;
        }

        &--full-mobile {
            width: 100%;
            height: 100%;
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch !important;
        }
    }
}

.modal-ios-fix {
    position: fixed;
    width: 100%;
}

@media (min-width: 501px) {
    .modal {
        &__backdrop {
            position: fixed;
            z-index: 0;
        }
    }
}

// // fix for iOS webkit bug of displaced cursor
// @supports (-webkit-overflow-scrolling: touch) {
//     /* CSS specific to iOS devices */

// }
