.wallet-footer {
    background-color: $black;
    padding: 20px;
    color: $white;
    position: relative;

    &__apps {
        float: left;
    }

    &__app-button {
        width: 120px;
    }

    &__logo-wrapper {
        text-align: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 15px;
    }

    &__logo {
        width: 100px;
    }

    @media screen and (max-width: 1399px) {
        &__socials {
            text-align: center;
        }
    }

    &__social-icon {
        width: 1.5em;
        height: 1.5em;
        color: $white;
    }
}
