.announcement {
    position: relative;
    margin-bottom: 5%;

    &__link {
        display: block;
    }

    &__image {
        width: 100%;
    }

    &__close {
        fill: $white;
        width: 2em;
        height: 2em;
        top: 20px;
        right: 20px;
        padding: 3px;
        cursor: pointer;
        position: absolute;
    }
    .select {
        border-radius: 10px;
    }
}
