.terminal-deal-combo {
	&__card {
		margin-right: 15px;
		margin-top: 10px;
		height: 280px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		border-radius: 4px;
	}

	&__main-wrapper {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
	}

	&__title-header {
		color: #fec700;
		font-size: 36px;
		font-weight: 600;
		margin-bottom: 20px;
	}

	&__center-icon {
		position: absolute;
		/* display: flex; */
		/* justify-content: center; */
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		/* width: 100%; */
		z-index: 2;
	}

	&__center-digital-image {
		background-position: center bottom 0px;
		height: 100%;
		background-repeat: no-repeat;
		border-radius: 4px;
		background-size: cover;
	}

	&__center-digital-main-image {
		background-position: center bottom 50px;
		height: 125%;
		background-repeat: no-repeat;

		background-size: cover;
	}

	&__card-error {
		border: 5px solid #f4594f;
	}

	&__card-wrapper {
		margin-right: 10px;
	}

	&__card-txt {
		color: white;
		font-weight: 600;
		font-size: 18px;
		width: 134px;
		text-align: center;
		padding: 10px 0px;
	}

	&__card-selection {
		width: 134px;
		height: 177px;
		background: #fff4cc;
		position: relative;
		border-radius: 4px;
	}
}
