.wallet-ads {
    text-align: center;
    background-color: $white;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 32px;

    &__main-text {
        font-size: 20px;
        font-family: $brand-bolder-font;
        margin-top: 24px;
        margin-bottom: 16px;
    }

    &__sub-heading {
        margin-bottom: 32px;
    }
}
