.promotion {
    &__container {
        padding: 30px 0;
        width: 85%;
        margin-left: auto;
        margin-right: auto;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 30px;
    }

    &__promotion-tiles {
        width: 100%;
        height: initial;
        margin-left: auto;
        margin-right: auto;
        .info-content {
            flex-direction: column;
            padding: 30px 40px;
        }
        .info-icon {
            margin-bottom: 15px;
        }
    }
}
