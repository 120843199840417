.careers__section-hero {
    overflow: hidden;
    margin-bottom: initial;
    height: initial;

    &__img {
        background: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/careers-hero-mobile.jpg');
        background-size: cover;
        height: 477px !important;
        top: -92px;
        width: 477px;
        z-index: -1;
    }

    &__text-container {
        margin-top: 220px;
        width: 92%;
        position: initial;
        margin-left: auto;
        margin-right: auto;
        height: initial;
        margin-bottom: 45px;
    }

    &__img {
        height: 400px;
    }

    &__text {
        margin: initial;
        padding: 30px 40px;
    }

    &__heading {
        font-size: 28px;
    }
}
