.addon-item {
    width: 100%;
    position: relative;

    &__image {
        background-size: 100%;
        width: 100%;
        height: 100%;
    }

    &__main-container {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
        z-index: 2;
        border-radius: 5px;
        overflow: hidden !important;
        //-webkit-backface-visibility: hidden;
        //-moz-backface-visibility: hidden;
        //-webkit-transform: translate3d(0, 0, 0);
        //-moz-transform: translate3d(0, 0, 0);
        height: 100%;
    }

    &__addon-description-container {
        line-height: 1.28;
        font-size: 16px;
    }

    &__container {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1.9fr 0.1fr;
    }

    &__name,
    &__subheading {
        text-align: left;
    }

    &__padding {
        padding: 15px;
    }

    &__link {
        width: 100%;
        display: block;
    }

    &__button-container {
        position: absolute;
        bottom: 15px;
        right: 15px;
    }
}

.big-list {
    padding-bottom: 5px !important;
}
