.menu-header {
    padding: 14px 0;
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0;
    background-color: $white;
    color: black;
    border-bottom: 1px solid #f2f2f2;
    font-size: 14px;
    width: 100% !important;
    transition: 0.15s;
    z-index: 98;

    &__text-white {
        color: white !important;
    }

    &__desktop-link {
        font-family: $brand-boldest-font;
        line-height: initial !important;
    }

    &__padder {
        padding-top: 64px;
    }

    &__back-icon {
        position: relative;
        top: 3px;
        transform: rotate(-90deg);
    }

    &__hamburger-container {
        display: none;
    }

    &__onboarding-logo {
        display: flex;
    }

    &__logo {
        position: relative;
        margin-right: auto;
    }

    &__whatsapp-order-button {
        display: none;
    }
}

.menu-header.landing-header {
    background-color: initial !important;
    position: absolute;
    box-shadow: initial;
    transition: 0.15s;
    border-bottom: 0;
}

.wallet-banner {
    &__container {
        margin: -21px -20px 21px -20px;
        height: 120px;

        background: #15a3b3; /* fallback for old browsers */
        background: linear-gradient(to right, #18d665, #15a3b3);
    }

    &__content {
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }

    &__button {
        background-color: red;
        height: 45px;
        line-height: 45px;
        padding: 0 14px;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
            0 1px 3px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        font-size: 12pt;
        font-weight: 600;
        letter-spacing: 0.025em;
        transition: all 0.15s ease;
        cursor: pointer;
        min-width: 150px;

        &:hover {
            transform: translateY(-1px);
            box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1),
                0 3px 6px rgba(0, 0, 0, 0.08);
        }
    }
}

.menu-header.polpa-header,
.menu-header.polpa-header-onboarding {
    padding: 15px 0 !important;
}

@media (min-width: 901px) and (max-width: 991px) {
    .menu-header {
        &__desktop-link {
            display: none;
        }

        &__hamburger-container {
            display: block;
        }

        &__right-container {
            flex: initial;
        }
    }
}
