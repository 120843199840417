.terminal-coupon-code {
    display: flex;
    justify-content: center;
    margin-top: 90px;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    max-width: 443px;
    text-align: center;
    &__coupon-header {
        font-size: 36px;
        font-weight: 600;
        color: white;
    }
    &__coupon-input-txt {
        border: 1.5px solid #dfe0e1;
        background-color: white;
        width: 100%;
        font-size: 30px !important;
        font-weight: 600;
        border-radius: 4px;
        line-height: 36px;
        padding: 14px 23.5px;
    }

    ::placeholder {
        font-family: Barlow;
        font-size: 30px !important;
        font-style: normal;
        color: #c0c1c3;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: 0px;
        text-align: left;
    }

    &__coupon-input-txt-disabled {
        border: 1.5px solid #dfe0e1;
        background: linear-gradient(
                0deg,
                rgba(244, 89, 79, 0.2),
                rgba(244, 89, 79, 0.2)
            ),
            #ffffff;
        width: 100%;
        font-size: 30px !important;
        border-radius: 4px;
        color: #f4594f;
        border: 2px solid #f4594f;
        font-weight: 600;
        line-height: 36px;
        padding: 14px 23.5px;
    }

    &__coupon-button-disabled {
        opacity: 0.25;
        background: #fec700;
        border-radius: 4px;
        color: rgba(0, 0, 0, 0.25);
        font-size: 24px !important;
        font-weight: 600;
        padding: 9px 124px;
        height: 66px;
        width: 100%;
    }

    &__coupon-button {
        background: #fec700;
        border-radius: 4px;
        border: none;
        color: black;
        font-size: 24px;
        font-weight: 600;
        padding: 9px 124px;
        height: 66px;
        width: 100%;
    }
}
