.list-payment-methods {
    &__cancel {
        width: 100px;
    }

    &__add {
        width: 230px;
        height: 40px;
        max-height: 40px;
        color: $white;
        &.small-font {
            font-size: 11px;
        }
    }

    &__cancel {
        color: $white;
        height: 30px;
        max-height: 30px;
        position: relative;
        top: 5px;
    }
}
