.menu-checkout {
    &__container {
        padding: 32px 65px;
    }

    &__user-info {
        padding-right: 6px;
        padding-bottom: 65px;
    }

    &__cart-info {
        padding-left: 6px;
    }
}
