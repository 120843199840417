.address-selector-popup {
    width: 555px;
    &__main-container {
        padding: 80px 40px;
        padding-bottom: 23px;
    }
    &__header {
        line-height: 1.21;
        font-size: 24px;
        margin-bottom: 25px;
    }
    &__saved-addresses-header-container {
        margin-bottom: 20px;
    }
    &__saved-addresses-header {
        font-family: $brand-bolder-font;
        font-size: 12px;
    }
    &__saved-address-location-header {
        margin-bottom: 5px;
        line-height: 1.44;
    }
    &__saved-address-location-subheader {
        font-size: 14px;
        color: #4a4a4a;
        line-height: 1.29;
    }
    &__saved-address-icon-container {
        margin-right: 12px;
    }
    &__pin-icon {
        margin-top: 2px;
        stroke-width: 1px;
    }
    &__pin-icon.house,
    &__pin-icon.office {
        stroke-width: 0 !important;
    }
    &__input-box {
        margin-bottom: 25px;
    }
    &__footer {
        border-top: solid 1px #e2e2e2;
    }
    &__cta-container {
        padding: 23px 40px;
    }
}

.address-selector-popup.fixed {
    overflow: initial !important;
}
