.profile-payment-method {
    &__item {
        &__card-type {
            margin-left: 10px;
        }

        &__delete-btn {
            visibility: visible;
        }

        &__card-info {
            white-space: normal;
            width: 0px;
            line-height: 125%;
            word-break: break-all;
        }

        &__delete-btn {
            margin-left: 16px;
        }
    }
}
