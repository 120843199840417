.small-item {
    background-color: $whitelabel-pastel-yellow;
    position: relative;
    overflow: hidden;
    font-size: 6px;
    cursor: pointer;
    color: $charcoal-dark;

    &__meal_img {
        position: absolute;
        object-fit: cover;
        height: 30.5em;
        margin-top: -17em;
        width: 35em;
        right: -6.5em;
    }

    &__ranking_name_container {
        display: flex;
        padding: 1.28em 1.16em 0.5em;
        align-items: start;
    }

    &__ranking {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__current_ranking {
        font-style: normal;
        font-weight: 600;
        font-size: 5.3em;
        line-height: 102%;
        color: $whitelabel-red;
        font-family: $brand-condensed-font;
    }

    &__ranking_change {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2.5px;
        min-width: 20px;
        min-height: 18px;
    }

    &__ranking_change_num {
        font-style: normal;
        font-weight: 600;
        font-size: 2.5em;
        line-height: 18px;
        font-family: $brand-semi-condensed-font;
        margin-left: 3.5px;
    }

    &__title_bold {
        margin-left: 13px;
        font-style: normal;
        font-weight: 600;
        font-size: 3.16em;
        line-height: 23px;
        position: relative;
        z-index: 2;
        font-family: $brand-semi-condensed-font;
    }

    &__price_img_container {
        display: flex;
        position: relative;
        z-index: 1;
    }
    &__price_container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: absolute;
        bottom: 5px;
        z-index: 3;
        padding-left: 1.33em;
    }
    &__price {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        font-family: $brand-semi-condensed-font;
        margin-right: auto;
        margin-left: 2px;
    }
    &__special-price {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 22px;
        color: $whitelabel-red;
        font-family: $brand-semi-condensed-font;
    }

    &__currency {
        font-style: normal;
        font-weight: 600;
        font-size: 1.1em;
        margin-right: 2px;
        margin-top: -13px;
    }

    &__separator {
        margin: 0px 0.6em 0px 0.83em;
        width: 0.9px;
        height: 2.66em;
        background-color: $whitelabel-black;
        opacity: 0.2;
    }

    &__combo_container {
        font-style: normal;
        font-weight: 600;
    }

    &__combo_text {
        font-size: 1.5em;
        line-height: 9px;
        font-family: $brand-condensed-font;
    }

    &__combo_price {
        font-size: 2em;
        line-height: 14px;
        font-family: $brand-semi-condensed-font;
    }

    &__yellow_pastel_section {
        position: absolute;
        bottom: -5.5em;
        left: -5px;

        background-color: rgba(255, 234, 160, 0.82);
        width: 120%;
        height: 85%;

        -webkit-transform: rotate(-16deg); /* Safari and Chrome */
        -moz-transform: rotate(-16deg); /* Firefox */
        -ms-transform: rotate(-16deg); /* IE 9 */
        -o-transform: rotate(-16deg); /* Opera */
        transform: rotate(-16deg);
    }
}
