.addon-item {
    width: initial;
    margin-right: 15px;
    margin-left: 0;
    position: relative !important;

    &__main-container {
        display: flex;
        flex-direction: column;
        width: 135px;
        overflow: initial;
        height: 100% !important;
    }

    &__container {
        height: 100%;
        grid-template-rows: initial;
        display: flex !important;
        flex-direction: column;
    }

    &__image {
        height: 135px !important;
        width: 135px !important;
        background-size: 100%;
        border-radius: 5px;
    }

    &__link {
        display: block;
    }

    &__name-container {
        margin: 10px 0;
    }

    &__name {
        line-height: 1.2;
    }

    &__button-container {
        position: initial;
        margin-top: auto;
        margin-left: initial;
        margin-bottom: 5px;
        padding: 1px;
    }

    &__tag {
        margin: 10px;
        font-size: 10px;
    }
}

.addon-item:first-of-type {
    margin-left: 25px;
}

.addon-item:last-of-type {
    .addon-item__main-container {
        margin-right: 25px;
    }
}

.addon-item.big-list {
    height: initial !important;
}

.column-container {
    padding-bottom: 5px;
}
