.checkout {
    background-color: $whitelabel-lightest;

    &--no-fixed-banner {
        .banner {
            position: static;
        }
    }

    &__user-info {
        padding-right: 6px;
    }

    &__cart-info {
        padding-left: 6px;
    }

    .wallet-header {
        background-color: transparent;
    }

    .button--busy {
        &::after {
            background-image: linear-gradient(
                -45deg,
                $whitelabel-blue 25%,
                transparent 25%,
                transparent 50%,
                $whitelabel-blue 50%,
                $whitelabel-blue 75%,
                transparent 75%,
                transparent
            );
        }
    }
}
