.section-how-it-works {
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 70px;

    &__title {
        font-size: 28px;
    }

    &__content-container {
        margin-top: 30px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    &__content-header {
        margin-bottom: 10px;
    }

    &__content-subheader {
        line-height: 1.5;
    }
}
