@media (max-width: 769px) {
    .checkout {
        &__container {
            display: flex;
            flex-direction: column;
        }

        &__user-info {
            order: 2;
        }
    }
}
