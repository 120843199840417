.calendar {
    display: flex;
    overflow-x: scroll;
    &__cell {
        margin-right: 20px;
        width: 25px;
    }
    &__day-strip {
        margin-left: initial !important;
        margin-right: initial !important;
    }
}
