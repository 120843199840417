.section-footer {
    padding-top: 95px;

    &__title {
        width: 290px;
    }

    &__paragraph {
        font-size: 15px;
        width: 240px;
    }

    &__background {
        height: 260px;
    }
}
