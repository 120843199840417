.shopping-cart-header {
    border-bottom: 2px solid lighten($whitelabel-lighter, 10%);
    box-shadow: 0px 0px 3px $whitelabel-lighter;

    &__logo {
        transform: scale(0.8);
    }

    &__back-link-icon {
        width: 1.2em;
        height: 1.2em;
    }

    &__back-link-text {
        display: none;
    }
}
