.account-switcher {
    width: 100%;
    margin-right: 25px;
    margin-left: 25px;
    display: flex;
    font-size: 14px;
    &__content-container {
        display: flex;
        width: 100%;
        span {
            font-family: $brand-bolder-font;
        }
    }

    .dropdown__container {
        right: 0;
        top: 0;
    }
}
