.header {
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1;
    background-color: $white;
    transform-origin: top;

    &--show {
        transform: scaleY(1);
        transition: transform 0.2s ease;
    }

    &--hide {
        transform: scaleY(0);
        transition: transform 0.15s ease;
    }

    &__logo {
        margin: 10px;
    }

    &__dahmakan {
        fill: $black;
        width: 150px;
    }
}
