.shopping-cart-timeslot {
    &__list-container {
        flex-wrap: wrap;
    }

    &__item-container {
        max-width: 25%;
    }

    &__display {
        margin-left: 16px;

        &:empty {
            display: none;
        }
    }
}

@media (min-width: 993px) and (max-width: 1199px) {
    .shopping-cart-timeslot {
        &__item-container {
            max-width: 33.33%;
            min-width: 33.33%;
        }
    }
}
