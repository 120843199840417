.wallet {
    &__section {
        width: 100%;
        padding-top: 0;
    }

    &__section-hero {
        background-position: 75%;
        padding: 40px 0;
        text-align: left;

        &-content {
            background-position: right 10px bottom 0px;
        }

        &-gradient {
            background: none;
        }

        &-mobile-gradient {
            background: linear-gradient(
                0deg,
                #3794d1 20%,
                rgba(39, 90, 78, 0) 65%,
                rgba(31, 59, 8, 0) 100%
            );
        }

        &-title {
            font-size: 28px !important;
            margin-bottom: 32px;
        }

        &-cta {
            width: 100%;
        }
    }

    &__section-video {
        padding: 0;
    }

    &__video {
        width: 100%;
    }

    &__section-title {
        font-size: 1.5em;
        line-height: 120%;
        text-align: left;
    }

    &__section-faq {
        padding-top: 32px;
        padding-bottom: 16px;
    }

    &__benefit-wrapper {
        flex-direction: column;
        margin: 10px auto;
        padding: 0px;
        display: block;
        width: 100%;
        padding: 0px 24px;
    }

    &__section-savings {
        background-color: $white;

        &-wrapper {
            margin-top: 0px;
            margin-bottom: 0px;
        }

        &-item {
            display: flex;
            margin-bottom: 20px;
        }

        &-icon {
            font-size: 50px;
            margin-right: 12px;
        }
    }

    &__savings-item {
        &-title {
            font-size: 18px;
            margin-bottom: 8px;
        }

        &-subtitle {
            font-size: 16px;
            line-height: 1;
        }
    }

    &__faq-wrapper {
        grid-template-columns: 1fr;
    }

    &__faq-item-wrapper {
        width: 100%;
        display: block;
    }
}
