.chef-view {
    margin-bottom: 30px;
    text-align: left;

    &__arrow {
        margin-top: auto;
        margin-bottom: auto;
    }

    &__avatar {
        width: 100%;
    }

    &__name {
        vertical-align: initial;
        font-size: 14px;
        padding-left: 0 !important;
    }

    &__read-more-container {
        color: $whitelabel-primary;
        margin-top: 15px;
        font-size: 14px !important;
    }

    &__arrow {
        width: 0.75em;
        height: 0.75em;
        margin-left: 8px;
        stroke-width: 2px;
    }
}
