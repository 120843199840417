$tag-selected: $whitelabel-light;

.rating-tag {
    font-size: 12px;
    background-color: #f2f2f2;
    display: inline-block;
    border-radius: 20px;
    padding: 9px 14px;
    margin: 4px;
    cursor: pointer;

    &--selected {
        background-color: $whitelabel-primary;
        color: $white;
    }
}
