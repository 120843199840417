.terminal-table-popup {
    width: 500px;
    height: 711px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: $brand-primary-font;
    font-style: normal;
    font-weight: 600;
    padding: 32px;

    &__ordering {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 16px 0px;
    }

    &__table_num_icon {
        width: 212px;
        height: 80px;
        object-fit: contain;
        margin-bottom: 16px;
    }

    &__title {
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 34px;
        text-align: center;
        letter-spacing: -0.527692px;
        color: $whitelabel-black;
    }

    &__container {
        margin-top: 5px;
        width: 100%;
    }

    &__input-wrapper {
        width: 100%;
    }

    &__input-number {
        width: 100%;
        border: none;
        border-radius: 0 !important;
        font-family: $brand-bolder-font;
        border-radius: 0 !important;
    }

    &__num_inp_box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .input-number-box {
            width: 100%;
            height: 64px;
            border: 1.5px solid #dfe0e1;
            border-radius: 5px;
            display: flex;
            align-items: center;
            margin-top: 5px;
            border-left: 1px solid $whitelabel-lighter;
            font-size: 30px;
            line-height: 19px;
        }
    }

    &__number {
        width: 100%;
        padding: 10px 10px 10px 16px;
        font-family: $brand-primary-font;
        border-radius: 4px;
        color: $whitelabel-black;
    }

    &__placeholder {
        color: #c0c1c3 !important;
        font-size: 30px;
        line-height: 19px;
    }

    &__backspace_icon {
        margin-right: 8px;
        .hide {
            display: none;
        }
    }

    &__numpad {
        display: flex;
        flex-wrap: wrap;
        padding: 0px 36px;
        margin-top: 10px;
        justify-content: center;
        align-items: center;
    }

    &__numpad_key {
        height: 76px;
        width: 76px;
        font-style: normal;
        font-weight: 600;
        font-size: 53px;
        text-align: center;
        color: $whitelabel-gray;
        border-radius: 50%;
        border: 2px solid #c0c1c3;
        margin: 7px 8px;
        cursor: pointer;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__error_message {
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        color: #f4594f;
        margin-bottom: 10px;
    }

    &__CTA-container {
        width: 92%;
        padding: 0px 15px;
        margin-top: 25px;
    }

    &__CTA {
        margin: 0px 0px 5px;
        transition: 1s ease;
        height: 60px;
        width: 100%;
        background-color: $whitelabel-primary;
        color: $black;
        border-radius: 5px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
    }

    .disabled {
        background: rgba(254, 199, 0, 0.25);
        color: rgba(19, 20, 21, 0.25);
    }
}
