.careers__carousel {
    margin-left: initial;
    margin-right: initial;

    &__content-container {
        margin-left: initial;
        margin-right: initial;
        margin-bottom: 5px !important;
    }

    &__overflow-container:first-of-type {
        margin-left: 15px !important;
    }

    &__overflow-container:last-of-type {
        .careers__carousel__content-box {
            margin-right: 15px !important;
        }
    }

    &__header-container {
        flex-direction: column;
    }

    &__header {
        margin-top: 15px;
        font-size: 28px;
    }

    &__content-box {
        width: 85vw;
        margin-right: 15px;
        height: 100%;
    }

    &__content {
        padding: 35px 30px;
        margin-top: 0;
        width: initial !important;
        margin-bottom: auto;
    }

    &__arrows {
        &--center-align {
            padding-right: initial !important;
        }
    }

    &__left-margin {
        margin-left: initial !important;
    }

    &__link {
        margin-top: 15px;
    }
}
