.about__section-save {
    margin-bottom: 90px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    &__header-container {
        width: 255px;
        margin-top: 22px;
    }
    &__header {
        font-size: 36px;
        margin-bottom: 25px;
    }
    &__sub-header {
        line-height: 1.5;
    }

    &__info-content {
        display: flex;
    }

    &__info-icon-container {
        margin-right: 20px;
    }

    &__info-container {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: 100px;
            right: 140px;
            height: 375px;
            width: 500px;
            background-color: $whitelabel-pastel-yellow;
            border-radius: 10px;
            z-index: -1;
        }
    }

    &__info-text-header {
        font-size: 28px;
        margin-bottom: 20px;
    }

    &__cta {
        margin-left: 185px;
        width: 291px;
        height: 45px;
    }

    .icon-heart {
        width: 37px;
        height: 31px;
    }

    .icon-cash-envelope {
        width: 35px;
        height: 34px;
    }
}

@media (min-width: 901px) and (max-width: 1300px) {
    .about__section-save {
        flex-direction: column;
        &__header-container {
            margin-bottom: 50px;
        }
        &__info-text-header {
            font-size: 24px;
        }
        &__info-box.one,
        &__info-box.two,
        &__info-box.three,
        &__info-box.four {
            .info-content {
                padding: 35px 40px;
            }
        }
    }
}
