.address-type {
    &__title {
        text-align: center;
        margin-right: initial;
        margin-bottom: auto;
        margin-top: auto;
    }
    &__wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 5px;
    }

    &__cell {
        width: 100%;
    }

    &__building-icon-office {
        margin-left: 6px;
        margin-right: 6px;
    }

    &__building-icon-home {
        margin-left: 6px;
        margin-right: 6px;
    }

    &__map {
        width: 100%;
    }
}
