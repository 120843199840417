.addon-item {
    display: flex;
    flex-direction: column;
    margin: 7px;
    background-color: #f4f4f4;
    color: $whitelabel-black;
    font-family: $brand-semi-condensed-font;
    font-style: normal;
    width: 187.6px;
    height: 300px;
    border-radius: 4px;
    font-size: 6px;
    cursor: pointer;

    &__content_card {
        padding: 1.33em 2em 0px;
    }

    &__image {
        width: 100%;
        height: 185px;
        border-radius: 4px 4px 0px 0px;
        object-fit: cover;
    }

    &__name {
        font-weight: 600;
        font-size: 3em;
        line-height: 20px;
        max-width: 100%;
        font-family: $brand-semi-condensed-font;
    }

    &__price-container {
        margin-top: 10px;
        position: absolute;
        bottom: 10px;
        height: 24px;
        display: flex;
        align-items: center;
    }

    &__special-price {
        font-weight: 600;
        font-size: 20px;
        font-family: $brand-semi-condensed-font;
        color: $whitelabel-red;
    }

    &__price {
        font-weight: 600;
        font-size: 2.5em;
        color: $whitelabel-gray;
        font-family: $brand-semi-condensed-font;
    }
}
