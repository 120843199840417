.terminal-qrcode {
    display: flex;
    justify-content: center;
    margin-top: 90px;
    margin: 30px 50px;
    flex-direction: column;
    width: 700px;
    text-align: center;

    &__rewardCode {
        display: flex;
        justify-content: center;
        margin-top: 90px;
        margin: 32px;
        flex-direction: column;
        width: 700px;
        text-align: center;
    }

    &__title {
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 34px;
        text-align: center;
        letter-spacing: -0.527692px;
        color: $whitelabel-black;
    }

    &__addon_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__reward-img {
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url('https://s3-ap-southeast-1.amazonaws.com/dahmakan-images-staging/menu-item/medium/DIGITAL_MENU_IMAGE_5f434dbd3817577a8e83b9ca.png?v=7');
        background-repeat: no-repeat;
        background-position: bottom 10% right 0;
        background-size: cover;
    }

    &__red-cross-line {
        position: absolute;
        transform: rotate(60deg);
        height: 50px;
        top: -10px;
        left: 25px;
        z-index: 10;
        border-left: 2px solid #f4594f;
    }

    &__next_btn {
        background: none;
        font-family: Barlow;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        color: $black;
        text-align: center;
        padding: 16px 64px;
        background-color: $whitelabel-primary;
        border-radius: 4px;
        width: 298px;
    }

    &__numbering {
        width: 50px;
        height: 50px;
        background-color: #fec700;
        flex-shrink: 0;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 36px;
    }

    &__img-pay {
        padding-left: 10px;
    }

    &__qrcodeTxt {
        border-radius: 16px;
        z-index: 2;
        top: 0px;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        /* display: flex; */
        /* align-items: center; */
        left: 50%;
        height: 200px;
        width: 200px;
    }

    &__qr-code-container {
        background-color: #ed2e67;
        border-radius: 16px;
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        padding: 16px;
        justify-content: center;
        flex-direction: column;
    }

    &__sub-txt {
        font-weight: 600;
        font-size: 22px;
        margin-left: 16px;
        text-align: left;
    }

    &__row-span {
        margin: 30px 20px;
    }

    &__loader-qrcode {
        width: 120px !important;
        height: 120px !important;
        margin-top: 35px !important;
    }
}
