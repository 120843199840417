.section-footer {
    padding-top: 115px;

    &__background {
        background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/kl-skyline.png');
        background-size: cover;
        background-position: center bottom;
        height: 430px;
        margin-bottom: -10px;
    }

    &__btn {
        width: 180px;
        font-family: $brand-bolder-font;
    }
}
