.about__section-how-it-works {
    width: 100%;
    margin-bottom: 120px;
    &__header-container {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 45px;
    }
    &__header {
        font-size: 36px;
        margin-bottom: 25px;
    }

    &__sub-header {
        line-height: 1.5;
    }

    &__carousel-titles {
        margin-left: 180px;
        display: flex;
    }

    &__title-container {
        display: flex;
        margin-right: 75px;
    }

    &__title-container.greyed-out {
        opacity: 0.6;
        color: $black;
    }

    &__carousel-number {
        margin-right: 8px;
    }

    &__carousel-title {
        width: 129px;
    }
}
