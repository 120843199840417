.chef__section-hero {
    height: 456px;
    width: 100%;
    margin-bottom: 85px;
    position: relative;
    &__bg-image {
        right: 0;
        width: 69%;
        height: 411px;
        background: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/chef-hero.jpg');
        background-size: cover;
        position: absolute;
    }
    &__content-container {
        height: 100%;
        position: relative;
        border-radius: 10px;
    }
    &__content {
        position: absolute;
        top: 133px;
        background-color: white;
        margin-right: auto;
        padding: 60px 55px;
        width: 420px;
        border-radius: 10px;
    }
    &__header {
        margin-bottom: 25px;
    }
    &__body {
        line-height: 1.5;
    }
    &__grey-box {
        position: absolute;
        height: 358px;
        width: 63%;
        top: 98px;
        left: -135px;
        background-color: #ededed;
        z-index: -1;
    }
}
