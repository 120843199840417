.terminal-hamburger-menu {
    &__content {
        color: $black;
        position: fixed;
        top: 0px;
        left: 0px;
        right: 30%;
        bottom: 0px;
        transform: translateX(-100%);
        background-color: $white;
        z-index: 10;
        padding: 25px;
        padding-top: 30px;
        font-size: 20px;
        font-weight: bold;
        transition: transform 0.4s ease;
        overflow: auto !important;

        &--show {
            transform: translateX(0%);
        }
    }

    &__item {
        padding-top: 16px;
        padding-bottom: 16px;

        &--underlined {
            border-bottom: 1px solid $whitelabel-lightest;
        }
    }

    &__how-it-works {
        margin-top: 64px;
        font-weight: normal;
    }

    &__hint-number {
        float: left;
        background-color: $whitelabel-red !important;
        color: $white;
        display: inline-block;
        width: 26px;
        padding-top: 5px;
        text-align: center;
        border-radius: 50%;
        font-size: 19px;
        margin-right: 10px;
    }

    &__hint-strong {
        font-weight: bold;
        font-size: 18px;
        font-family: $brand-bolder-font;
        line-height: 125%;
    }

    &__hint-explain {
        font-size: 17px;
        color: $whitelabel-light;
        font-family: $brand-primary-font;
        font-weight: normal;
        line-height: 125%;
    }

    &__hint {
        margin-bottom: 48px;
    }

    &__contact-line {
        font-weight: normal;
        font-family: $brand-primary-font;
        font-size: 19px;
    }
}
