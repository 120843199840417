.careers__how-we-hire {
    margin-bottom: 90px;

    &__content {
        width: 85%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        margin-bottom: 50px;
    }

    &__header {
        margin-left: initial;
    }
}
