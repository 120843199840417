.well {
    background-color: $white;
    padding: 20px;
    margin-bottom: 12px;
    border-radius: 5px;

    &--no-margin {
        margin-bottom: 0px;
    }
}
