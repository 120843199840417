.business {
    &__hero-section {
        height: 100%;
        background-size: 250%;
    }

    &__hero-section-overlay {
        background: linear-gradient(
            to right,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0.8) 100%
        );
        opacity: 0.6;
    }

    &__hero-section-subheading {
        font-size: 21px;
    }

    &__intro-container {
        text-align: center;
        margin-top: 42px;
    }

    &__form-container {
        margin-top: 42px;
        padding-left: 0px;
        padding-right: 0px;
    }

    &__happy-customer-subheading {
        width: 100%;
        margin-top: 24px;
    }

    &__logo-container {
        flex: none;
        width: 50%;
        margin-top: 8px;
        margin-bottom: 8px;
    }

    &__benefit-container {
        margin-bottom: 36px;
    }
}
