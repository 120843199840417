.about__section-details {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 75px;

    &__header-container {
        margin-bottom: 55px;
    }

    &__header {
        font-size: 36px;
        margin-bottom: 25px;
    }

    &__sub-header {
        line-height: 1.5;
    }

    &__content-container {
        display: flex;
    }

    &__content {
        height: 168px;
        width: 330px;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.07);
        margin-right: 45px;
    }

    &__content-box {
        display: flex;
        padding-top: 50px;
        padding-left: 55px;
    }

    &__content-text {
        font-size: 28px;
    }

    &__icon {
        margin-right: 16px;
    }

    .icon-top-ten {
        width: 51px;
        height: 49px;
    }

    .icon-price-tag {
        width: 41px;
        height: 39px;
    }

    .icon-scooter {
        width: 40px;
        height: 32px;
    }
}

@media (min-width: 901px) and (max-width: 1300px) {
    .about__section-details {
        &__content-box {
            padding-top: 30px;
            padding-left: 35px;
            flex-direction: column;
        }
        &__content-text {
            font-size: 20px;
            margin-top: 20px;
        }
    }
}
