.delivery-area {
    &__hero-section {
        color: $white;
        background-size: 1190px;
        background-position-x: -700px;
        height: 520px;
        padding-top: 50px;
        position: relative;
        text-align: center;

        &__container {
            z-index: 1;
            position: relative;
        }

        &__overlay {
            background-color: $black;
            opacity: 0.5;
            position: absolute;
            top: 0px;
            left: 0px;
            bottom: 0px;
            right: 0px;
            z-index: 0;
        }

        &__heading {
            font-size: 30px;
            margin-bottom: 12px;
        }

        &__subheading {
            width: 80%;
            margin: 0 auto;
            font-size: 22px;
            line-height: 130%;
            margin-bottom: 22px;
        }

        &__intro-text {
            width: 100%;
            padding-left: 16px;
            padding-right: 16px;
            color: $white;
        }

        &__benefit-container {
            margin: 0 auto;
            display: inline-block;
        }

        &__benefit {
            color: $white;
        }

        &__cta {
            width: 100%;
            font-size: 15px;
        }
    }

    &__map-section {
        text-align: center;
        padding-bottom: 32px;

        &__text-area {
            padding-top: 0px;
        }

        &__heading {
            font-family: $brand-bolder-font;
            font-weight: normal;
            font-size: 24px;
        }

        .separator {
            margin-top: 20px;
            margin-bottom: 16px;
        }

        &__map-container {
            padding-left: 0px;
            padding-right: 0px;
        }

        &__map {
            height: 400px;
        }

        &__cta2 {
            margin-top: 32px;
        }
    }

    &__vote-section {
        padding-top: 50px;

        &__heading {
            font-family: $brand-bolder-font;
            font-weight: normal;
            font-size: 24px;
        }

        .separator {
            margin-top: 20px;
            margin-bottom: 16px;
        }

        &__subheading,
        &__subheading-2,
        &__form-container {
            width: 90%;
        }

        &__subheading-2 {
            margin-top: 32px;
        }

        &__input {
            width: 100%;
        }

        &__cta {
            width: 100%;
            margin-bottom: 16px;
        }
    }

    &__popup-container {
        transform: translate(-50%, 120px);
        width: 90%;
    }

    &__popup-success {
        padding-left: 48px;
        padding-right: 48px;

        &__subheading {
            line-height: 150%;
        }
    }

    &__popup-email {
        padding-left: 48px;
        padding-right: 48px;
        width: 100%;

        &__subheading {
            line-height: 150%;
        }
    }
}
