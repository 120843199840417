.menu {
    background-color: $white !important;

    &__list--loading {
        /* 71px: footer's height */
        min-height: calc(100vh - 71px);
        padding-top: 20px;
    }

    &__list-wrapper {
        &:empty {
            min-height: calc(100vh - 71px);
        }
    }

    &__list-container {
        display: flex;
    }

    &__sticky-nav {
        width: 100%;
        left: 0px;
        right: 0px;
        position: fixed;
        background-color: $white;
        z-index: 5;
    }
}
