$backdrop-color: rgba(0, 0, 0, 0.5);

.modal {
    &-fullscreen {
        min-height: 100vh;
        min-width: 100vw;
    }

    margin: 0 auto;
    position: relative;
    z-index: 0;
    opacity: 0;
    pointer-events: none;

    &--margin-bottom {
        margin-bottom: 25px;
    }

    &--open {
        z-index: 1000;
        pointer-events: all;
        opacity: 1;
    }

    &__content {
        z-index: 1000;
        background-color: $white;
        color: $black;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: 80vh;
        overflow: auto;
        border-radius: 5px;
        zoom: 97% !important;

        &--golden-ratio {
            top: 0px;
            transform: translate(-50%, 110px);
        }
    }

    .primary_color_gradient {
        //it's a hack/temporary fix to hide the white line appearing at the right side of order confirmation modal on terminal.
        background: linear-gradient(
            180deg,
            #ffc800 0%,
            #ffc907 57.81%,
            #ffd334 100%
        );
    }

    .rounded {
        border-radius: 5px !important;
    }

    &__content::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
    &__content::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px !important;
    }

    &__backdrop {
        position: fixed;
        background-color: $backdrop-color;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 90;
    }

    &__button {
        padding: 0;
    }

    &__close {
        right: 13px;
        top: 20px;
        position: absolute;
    }

    &__margin {
        top: 30px;
    }

    &__extra-margin {
        right: 35px !important;
        top: 43px !important;
    }

    &__close-icon {
        width: 14px;
        height: 14px;
        cursor: pointer;
    }

    &__back {
        left: 25px;
        top: 43px;
        position: absolute;
    }

    &__back-icon {
        transform: rotate(-90deg);
        width: 17px;
        height: 17px;
        fill: black;
        margin-left: -3px;
    }

    &__back-text {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: -5px;
    }
}
