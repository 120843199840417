.profile {
    .menu-header {
        position: static;
        margin-top: 0 !important;
    }

    background-color: $whitelabel-lightest;

    &__header {
        height: 135px;
        background-color: $whitelabel-primary;
        color: $whitelabel-secondary;
        text-align: center;
        padding-top: 55px;
    }

    &__title {
        font-size: 30px;
        font-family: $brand-bolder-font;
        line-height: 1.4;
    }

    &__name-greeting {
        width: 550px;
    }

    &__content {
        width: 550px;
        margin: 0 auto;
        margin-top: -16px;
    }

    &__country-code {
        width: 60px;
    }

    &__button {
        width: 300px;
    }

    &__box {
        background-color: $white;
        margin-bottom: 12px;
        border-radius: 5px;
    }

    &__box-header {
        padding: 24px 22px;
        font-weight: bold;
        font-size: 18px;
        max-width: 80%;
    }

    &__box-subheader {
        padding: 24px 22px 0;
        border-top: 0;
        width: 80%;
    }

    &__box-footer {
        padding: 24px 22px;
        border-top: 1px solid $whitelabel-line-separator;
    }

    &__box-body {
        border-top: 1px solid $whitelabel-line-separator;
        padding: 24px 22px;

        &:empty {
            display: none;
        }
    }

    &__notification-item {
        padding: 24px 22px;
        border-bottom: 1px solid $whitelabel-line-separator;

        &:last-of-type {
            border-bottom: none;
        }
    }

    .add-payment-method__button {
        height: 42px;
    }
}
