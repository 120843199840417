.checkout {
    &__container {
        display: flex;
        flex-direction: column;
    }

    &__user-info {
        order: 2;
    }

    .wallet-header {
        background-color: $white;
    }
}
