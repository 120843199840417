.terminal-cart-summary {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 9;
    background-color: $whitelabel-primary !important;
    color: $black;
    // max-height: 150px;
    transform: translateY(0px);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    // padding: 20px 0px;
    // height: auto;
    height: 190px;
    transition: all 0.8s;
    max-height: calc(100vh - 60px);

    &--hidden {
        transform: translateY(100%);
    }

    &__summary-container {
        position: absolute;
        transition: all 1s;
        width: 100%;
        // height: 100%;
        left: 0;
        bottom: 0;
        overflow: auto;
        // max-height: calc(100vh - 110px);
        // transform: translateY(calc(100% - 100px));
    }

    &__combo-tag{
        background: rgba(244, 89, 79, 1);
        padding: 4px, 16px, 4px, 16px;
        color: white;
        text-transform: uppercase;
        font-size: 18px;
        padding:5px 20px;
        font-weight: 600;
        border-radius: 32px;
        margin-left: 5px;
        position: absolute;
        align-self: flex-start;     
        // position: absolute;
        // right:-50px;
        // top:0;

    }

    &__expand-toggle {
        text-align: center;
        font-size: 18px;
        justify-content: center;
        align-items: center;
        height: 58px;
        display: flex;
        color: #7056b5;
        font-weight: 600;
    }

    &__item-animate {
        // animation: moveTop 0.5s;
        // animation-fill-mode: forwards;
    }

    &__content {
        min-height: 140px !important;
        height: 100%;
        padding-top: 6px;
        padding-bottom: 6px;
        justify-content: space-around;
    }

    &__CTA {
        background-color: white !important;
        border-radius: 5px;
        padding: 18px 15px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        // margin-top: 24px;
    }

    &__CTA-container {
        margin-bottom: 30px;

        margin-top: auto;
        position: relative;
        // padding: 20px 0px;
        width: 28%;
        // height: 130px;
    }
    &__CTA-inner-container {
        position: absolute;
        right: 0;
        left: 0;
        bottom: 40px;
    }

    &__CTA-text {
        color: $whitelabel-black;
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        display: flex;
        align-items: center;

        .separator-line {
            height: 23px;
            border-right: 2px solid black;
            margin: 0px 16px;
        }
    }

    &__item {
        display: flex;
        font-size: 15px;
        padding-bottom: 30px !important;
        transition: all 0.7s;
    }

    &__combo-item {
        border: 2px solid black;
        padding: 16px;
        border-radius: 16px;
        position: relative;
        margin-top: 20px;
        margin-right: 20px;

    }

    &__combo-tag {
       background-color: black;
       color: #FEC700;
       border-radius: 8px 8px 0px 0px;
       padding: 4px 16px 4px 16px;
       position: absolute;
       top: -28px;
       left: 10px;

    }

    &__increase-decrease-button-container {
        display: flex;
        margin-left: auto;
        align-items: center;
    }

    &__item-name {
        font-size: 28px !important;
        display: inline-block;
    }

    &__quantity-box {
        width: 28px;
        height: 28px;
        display: flex;
    }

    &__quantity {
        font-size: 24px !important;
        margin: auto;
    }

    &__increase,
    &__decrease {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;
        border: none;
        border-radius: 50%;
        width: 43px;
        height: 43px;
        background-color: $black;
    }

    &__increase .iconmoon-icon,
    &__decrease .iconmoon-icon {
        width: 15.4px !important;
        height: 15.4px !important;
        stroke-width: 4px !important;
        stroke: white !important;
    }

    &__summary-wrapper {
        width: 68%;
        transition: all 0.8s;
    }

    &__heading {
        font-size: 18px;
        line-height: 18px;
        font-weight: 600;
    }

    &__sub-heading {
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
    }

    &__cutlery-text {
        max-width: 144px;
    }

    &__takeaway-remark {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    @keyframes moveTop {
        1% {
            opacity: 1;
            visibility: visible;
            transform: translateY(0px);
        }
        50% {
            opacity: 0.3;
            visibility: visible;
            transform: translateY(-20px);
        }
        100% {
            opacity: 0;
            visibility: hidden;
            transform: translateY(-100px);
        }
    }
}

.arrow-toggle {
    transition: all 0.5s;
    margin-left: 8px;
    font-size: 0;
}
