.wallet-progress {
    &__label {
        font-size: 24px;
        font-weight: bold;

        &--too-big {
            font-size: 18px;
        }
    }
}
