.cart-preview {
    background-color: $white;
    padding: 25px;
    text-align: left;
    max-height: 90vh;
    overflow-y: auto;

    &__container {
        padding: $large 65px;
    }

    &__separator {
        border-color: $whitelabel-lightest;
        margin-top: 15px;
        margin-bottom: 25px;
    }

    &__quantity-separator {
        border-color: $whitelabel-lightest;
        margin-top: 7.5px;
        margin-bottom: 7.5px;
    }

    &__subtotal-row {
        margin-top: 15px;
    }

    &__next {
        position: relative;
        height: 45px;
        margin-bottom: 40px;
    }

    &__add-to-cart {
        margin-top: 15px;
    }
}

.cart--empty-preview {
    padding: $medium;
    text-align: center;

    &__cart__image {
        max-width: 100px;
    }
}

.cart-dropdown {
    position: absolute;
    border: 1px solid $whitelabel-lighter;
    border-radius: 5px;
    overflow: hidden;
    background-color: $white;
    margin-top: 13px;
    z-index: 10;
    width: 100%;
    right: -10px;
    min-width: 150px;

    &__wrapper {
        position: absolute;
        right: 10px;
        top: 30px;
        min-width: 400px;
    }

    &__backdrop {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
    }

    &__triangle {
        position: absolute;
        right: 0px;
        z-index: 11;
        top: 7px;

        &:before {
            content: '';
            position: absolute;
            width: 0px;
            height: 0px;
            right: 0px;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 7px solid $whitelabel-lighter;
        }

        &:after {
            content: '';
            position: absolute;
            width: 0px;
            height: 0px;
            right: 2px;
            top: 2px;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid $white;
            z-index: 3;
        }
    }
}
.cart-preview::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.cart-preview::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px !important;
}

@media (max-width: 991px) {
    .cart-dropdown__wrapper {
        display: none !important;
    }
}
