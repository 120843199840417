.dmk-landing {
    width: auto;
    height: auto;

    &__header-image {
        display: none;
    }

    &__wrapper-padding {
        padding: 25px !important;
    }

    &__fullpage-container {
        > :first-child {
            height: auto;
        }

        > :last-child {
            height: auto;
        }
    }

    &__upper {
        > div {
            min-width: 100vw;
        }
    }

    &__left:after {
        display: none;
    }

    &__on-menu-flex-container {
        > div {
            min-width: 100%;
            min-height: 30vh;
            margin: 15px auto;
            > p {
                font-size: 0.8em;
            }
        }
    }

    &__input {
        width: 70%;
    }

    &__gobutton {
        width: 100%;
        text-align: center;
    }
}
