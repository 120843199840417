.menu-footer-show {
    bottom: 0 !important;
    transform-origin: center bottom 0 !important;
    width: 100% !important;
    z-index: 5 !important;
    transform: scaleY(1) !important;
    padding: 0 !important;
    transition: -ms-transform 0.2s ease-out, -webkit-transform 0.2s ease-out,
        transform 0.2s ease-out !important;
}

.menu-footer-hide {
    bottom: 0 !important;
    transform-origin: center bottom 0 !important;
    width: 100% !important;
    z-index: 5 !important;
    transform: scaleY(0) !important;
    padding: 0 !important;
    transition: -ms-transform 0.2s ease-out, -webkit-transform 0.2s ease-out,
        transform 0.2s ease-out !important;
    .visible-none {
        visibility: hidden !important;
    }
}

.sticky {
    position: sticky;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
}

.menu-footer {
    background-color: #f2f2f2;
    color: $black;
    width: 100% !important;
    z-index: 5 !important;
    border-top: solid 0.4px #dedede;

    &__app-button {
        width: 100px;
        border-radius: 4px;
    }

    &__btn-container {
        align-self: flex-end;
        margin-left: auto;
        margin: 20px;
    }

    &__container {
        margin: 0;
        padding: 18px 0;
        position: relative;
    }

    &__bottom {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        div {
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    &__links {
        a:first-of-type {
            margin-left: 0;
        }
        a:last-of-type {
            margin-right: 0;
        }
    }

    &__social {
        display: flex;
        margin-right: 35px;
        a {
            margin-right: 25px;
        }
    }

    &__apps {
        display: flex;
        margin-right: 60px;

        a:not(:last-child) {
            margin-right: 16px;
        }
    }

    &__huawei-app-gallery {
        position: relative;
        top: 1px;
    }

    &__social-icon {
        width: 1.5em;
        height: 1.5em;
        color: $black;
    }

    &__payment-methods {
        display: flex;
        div {
            margin-right: 16px;
        }
    }

    &__visa-logo {
        width: 43px;
        height: 15px;
        background-size: contain !important;
        background: url(https://d3tjqo2jh935k9.cloudfront.net/images/static/visa.png)
            no-repeat;
    }

    &__mastercard-logo {
        width: 25px;
        height: 15px;
        background-size: contain !important;
        background: url(https://d3tjqo2jh935k9.cloudfront.net/images/static/master.png)
            no-repeat;
    }

    &__amex-logo {
        width: 55px;
        height: 15px;
        background-size: contain !important;
        background: url(https://d3tjqo2jh935k9.cloudfront.net/images/static/amex-new.png)
            no-repeat;
    }

    &__paypal-logo {
        width: 57px;
        height: 15px;
        background-size: contain !important;
        background: url(https://d3tjqo2jh935k9.cloudfront.net/images/static/paypal-new.png)
            no-repeat;
    }

    &__boost-logo {
        width: 15px;
        height: 15px;
        background-size: contain !important;
        background: url(https://d3tjqo2jh935k9.cloudfront.net/images/static/boost.png)
            no-repeat;
    }

    &__cod-logo {
        width: 101px;
        height: 19px;
        background-size: contain !important;
        background: url(https://d3tjqo2jh935k9.cloudfront.net/images/static/cod.png)
            no-repeat;
    }

    &__fpx-logo {
        width: 46px;
        height: 15px;
        background-size: contain !important;
        background: url(https://d3tjqo2jh935k9.cloudfront.net/images/static/fpx.png)
            no-repeat;
    }

    .language,
    .country {
        display: inline-block;
        position: relative;
        span {
            margin-right: 5px;
        }
    }

    &__selector,
    &__selector-picker {
        margin: 0 !important;
    }

    &__selector {
        padding: 7.5px 15px;
    }

    &__selector-picker {
        padding: 0 8px;
    }

    &__selector-container {
        margin-bottom: 5px;
        background-color: white;
        width: 186px;
        border: solid 1px $whitelabel-lighter;
        z-index: 1;
        position: absolute;
        bottom: 100%;
        right: 5% !important;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 7.5px 0 !important;
        div {
            margin: 0;
        }
    }

    &__toggle {
        border: none;
        background: none;
    }

    &__check {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        margin-right: 0 !important;
        margin-left: auto !important;
    }

    &__language {
        margin-right: -8px;
    }

    .link--no-underline {
        color: $black;
    }

    .border-bottom {
        border-bottom: solid 0.3px #dedede;
    }

    @media screen and (max-width: 1399px) {
        &__socials {
            text-align: center;
        }
    }
}
