.careers__how-we-hire {
    margin-bottom: 90px;

    &__content {
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        margin-bottom: 50px;
    }

    &__header {
        font-size: 40px;
        margin-top: 10px;
        margin-right: 50px;
        margin-left: 109px;
    }

    &__sub-header {
        line-height: 1.5;
        width: 462px;
    }
}
