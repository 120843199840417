.section-about {
    margin-top: 10px;
    font-size: 14px;

    h3 {
        margin-bottom: 25px;
        font-size: 24px !important;
        line-height: 1.21;
    }

    p,
    ul {
        margin-bottom: 25px;
        line-height: 1.29;
    }
}
