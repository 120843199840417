.about__section-details {
    margin-bottom: 65px;
    &__content-container {
        flex-direction: column;
    }
    &__content {
        width: 100%;
        height: 118px;
        margin-right: initial;
        margin-bottom: 25px;
    }
    &__content-box {
        padding-top: 32px;
    }
    &__content-text {
        font-size: 24px;
    }
}
