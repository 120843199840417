.dish-header {
    padding: 20px;
    padding-bottom: 15px;
    left: 0px;
    right: 0px;
    background-color: $white;
    z-index: 6;
    text-align: left;
    position: sticky;
    top: 0;

    &__back-icon {
        position: relative;
        top: 4px;
        transform: rotate(-90deg);
        width: 1.2em;
        height: 1.2em;
    }

    &__cart-icon-container {
        margin-top: 5px;
        margin-left: auto;
        display: flex;
    }
}
