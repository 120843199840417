.section-delivered {
    height: 619px;
    background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/blue-section.jpg');
    display: flex;
    background-size: cover;
    background-position: center center;

    &__background {
        width: 100%;
        position: absolute;
        z-index: 0;
        margin: auto;
    }

    &__btn {
        width: 180px;
    }
}
