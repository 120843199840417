.terminal-main-items {
    &__menu_screen_1 {
        display: flex;
        user-select: none !important;
    }

    &__screen1_lhs {
        display: flex;
        flex-direction: column;

        .medium-items {
            display: flex;
        }

        .small-items {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__small_items_grid {
        display: flex;
        flex-wrap: wrap;
    }

    &__masthead {
        justify-content: space-between;
        align-items: center;
        color: white;
        padding: 2rem 1.25rem;

        .heading {
            font-size: 4rem;
            font-weight: 800;
            font-family: 'Barlow Semi Condensed';
        }

        .sub_heading {
            font-size: 1.75rem;
            margin-top: 0.75rem;
            font-weight: 500;
            font-family: 'Barlow Semi Condensed';
        }

        .button {
            width: 22rem;
            height: 3.125rem;
            border-radius: 1rem;
            background: #7056B5;
            color: white;
            font-size: 1.5rem;
            padding: 0;
            margin-top: 4.2rem;
            font-weight: 600;
            font-family: 'Barlow Semi Condensed';
        }

        .masthead_image {
            height: 30rem;
            width: 25.5rem;
            border-radius: 8px;
            background-size: cover;
            background-position: center;
        }
    }

    &__top_item_category {
        .item_card {
            align-items: center;
        }

        .item_title {
            position: absolute;
            top: 0.5rem;
            left: 8px;
            right: 8px;
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 0.25px;
            font-weight: 600;
            font-family: 'Barlow Semi Condensed';
        }

        .item_price {
            position: absolute;
            bottom: 0.5rem;
            left: 8px;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0.25px;
            font-family: 'BarlowSemiBold';
            display: flex;
        }

        .ranking_image { 
            height: 11rem;
            width: 5rem;
        }

        .item_image {
            height: 16rem;
            width: 9.5rem;
            border-radius: 8px;
            background-size: cover;
            background-position: center;
        }
    }

    &__item_card_heading {
        font-size: 2rem;
        color: white;
        padding: 2rem 0px 1rem 1.2rem;
        font-weight: 800;
        font-family: 'Barlow Semi Condensed';
    }

    &__item_cards {
        display: flex;
        overflow-x: scroll;
        // Hide scrollbar
        scrollbar-width: none;
        -ms-overflow-style: none; 

        &::-webkit-scrollbar { 
            display: none;
        }
    }

    &__large_menu_line {
        .item_title {
            position: absolute;
            top: 0.5rem;
            left: 8px;
            right: 8px;
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 0.25px;
            font-weight: 600;
            font-family: 'Barlow Semi Condensed';
        }

        .item_price {
            position: absolute;
            bottom: 0.5rem;
            left: 8px;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0.25px;
            font-family: 'BarlowSemiBold';
            display: flex;
        }

        .item_image {
            height: 21.5rem;
            width: 13rem;
            border-radius: 8px;
            background-size: cover;
            background-position: center;
        }
    }

    &__medium_menu_line {
        .item_title {
            position: absolute;
            top: 0.5rem;
            left: 8px;
            right: 8px;
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 0.25px;
            font-weight: 600;
            font-family: 'Barlow Semi Condensed';
        }

        .item_price {
            position: absolute;
            bottom: 0.5rem;
            left: 8px;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0.25px;
            font-family: 'BarlowSemiBold';
            display: flex;
        }

        .item_image {
            height: 18.5rem;
            width: 11.25rem;
            border-radius: 8px;
            background-size: cover;
            background-position: center;
        }
    }

    &__small_menu_line {
        .item_title {
            position: absolute;
            top: 0.5rem;
            left: 8px;
            right: 8px;
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 0.25px;
            font-weight: 600;
            font-family: 'Barlow Semi Condensed';
        }

        .item_price {
            position: absolute;
            bottom: 0.5rem;
            left: 8px;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0.25px;
            font-family: 'BarlowSemiBold';
            display: flex;
        }

        .item_image {
            height: 15.75rem;
            width: 9.5rem;
            border-radius: 8px;
            background-size: cover;
            background-position: center;
        }
    }

    &__reset_button {
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        color: white;
    }
}


