.terminal-addon-item-combo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 8px 8px;

    .image {
        width: 89px;
        height: 121px;
        border-radius: 8px;
        object-fit: cover;

        @media (max-height: 700px) {
            //styling changes applied for mobile devices having height <700px
            width: 80px;
            height: 100px;
        }
    }

    .name {
        margin-top: 8px;
        font-weight: 600;
        font-size: 11px;
        line-height: 11px;
        text-align: center;
        max-width: 89px;
    }

    .price-logic {
        margin-top: 4px;
        color: #b0b2b3;
    }
}
