.counter {
    &__counter {
        border-top: 38px solid $whitelabel-primary;
        border-left: 38px solid transparent;
    }

    &__text {
        top: -32px;
        font-size: 14px;
    }
}

@media (min-width: 501px) {
    .counter {
        &__counter {
            border-top: 45px solid $whitelabel-primary;
            border-left: 45px solid transparent;
        }
        &__text {
            top: -34px;
            font-size: 16px;
        }
    }
}
