.terminal-address-input {
    &__input-container {
        width: 580px;
    }

    &__map {
        width: 100%;
        height: 300px;
    }

    &__map--hidden {
        height: 0px;
    }
}
