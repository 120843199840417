.menu-one-day {
    height: 100% !important;
    padding-bottom: 45px !important;
    &__item-container {
        margin-top: 15px;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: minmax(min-content, max-content);
        grid-gap: 30px 15px;
    }
    &__header {
        display: flex;
        padding-top: 0 !important;
        margin-bottom: 25px !important;
    }
    &__toggle-container {
        margin-top: auto;
        margin-bottom: auto;
    }
    &__date-container {
        width: 100%;
    }

    &__date {
        font-size: 24px;
    }

    &__meal-toggle-container {
        margin-bottom: auto;
        margin-top: auto;
    }

    &__meal-cutoff-container {
        margin-top: auto;
        margin-bottom: auto;
    }

    &__meal-cutoff {
        margin-top: initial;
    }

    &__arrow-icon {
        top: -1px;
    }

    &__see-tomorrow-header-icon {
        position: relative;
        top: -2px;
    }
}

@media (min-width: 501px) {
    .menu-one-day {
        &__item-container {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
