.careers__testimonials {
    &__main-container {
        left: 50px;
    }

    .careers__testimonials {
        &__main-container {
            left: 50px;
        }

        &__testimonial {
            p {
                font-size: 18px;
            }
        }
    }
}
