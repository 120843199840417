:root {
    --primary-font: Barlow;
    --bolder-font: BarlowBold;
    --boldest-font: BarlowSemiBold;
}

$largest: 64px;
$large: 32px;
$medium: 16px;
$small: 8px;
$smallest: 4px;

$whitelabel-primary: #fec700;
$whitelabel-secondary: #7056b5;

$whitelabel-blue: #00acc6;
$whitelabel-red: #ff5a5f;
$whitelabel-orange: #ffa400;
$whitelabel-lightest: #efefef;
$whitelabel-lighter: #cdcdcd;
$whitelabel-light: #8b898d;
$whitelabel-dark: #535353;
$whitelabel-line-separator: #e4e4e4;
$whitelabel-gray: #616468;
$whitelabel-black: #131415;

$white: white;
$black: black;

$whitelabel-pastel-yellow: #fff9e6;

$skeleton-grey: #d8d8d8;

$brand-primary-font: Barlow;
$brand-bolder-font: var(--bolder-font);
$brand-boldest-font: var(--boldest-font);
$brand-condensed-font: Barlow Condensed;
$brand-semi-condensed-font: Barlow Semi Condensed;

$menu-header-height: 70px;
$shopping-cart-header-height: 70px;
$charcoal-dark: #2b2b2b;
