.address-selector {
    &__container {
        margin: 30px 40px;
        text-align: left;
    }
    &__header-container {
        margin-bottom: 25px;
    }
    &__header {
        font-size: 24px;
    }
    &__input-box {
        margin-bottom: 26px;
    }
    &__input-container {
        border-bottom: initial !important;
    }
    &__pin-icon {
        margin-top: 2px;
        width: 11px;
        height: 15px;
    }
    &__pin-icon.house {
        width: 14px;
        height: 14px;
    }
    &__pin-icon.office {
        width: 14px;
        height: 18px;
    }
    &__saved-addresses-header-container {
        margin-bottom: 20px;
    }
    &__saved-addresses-header {
        font-family: $brand-bolder-font;
        font-size: 12px;
    }
    &__saved-address-icon-container {
        margin-right: 12px;
    }
    &__saved-address-location-header {
        line-height: 1.44;
        margin-bottom: 5px;
    }
    &__saved-address-location-subheader {
        font-size: 14px;
        line-height: 1.29;
        color: #4a4a4a;
    }
}
