.terminal-addon-items-popup {
    width: 633px;
    max-height: 726px;

    &__addon_item_header {
        position: sticky;
        top: 0px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fec700;

        .timer-container {
            display: flex;
            border-radius: 20px;
            font-size: 18px;
            font-weight: 600;
            background: #f4594f;
            justify-content: center;
            align-items: center;
            margin-left: 32px;
            width: 100px;
            padding: 10px;
            color: white;
        }

        .snack-dessert-text {
            font-family: Barlow;
            font-weight: 600;
            font-size: 36px;
            line-height: 43px;
            padding: 25px 0px;
            padding-left: 16px;
        }
    }

    &__ipad_header {
        position: sticky;
        top: 0px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 20px 8px 20px;
        background-color: #f4f4f4;

        .addon-text {
            font-family: Barlow;
            font-weight: 600;
            font-size: 36px;
            line-height: 30px;
        }

        .save-text-container {
            background-color: $whitelabel-primary;
            color: $whitelabel-secondary;
            border-radius: 0px 0px 4px 4px;
            padding: 10px 12px;
            font-weight: 600;
            font-size: 32px;
            line-height: 30px;
            text-align: center;
        }

        .highlight-text-container {
            position: absolute;
            top: 0;
            right: 20px;
            background-color: $whitelabel-primary;
            color: $whitelabel-secondary;
            border-radius: 0px 0px 4px 4px;
            padding: 9px;
            font-weight: 600;
            font-size: 18px;
            line-height: 18px;
            text-align: center;
        }
    }

    &__mobile_header {
        display: none;
    }

    &__combo-items {
        display: flex;
        flex-wrap: wrap;
        padding: 20px 10px;

        .not-select-item {
            margin: 5px 5px;

            .item-container {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 139px;
                height: 216px;
                border-radius: 8px;
                background-color: #f4f4f4;
            }

            .text {
                margin-top: 10px;
                font-weight: 600;
                font-size: 18px;
                line-height: 20px;
                text-align: center;
            }
        }
    }

    &__snack-dessert-items {
        display: flex;
        flex-wrap: wrap;
        padding: 26px 32px;
        padding-right: 8px;
    }

    &__skip-snack-dessert-items {
        display: inline-block;
        padding: 16px 48px;
        font-size: 18px;
        font-weight: 600;
        line-height: 18px;
        text-align: center;
        margin-bottom: 10px;
        color: #616468;
    }
}
