.chef__chefs {
    &__header-container {
        margin-bottom: 85px;
    }
    &__header {
        width: 572px;
        margin-left: 110px;
        font-size: 28px;
        line-height: 1.21;
    }
    &__content {
        position: relative;
        margin-bottom: 85px;
    }
    &__display-pic-container {
        height: 563px;
        width: 437px;
        position: relative;
    }

    &__display-pic {
        width: 375px;
        height: 539px;
        border-radius: 10px;
    }

    &__display-pic.right {
        margin-left: auto;
    }

    &__green-box {
        position: absolute;
        bottom: 0;
        left: 254px;
        width: 183px;
        border-radius: 10px;
        background-color: #effcf4;
        z-index: -1;
    }

    &__green-box.right {
        left: 0;
    }

    &__green-box.doul {
        height: 244px;
    }

    &__green-box.shahril {
        height: 244px;
        width: 293px;
    }

    &__green-box.riza {
        height: 426px;
    }

    &__green-box.kamarul {
        height: 244px;
        width: 293px;
    }

    &__description-container {
        margin-top: 32px;
        margin-bottom: auto;
        margin-left: 124px;
        margin-right: 120px;
    }

    &__description-fact {
        margin-bottom: 26px;
    }

    &__description-container.right {
        margin-left: auto !important;
    }
    &__description-header-container {
        margin-bottom: 30px;
        position: relative;
    }
    &__description-header {
        font-size: 24px;
    }
    &__description-name {
        font-size: 56px;
    }
    &__description-body-container {
        width: 476px;
        margin-bottom: 27px;
    }
    &__description-body {
        line-height: 1.5;
    }
    &__name-container {
        position: relative;
    }
    &__grey-box {
        height: 13px;
        position: absolute;
        bottom: 9px;
        z-index: -1;
        left: -4px;
        background-color: #ededed;
    }
    &__grey-box.doul {
        width: 126px;
    }
    &__grey-box.shahril {
        width: 195px;
    }
    &__grey-box.riza {
        width: 121px;
    }
    &__grey-box.kamarul {
        width: 228px;
    }
    &__description-fun-facts {
        margin-bottom: 65px;
    }
    &__fact-text {
        line-height: 1.44;
        width: 394px;
        margin-top: auto;
        margin-bottom: auto;
    }
    &__fact-icon-container {
        min-width: 32px !important;
        margin-right: 14px;
    }
    &__fact-icon {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .chef-doul {
        background: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/chef-doul.jpg');
        background-size: cover;
    }
    .chef-shahril {
        background: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/chef-shahril.jpg');
        background-size: cover;
    }
    .chef-riza {
        background: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/chef-riza.jpg');
        background-size: cover;
    }
    .chef-kamarul {
        background: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/chef-kamarul.jpg');
        background-size: cover;
    }
    .pen-tool {
        width: 26px;
        height: 26px;
    }
    .chef-star {
        width: 29px;
        height: 27px;
    }
    .guitar {
        width: 29px;
        height: 27px;
    }
    .airplane {
        width: 30px;
        height: 22px;
    }
    .chef-hat {
        width: 25px;
        height: 23px;
    }
    .policeman {
        width: 18px;
        height: 23px;
    }
    .trophy {
        width: 24px;
        height: 23px;
    }
    .hands {
        width: 31px;
        height: 25px;
    }
    .sun {
        width: 26px;
        height: 23px;
    }
}
