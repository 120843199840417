.terminal-mobile-addon-items-home-screen-list {
    margin: 16px 3.5px 0px;

    &__list_name_viewall {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        .list-name {
            font-family: $brand-primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 18px;
            letter-spacing: -0.4px;
            color: $whitelabel-black;
        }

        .view-all-btn {
            border: none;
            font-family: $brand-primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 15px;
            text-align: right;
            color: $whitelabel-secondary;
            background-color: transparent;
            padding: 0px;
            border-radius: 0px;
        }
    }
}
