.password-reset {
    &__container {
        width: 95%;
        padding: 20px;
    }

    &__title {
        margin-top: 30px;
    }

    &__title-separator {
        margin-bottom: 20px;
    }

    &__close {
        fill: $whitelabel-light;
    }

    &__back-link {
        top: 0px;
        left: 0px;
    }
}

@media (max-width: 320px) {
    .password-reset {
        min-height: calc(100vh - 196.31px);
    }
}
