.wallet-entry-popup {
    width: 400px;
    &__top-bar {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $whitelabel-red;
        padding: 13px 0;
        span {
            color: white;
            font-size: 12px;
        }
    }
    &__icon-wallet-limited-time {
        width: 12px;
        height: 12px;
        margin-right: 5px;
    }
    &__body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 30px 0;
        margin-left: 36px;
        margin-right: 36px;
    }
    &__header-container {
        margin-bottom: 30px;
    }
    &__header {
        font-size: 40px;
        margin-bottom: 10px;
    }
    &__header-alt {
        color: $whitelabel-primary;
    }
    &__subheader {
        line-height: 1.19;
    }
    &__slashed-price {
        color: $whitelabel-red;
        font-family: $brand-bolder-font;
    }
    &__points {
        margin-bottom: 15px;
    }
    &__point {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        span {
            font-family: $brand-bolder-font;
            line-height: 1.19;
        }
    }
    &__icon-wallet-check-mark {
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }
    &__cta-container {
        width: 100%;
    }
    &__cta {
        margin-bottom: 25px;
    }
}
