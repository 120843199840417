.p {
    &a {
        padding: auto;
    }

    &xl {
        padding: $largest;
    }
    &l {
        padding: $large;
    }
    &m {
        padding: $medium;
    }
    &s {
        padding: $small;
    }
    &xs {
        padding: $smallest;
    }
    &0 {
        padding: 0px;
    }

    &txl {
        padding-top: $largest;
    }
    &tl {
        padding-top: $large;
    }
    &tm {
        padding-top: $medium;
    }
    &ts {
        padding-top: $small;
    }
    &txs {
        padding-top: $smallest;
    }
    &t0 {
        padding-top: 0px;
    }

    &bxl {
        padding-bottom: $largest;
    }
    &bl {
        padding-bottom: $large;
    }
    &bm {
        padding-bottom: $medium;
    }
    &bs {
        padding-bottom: $small;
    }
    &bxs {
        padding-bottom: $smallest;
    }
    &b0 {
        padding-bottom: 0px;
    }

    &lxl {
        padding-left: $largest;
    }
    &ll {
        padding-left: $large;
    }
    &lm {
        padding-left: $medium;
    }
    &ls {
        padding-left: $small;
    }
    &lxs {
        padding-left: $smallest;
    }
    &l0 {
        padding-left: 0px;
    }

    &rxl {
        padding-right: $largest;
    }
    &rl {
        padding-right: $large;
    }
    &rm {
        padding-right: $medium;
    }
    &rs {
        padding-right: $small;
    }
    &rxs {
        padding-right: $smallest;
    }
    &r0 {
        padding-right: 0px;
    }
}

.m {
    &a {
        margin: auto;
    }
    &la {
        margin-left: auto;
    }
    &ra {
        margin-right: auto;
    }
    &0a {
        margin: 0 auto;
    }

    &xl {
        margin: $largest;
    }
    &l {
        margin: $large;
    }
    &m {
        margin: $medium;
    }
    &s {
        margin: $small;
    }
    &xs {
        margin: $smallest;
    }
    &0 {
        margin: 0px;
    }

    &txl {
        margin-top: $largest;
    }
    &tl {
        margin-top: $large;
    }
    &tm {
        margin-top: $medium;
    }
    &ts {
        margin-top: $small;
    }
    &txs {
        margin-top: $smallest;
    }
    &t0 {
        margin-top: 0px;
    }

    &bxl {
        margin-bottom: $largest;
    }
    &bl {
        margin-bottom: $large;
    }
    &bm {
        margin-bottom: $medium;
    }
    &bs {
        margin-bottom: $small;
    }
    &bxs {
        margin-bottom: $smallest;
    }
    &b0 {
        margin-bottom: 0px;
    }

    &lxl {
        margin-left: $largest;
    }
    &ll {
        margin-left: $large;
    }
    &lm {
        margin-left: $medium;
    }
    &ls {
        margin-left: $small;
    }
    &lxs {
        margin-left: $smallest;
    }
    &l0 {
        margin-left: 0px;
    }

    &rxl {
        margin-right: $largest;
    }
    &rl {
        margin-right: $large;
    }
    &rm {
        margin-right: $medium;
    }
    &rs {
        margin-right: $small;
    }
    &rxs {
        margin-right: $smallest;
    }
    &r0 {
        margin-right: 0px;
    }
}
