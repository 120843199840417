.section__testimonials {
    padding-top: 50px;
    padding-bottom: 50px;

    &__title {
        font-family: $brand-bolder-font;
        font-size: 35px;
        width: 70%;
        margin: 0 auto;
    }

    &__content {
        height: auto;
        padding-left: 15px;
        padding-right: 15px;
    }

    &__page {
        display: block;
        padding-left: 20px;
        padding-right: 20px;
    }

    &__nav-btn {
        &--next {
            margin-right: -10px;
        }

        &--prev {
            margin-left: -10px;
        }
    }

    .slider-list {
        min-height: 650px !important;
    }
}

.section__testimonials-item {
    &__footer {
        padding-top: 10px;
    }
}
