.section-corporate {
    background-size: 1080px;
    background-position: center bottom;
    padding: 35px 0px 95px 0px;
    height: 500px;

    &__content {
        margin-top: 5%;
    }

    &__title {
        width: 70%;
    }

    &__paragraph {
        font-size: 15px;
        line-height: 120%;
        max-width: 380px;
    }

    &__btn {
        width: 160px;
        margin-top: 0px;
    }
}
