.dmk-landing {
    width: 100vw;
    height: 100vh;
    line-height: 1.5;

    p,
    input {
        font-family: $brand-primary-font;
    }

    &__fullpage-container {
        > :first-child {
            height: 60vh;
        }

        > :last-child {
            height: 40vh;
            font-size: 1.4em;
        }
    }

    &__upper {
        background-color: $whitelabel-primary;
        color: white;
        flex-wrap: wrap;
        min-height: 500px;
    }

    &__left:after {
        position: absolute;
        content: '';
        left: 50vw;
        top: 0;
        border-width: 30vh 5vw;
        border-style: solid;
        border-color: $whitelabel-primary transparent transparent
            $whitelabel-primary;
    }

    &__wrapper-padding {
        padding: 50px 10px 0 80px;
    }

    &__header-image {
        background: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/DMKHero.jpg')
            no-repeat;
        background-size: cover;
    }

    &__input {
        display: inline-block;
        width: 70%;
        height: 50px;
        background-color: #ffffff;
        border: none;
        padding: 0;
        border-image-width: 0;
        vertical-align: top;
        font-size: 16px;
        padding: 5px;
    }

    &__input-wrapper {
        height: 40px;
        max-width: 400px;
    }

    &__gobutton {
        display: inline-block;
        width: 100%;
        height: 50px;
        background-color: $whitelabel-red;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    &__goButtonBusy {
        background-color: grey;
        cursor: auto;
    }

    &__on-menu-flex-container {
        justify-content: space-between;
        text-align: center;
        height: calc(100% - 33px);
        align-items: center;
        font-size: 1.4em;
        flex-wrap: wrap;

        > div {
            width: 30%;
            height: 70%;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__modal {
        color: white;
        padding: 30px 10px;
        background-color: $whitelabel-primary;
    }
}

@media (max-height: 795px) {
    .dmk-landing {
        &__fullpage-container {
            > :first-child {
                height: 80vh;
            }
        }

        &__left:after {
            border-width: 40vh 5vw;
            left: 49.95vw;
        }
    }
}
