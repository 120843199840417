.menu-header {
    padding: 18px 0;
    height: initial;
    align-content: space-between;
    overflow: hidden !important;

    &__logo {
        margin-left: auto;
    }

    &__desktop-link {
        display: none;
    }

    &__right-container {
        flex: initial;
    }

    &__hamburger-container {
        display: block;
        margin-left: 25px;
        margin-top: auto;
        margin-bottom: auto;
        padding-bottom: 10px;
    }
}
