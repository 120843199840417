#app-banner-iframe {
    overflow: hidden;
    position: fixed;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.35);
    width: 1px;
    min-width: 100%;
    top: 0;
    left: 0;
    right: 0;
    border: 0;
    height: 100px;
    z-index: 99999;
    -webkit-transition: all 0.25s ease;
    transition: all 00.25s ease;

    &.collapse {
        top: -105px;
    }
}

#app-banner {
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-transition: all 0.25s ease;
    transition: all 00.25s ease;
    left: 0;
    line-height: normal;
    top: 0;

    .app-banner-close {
        display: inline-block;
        float: left;
        width: 24px;
        padding: 30px 0;
        text-align: center;
        color: grey;
        font-size: 20px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        z-index: 2;
    }

    .app-banner-icon {
        float: left;
        border-radius: 10px;
        width: 63px;
        height: 63px;
        margin-right: 10px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        z-index: 2;

        img {
            width: 63px;
            height: 63px;
            border-radius: 10px;
        }
    }

    .app-banner-title {
        font-weight: bold;
        color: #333333;
        padding-top: 5px;
        padding-bottom: 1px;
        font-size: 16px;
    }

    .app-banner-description {
        overflow: hidden;
        color: #333333;
        font-size: 12px;
    }

    .app-banner-button {
        font-size: 16px;
        border-radius: 10px;
        padding: 10px 10px;
        text-decoration: none;
        color: $whitelabel-secondary;
        font-weight: bold;
        text-align: center;
        letter-spacing: 1.15px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        position: relative;
        z-index: 2;
        background: $whitelabel-primary;
    }

    .app-banner-stars {
        display: inline-block;
        color: $whitelabel-primary;
        letter-spacing: -2px;
        font-size: 16px;
        margin-left: -2px;
    }

    .app-banner-reviews {
        font-size: 12px;
        color: #777;
        display: inline-block;
        margin-left: 4px;
        position: relative;
        top: -1px;
    }

    .app-banner-stars span {
        position: relative;
    }

    .app-banner-content {
        width: 100%;
        overflow: hidden;
        height: 100px;
        background: white;
        color: #333;
        border-top: 1px solid #ddd;
    }

    .app-banner-left {
        height: 100%;
    }

    .app-banner-details {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
    }

    .app-banner-right {
        float: right;
        height: 100%;
    }

    .app-banner-right:before {
        display: inline-block;
        vertical-align: middle;
        content: '';
        height: 100%;
    }
}
