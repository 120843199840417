.shopping-cart-meal {
    padding: 16px;
    margin-bottom: 0;

    &__header {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    &__header-date {
        font-family: $brand-bolder-font;
    }

    &__container {
        padding: 15px 0 !important;
    }

    &__subtotal-row {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    &__next {
        width: calc(100% - 32px);
        margin-bottom: 25px !important;
        margin-left: auto;
        margin-right: auto;
    }

    &__button-container {
        text-align: center;
        background: white;
    }

    &__money-back-bubble {
        position: sticky;
        margin-left: 10px;
        &__triangle {
            top: -6px;
        }
    }
    &__select-upsell {
        margin-top: 15px;
    }
}
