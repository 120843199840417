.terminal-table-popup {
    width: 295px;
    height: 356px;
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__table_num_icon {
        width: 136px;
        height: 50px;
        object-fit: contain;
        margin-bottom: 24px;
    }

    &__title {
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 29px;
        color: $whitelabel-black;
        text-align: left;
    }

    &__container {
        margin-top: 0px;
        padding: 0px;
        width: 100%;
        margin: 24px 0px;
    }

    &__input-wrapper {
        padding: 0px;
        margin: 0px;
        margin-top: 8px;
    }

    &__input_number_container {
        display: flex;
        background-color: #f2f3f3;
    }

    &__input-number {
        width: 100%;
        padding-left: 10px;
        height: 45px;
        border: none;
        font-family: $brand-bolder-font;
        background-color: #f2f3f3;
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: $whitelabel-black;
        border-radius: 4px !important;
        outline: none;

        &::placeholder {
            font-family: $brand-primary-font;
            font-style: normal;
            font-weight: 600;
            font-size: 16px !important;
            color: #b0b2b3 !important;
        }
    }

    &__input-number:focus {
        outline: none;
        background-color: #fff4cc;
        border: 2px solid $whitelabel-primary;
    }

    &__error_message {
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: #f4594f;
        margin-bottom: 10px;
    }

    &__CTA-container {
        transition: 1s ease;
        margin-top: 0px;
        padding: 0px;
        width: 100%;
    }

    &__CTA {
        padding: 13px 0px;
        margin: 0px;
        transition: 1s ease;
        bottom: 0px;
        left: 0px;
        right: 0px;
        z-index: 100;
        width: 100%;
        height: 45px !important;
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: $whitelabel-black;
        background-color: $whitelabel-primary;
    }

    .disabled {
        background-color: #f2f3f3;
        color: #b0b2b3;
    }

    &__skip_btn {
        border: none;
        background-color: transparent;
        font-family: $brand-primary-font;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #131415;
        margin-top: 24px;
    }
}
