.wallet-benefit {
    max-width: 100%;
    width: 100%;
    margin-bottom: 24px;
    padding: 20px;

    &__title {
        display: block;
    }

    &__popular-tag {
        margin: 5px 0;
        width: fit-content;
    }
}
