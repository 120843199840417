.add-payment-method {
    &__button {
        height: auto;
        display: flex;
        align-items: center;
        width: 100%;
    }

    &__icon {
        transform: scale(0.9);
        background-repeat: no-repeat;
    }

    &__button-text {
        color: $whitelabel-light;
    }
}
