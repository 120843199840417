.careers__section-hero {
    height: 550px;
    margin-bottom: 70px;
    display: flex;

    &__background {
        position: absolute;
        height: 365px;
        width: 1305px;
        background-color: $whitelabel-pastel-yellow;
    }

    &__img {
        background: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/careers-hero.jpg');
        background-size: cover;
        height: 620px;
        width: 65%;
        position: absolute;
        top: 0;
        right: 0;
    }

    &__text {
        text-align: left;
        margin: 36px 44px;
    }

    &__text-container {
        position: absolute;
        background-color: white;
        width: 471px;
        border-radius: 10px;
        box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.07);
        top: 189px;
        left: 25px;
    }

    &__heading {
        font-size: 36px;
        line-height: 1.39;
    }

    &__sub-heading {
        margin-top: 16px;
        line-height: 1.5;
    }

    &__jobs-available-text {
        line-height: 1.63;
        margin-top: 15px;
    }

    &__cta {
        margin-top: 18px;
        width: 50%;
    }

    &__box-container {
        position: relative;
        margin-right: auto;
        margin-left: auto;
    }
}

@media (min-width: 901px) and (max-width: 989px) {
    .careers__section-hero {
        &__box-container {
            margin-right: auto;
            margin-left: initial !important;
        }
        &__text-container {
            left: 15px;
        }
    }
}
