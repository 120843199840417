.payment-provider {
    width: 40px;
    height: 25px;
    display: inline-block;
    background-size: 100%;

    &--paypal {
        background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/paypal.png');
        margin-bottom: -10px;
    }

    &--boost {
        background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/boost-logo.jpg');
    }

    &--visacard {
        background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/visacard.png');
    }

    &--mastercard {
        background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/mastercard.png');
    }

    &--amex {
        background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/amex.png');
    }

    &--maestro {
        background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/maestro.png');
    }

    &--unknown {
        background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/unknown-card.png');
    }

    &--onlinebanking {
        background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/billplz.png');
    }

    &--wallet {
        background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/prime-pm.png');
    }

    &--cash {
        background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/cash.jpg');
    }

    &--truemoney {
        background-image: url('https://d3tjqo2jh935k9.cloudfront.net/images/static/truemoney.png');
    }
}
