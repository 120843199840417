body.user-agent__browser-name--IE.user-agent__browser-ver--11 {
    .menu-header__logo {
        .logo__text {
            height: 40px;
        }

        .logo--green {
            height: 20px;
        }
    }

    .add-to-cart-button__content {
        height: 20px;

        .iconmoon-icon {
            height: 20px;
        }
    }
}
